/* eslint no-param-reassign: "off" */
import { makePixel, triggerSocialProviderInitializationCallbacks } from '../util';

let fbInitialized = false;

export function fbIsInitialized() {
  return fbInitialized;
}

export function isFacebookBrowser() {
  const ua =
    navigator.userAgent ||
    navigator.vendor ||
    ((window as any).opera as string) ||
    '';
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
}

export function fbInit(appId: string, pixelId: string | null) {
  (window as any).fbAsyncInit = function () {
    FB.init({
      appId,
      xfbml: true,
      version: 'v7.0',
    });
  };
  fbInitialized = true;
  triggerSocialProviderInitializationCallbacks();

  (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    const js = d.createElement(s) as HTMLScriptElement;
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    if (fjs.parentNode) {
      fjs.parentNode.insertBefore(js, fjs);
    }
  })(document, 'script', 'facebook-jssdk');
  initFBPixel(pixelId);
}

export function fbShare(
  // params: facebook.ShareDialogParams,
  // callback: (response: facebook.ShareDialogResponse) => void
  url: string
) {
  if (FB && FB.ui) {
    FB.ui({ method: 'share', href: url }, (_r) => {});
  }
}

function initFBPixel(pixelId: string | null) {
  // eslint-disable-next-line no-unused-expressions
  !(function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
    return true;
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

  if (pixelId) {
    // insert noscript/img pixel element
    makePixel(`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`);

    fbq('init', pixelId);
    fbq('track', 'PageView');
  }
}

export function fbq(type: string, value: string, meta?: {}) {
  const fbqF = (window as any).fbq;
  fbqF && fbqF(type, value, meta);
}
