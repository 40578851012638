import * as React from 'react';
import { Checkbox, RadioGroup, Well, WellType } from 'client/shared/components/base';
import {
  ClientQuestionId,
  QuestionChoice,
  MULTIPLE_CHOICE_QUESTION_DROPDOWN_MIN_CHOICES,
} from 'client/shared/core/question';
import { Case } from 'core';
import { Dropdown } from '../base/dropdown_v2';

import './styles.scss';
import { LoadedEvents, MCChoice } from 'client/shared/core/types';
import {
  RandomizeChoicesProps,
  isRandomizeProps,
  useLanguageByQueryParam,
  useRandomizedQuestionChoices,
} from 'client/shared/hooks';

interface MCVoteProps {
  readonly ariaInvalid?: boolean;
  readonly choices: readonly QuestionChoice[];
  readonly selectMultipleChoice:
    | Case<'ACTION', { readonly action: LoadedEvents['selectMultipleChoice'] }>
    | Case<'SUBMITTING'>;
  readonly maxSelection: number;
  readonly questionId: ClientQuestionId;
  readonly selectedChoices: readonly MCChoice[];
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly questionTitle?: string;
}

export interface RandomizeMCVoteProps extends MCVoteProps, RandomizeChoicesProps {}

export type Props = MCVoteProps | RandomizeMCVoteProps;

export const baseClass = 'pn-mc-vote';

export const MC_VOTE_COPY = {
  error: 'You have selected more than the allowed',
  choices: 'choices',
  chooseAll: 'Choose all that apply',
  chooseMany: 'Choose up to',
  selectAnOption: 'Select an option',
};

export const MCVote: React.FC<Props> = (props) => {
  const {
    ariaInvalid,
    maxSelection,
    selectMultipleChoice,
    questionId,
    selectedChoices,
    disabled,
    required,
    questionTitle,
  } = props;
  const { language } = useLanguageByQueryParam();
  const randomizeEnabled = isRandomizeProps(props) && props.randomizeChoices;
  const choices = useRandomizedQuestionChoices({
    choices: props.choices,
    language,
    questionId: props.questionId,
    randomizeChoices: randomizeEnabled && props.randomizeChoices,
    randomizedSurveyItems: randomizeEnabled ? props.randomizedSurveyItems : null,
    disabled: props.disabled,
    setRandomizedSurveyItems: randomizeEnabled
      ? props.setRandomizedSurveyItems
      : undefined,
    getChoiceId: (questionChoice) => questionChoice.id,
  });

  const getUpdatedSelectedChoices = (selected: boolean, choice: QuestionChoice) => {
    // For the simplest case, just be deselect previous selection
    if (maxSelection === 1) {
      return selected ? [] : choice.id ? [{ id: choice.id }] : [];
    } else {
      // This is the case of allowing selecting/deselecting multiple choices
      if (selected) {
        return selectedChoices.filter((ch) => ch.id !== choice.id);
      } else {
        return [...selectedChoices, { id: choice.id }];
      }
    }
  };

  const handleChange = (selected: boolean, choice: QuestionChoice) => {
    if (selectMultipleChoice.type === 'ACTION') {
      const newSelectedChoices = getUpdatedSelectedChoices(selected, choice);
      selectMultipleChoice.action(newSelectedChoices, questionId);
    }
  };

  const createRadio = () => {
    if (
      choices.length > MULTIPLE_CHOICE_QUESTION_DROPDOWN_MIN_CHOICES &&
      maxSelection === 1
    ) {
      let options = choices.map((choice) => ({
        ...choice,
        value: choice.id,
        label: choice.label,
      }));

      if (!required) {
        options = [
          { id: '', label: MC_VOTE_COPY.selectAnOption, value: '' },
          ...options,
        ];
      }

      const dropdownSelectedValue = options.find(
        (ch) => ch.id === selectedChoices[0]?.id
      );

      return (
        <Dropdown
          ariaLabel={questionTitle || 'Dropdown question'}
          className={`${baseClass}-dropdown mb-4`}
          disabled={disabled}
          id={`mc-vote-dropdown-${questionId}`}
          onChange={(t) => t && handleChange(false, t)}
          options={options}
          placeholder={MC_VOTE_COPY.selectAnOption}
          value={dropdownSelectedValue}
        />
      );
    }

    const value = choices.find((ch) => ch.id === selectedChoices[0]?.id);

    return (
      <RadioGroup
        applyAccessibleStyles
        ariaInvalid={ariaInvalid}
        className={`${baseClass}-choices-container`}
        disabled={disabled}
        inputClassName={`${baseClass}-choice`}
        keySelect={(p: QuestionChoice) => `${p.id}-${questionId}`}
        labelSelect={(p) => p.label}
        name={questionId}
        onChange={(p) => handleChange(false, p)}
        options={choices}
        required={required}
        value={value}
      />
    );
  };

  const createChoice = (choice: QuestionChoice) => {
    const selected = !!selectedChoices.find((ch) => ch.id === choice.id);
    const maxAmountSelected = selectedChoices.length === maxSelection;

    return (
      <div key={`div-${choice.id}-${questionId}`}>
        <Checkbox
          applyAccessibleStyles
          ariaInvalid={ariaInvalid}
          checked={selected}
          className={`${baseClass}-choice cursor-pointer ml-3 ${
            maxAmountSelected ? 'is-max-selected' : ''
          }`}
          disabled={disabled}
          htmlId={`${choice.id}-${questionId}`}
          key={`${choice.id}-${questionId}`}
          label={choice.label}
          onChecked={() => handleChange(selected, choice)}
          required={required}
        />
      </div>
    );
  };

  return (
    <div className={`${baseClass} d-flex flex-column`}>
      {selectedChoices.length > maxSelection && (
        <Well ariaLive="polite" type={WellType.ERROR}>
          {MC_VOTE_COPY.error} {maxSelection} {MC_VOTE_COPY.choices}
        </Well>
      )}
      {maxSelection > 1 ? (
        <>
          <div className="font-size-sm font-weight-bold text-jungle pt-4 mb-1">
            {maxSelection === choices.length
              ? MC_VOTE_COPY.chooseAll
              : `${MC_VOTE_COPY.chooseMany} ${maxSelection}`}
          </div>
          <div
            className={`${baseClass}-choices-container d-flex flex-column mt-2`}
            role="group"
          >
            {choices.map(createChoice)}
          </div>
        </>
      ) : (
        createRadio()
      )}
    </div>
  );
};
