import React from 'react';
import { ComparisonGroupResultTableColumn } from './comparison-group-result-table-column';
import { TrackVariable } from 'core';
import {
  DomainIndicatorsFipsAreaRow,
  RankingData,
} from '../domain-indicators-group-wrapper';
import { RespondentLayout, useLayout } from 'client/shared/hooks';

const baseClass = 'pn-comparison-group-result-table';
export interface FipsAreaRow {
  readonly id: string;
  readonly name: string;
  readonly color: string;
  readonly isHidden: boolean;
  readonly isHoverRow: boolean;
}
interface Props {
  readonly variable: Pick<TrackVariable, 'id' | 'name' | 'valueType' | 'direction'>;
  readonly data: readonly DomainIndicatorsFipsAreaRow[];
  readonly isActionable: boolean;
  readonly onRowClick: (rowId: string) => any;
  readonly setHoverLine: (rowId: string | null) => void;
  readonly entriesPerPage: number;
  readonly pageOffset: number;
  readonly isEmbedded?: boolean;
  readonly hoverLine: string | null;
  readonly fipsAreaVisibility: Record<string, boolean>;
  readonly isCollapsed: boolean;
  readonly rankingData: RankingData;
}

export const ComparisonGroupResultTable: React.FC<Props> = (props) => {
  const {
    entriesPerPage,
    fipsAreaVisibility,
    hoverLine,
    isActionable,
    onRowClick,
    setHoverLine,
    pageOffset,
    isEmbedded,
    isCollapsed,
    rankingData,
  } = props;
  const { isMobile } = useLayout({
    mobileBreakingPoint: RespondentLayout.ContentWidth + 15,
  });
  const { top5RanksData, bottom5RanksData, numberOfRankings } = rankingData;

  const ComparisonGroupResultTableColumnMemo = React.useMemo(() => {
    const shouldUseSingleColumn = isMobile || isEmbedded;
    if (isCollapsed && numberOfRankings > 10) {
      return (
        <div className={`${baseClass}-double  w-100  justify-content-center`}>
          <div className="row">
            <div className={`col ${shouldUseSingleColumn ? 'col-12' : 'col-6'}`}>
              <h5 className="font-weight-bold my-3">Top 5 Ranked Communities</h5>
              <ComparisonGroupResultTableColumn
                data={top5RanksData}
                entriesPerPage={top5RanksData.length}
                fipsAreaVisibility={fipsAreaVisibility}
                hoverLine={hoverLine}
                isActionable={isActionable}
                onRowClick={onRowClick}
                setHoverLine={setHoverLine}
              />
            </div>
            <div className={`col ${shouldUseSingleColumn ? 'col-12' : 'col-6'}`}>
              <h5 className="font-weight-bold my-3">Bottom 5 Ranked Communities</h5>
              <ComparisonGroupResultTableColumn
                data={bottom5RanksData}
                entriesPerPage={bottom5RanksData.length}
                fipsAreaVisibility={fipsAreaVisibility}
                hoverLine={hoverLine}
                isActionable={isActionable}
                onRowClick={onRowClick}
                setHoverLine={setHoverLine}
              />
            </div>
          </div>
        </div>
      );
    }
    if (shouldUseSingleColumn) {
      return (
        <div>
          <div className={`${baseClass}-single w-100  justify-content-center`}>
            <ComparisonGroupResultTableColumn
              data={props.data.slice(
                pageOffset * entriesPerPage * 2,
                entriesPerPage * 2 * (pageOffset + 1)
              )}
              entriesPerPage={entriesPerPage * 2}
              fipsAreaVisibility={fipsAreaVisibility}
              hoverLine={hoverLine}
              isActionable={isActionable}
              onRowClick={onRowClick}
              setHoverLine={setHoverLine}
            />
          </div>
        </div>
      );
    }

    return (
      <div className={`${baseClass}-double  w-100  justify-content-center`}>
        <div className="row">
          <div className={'col col-6'}>
            <ComparisonGroupResultTableColumn
              data={props.data.slice(
                pageOffset * entriesPerPage * 2,
                pageOffset * entriesPerPage * 2 + entriesPerPage
              )}
              entriesPerPage={entriesPerPage}
              fipsAreaVisibility={fipsAreaVisibility}
              hoverLine={hoverLine}
              isActionable={isActionable}
              onRowClick={onRowClick}
              setHoverLine={setHoverLine}
            />
          </div>
          <div className={'col col-6'}>
            <ComparisonGroupResultTableColumn
              data={props.data.slice(
                pageOffset * 2 * entriesPerPage + entriesPerPage,
                pageOffset * 2 * entriesPerPage + 2 * entriesPerPage
              )}
              entriesPerPage={entriesPerPage}
              fipsAreaVisibility={fipsAreaVisibility}
              hoverLine={hoverLine}
              isActionable={isActionable}
              onRowClick={onRowClick}
              setHoverLine={setHoverLine}
            />
          </div>
        </div>
      </div>
    );
  }, [
    isMobile,
    isEmbedded,
    isCollapsed,
    numberOfRankings,
    props.data,
    pageOffset,
    entriesPerPage,
    fipsAreaVisibility,
    hoverLine,
    isActionable,
    onRowClick,
    setHoverLine,
    top5RanksData,
    bottom5RanksData,
  ]);
  return ComparisonGroupResultTableColumnMemo;
};

ComparisonGroupResultTable.displayName = 'ComparisonGroupResultTable';
