import * as React from 'react';
import parse from 'html-react-parser';
import { parseOptions, sanitizeHtml } from 'client/shared/core/helpers';
import './styles.scss';
import { isNil } from 'lodash';

interface Props {
  readonly html?: string;

  // If there happen to be any images in the html,
  // what alt value should we give it
  readonly imageAlt: string;
}

const baseClass = 'pn-html-content';

export const HtmlContent: React.FC<Props> = (props) => {
  if (isNil(props.html)) {
    return null;
  }
  return (
    <div className={baseClass}>
      {parse(sanitizeHtml(props.html), parseOptions(props.imageAlt))}
    </div>
  );
};
