import * as React from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router';

export interface UseQueryParameterOutput {
  readonly changeQueryParam: (
    filterParam: string,
    updatedValue: string | null
  ) => void;
  readonly changeMultipleQueryParams: (params: readonly ParameterUpdate[]) => void;
  readonly updateArrayQueryParamFn: (
    filterParam: string,
    currentFilters: readonly string[]
  ) => (updatedValue: string) => void;
}

interface ParameterUpdate {
  readonly filterParam: string;
  readonly updatedValue: string | null;
}

export function useQueryParameter(): UseQueryParameterOutput {
  const history = useHistory();
  const changeQueryParam = React.useCallback(
    (filterParam: string, updatedValue: string | null) => {
      const updatedParams = new URLSearchParams(history.location.search);
      updatedValue
        ? updatedParams.set(filterParam, updatedValue)
        : updatedParams.delete(filterParam);
      history.replace({
        pathname: history.location.pathname,
        search: `?${updatedParams.toString()}`,
      });
    },
    [history]
  );
  const changeMultipleQueryParams = React.useCallback(
    (p: readonly ParameterUpdate[]) => {
      const updatedParams = new URLSearchParams(history.location.search);
      p.forEach((param) => {
        param.updatedValue
          ? updatedParams.set(param.filterParam, param.updatedValue)
          : updatedParams.delete(param.filterParam);
      });
      history.replace({
        pathname: history.location.pathname,
        search: `?${updatedParams.toString()}`,
      });
    },
    [history]
  );
  const updateArrayQueryParamFn = React.useCallback(
    (filterParam: string, currentFilters: readonly string[]) => {
      return (updatedValue: string) => {
        if (currentFilters.includes(updatedValue)) {
          const updatedFilter = _.without(currentFilters, updatedValue);
          changeQueryParam(
            filterParam,
            updatedFilter.length === 0 ? null : updatedFilter.join(',')
          );
        } else {
          const updatedFilter = [...currentFilters, updatedValue];
          changeQueryParam(filterParam, updatedFilter.join(','));
        }
      };
    },
    [changeQueryParam]
  );

  return {
    changeQueryParam,
    changeMultipleQueryParams,
    updateArrayQueryParamFn,
  };
}
