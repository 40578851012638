import {
  ClickableDiv,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { ColorIndicator } from 'client/shared/components/color-indicator';
import React from 'react';
import { TransformedData, baseClass } from '../comparison-group-result-table-column';

interface AreaNameProps {
  readonly data: TransformedData;
  readonly isActionable: boolean;
  readonly isHidden: boolean;
  readonly isHoverRow: boolean;
  readonly onRowClick: (rowId: string) => any;
  readonly setHoverLine: (rowId: string | null) => void;
}
export const AreaName: React.FC<AreaNameProps> = (p) => {
  if (p.isActionable) {
    return (
      <ClickableDiv
        action={() => p.onRowClick(p.data.recentDatum?.fipsArea.id ?? p.data.id)}
        className="w-100"
        onMouseOut={() => {
          p.setHoverLine(null);
        }}
        onMouseOver={() => {
          p.setHoverLine(p.data.dataAreaFips);
        }}
      >
        <AreaNameUI
          isHidden={p.isHidden}
          isHoverRow={p.isHoverRow}
          isNull={p.data.recentDatum?.value ? false : true}
          itemColor={p.data.color}
          itemName={p.data.name}
          rank={p.data.rank}
        />
      </ClickableDiv>
    );
  }
  return (
    <div
      className={`${baseClass}-row d-flex align-items-center justify-content-center w-100`}
    >
      <div className={`mr-2 font-size-lg`}>
        {`${p.data.rank ? `${p.data.rank}.` : ''}`}
      </div>
      <p className={`${baseClass}-row-name font-size-lg text-nowrap`}>
        {p.data.name}
      </p>
    </div>
  );
};

AreaName.displayName = 'AreaName';

interface AreaNameUiProps {
  readonly rank: number;
  readonly itemName: string;
  readonly itemColor: string;
  readonly isNull?: boolean;
  readonly isHidden: boolean;
  readonly isHoverRow: boolean;
}

const AreaNameUI: React.FC<AreaNameUiProps> = (props) => {
  const characteristicBaseClass = 'characteristic-indicator';
  return (
    <div
      className={`${characteristicBaseClass} d-flex align-items-center justify-content-center`}
    >
      {props.isHidden ? (
        <MaterialIcon
          className="font-size-sm mr-2"
          icon={MaterialIconName.VISIBILITY_OFF}
        />
      ) : (
        <div className={`d-flex flex-row align-items-center text-nowrap`}>
          <div className={`mr-2 font-size-lg`}>
            {`${props.rank ? `${props.rank}.` : ''}`}
          </div>

          {props.itemColor && (
            <ColorIndicator
              className="mr-2"
              color={props.itemColor}
              isNull={props.isNull}
            />
          )}
        </div>
      )}

      <p
        className={`${characteristicBaseClass}-name font-size-lg ${props.isHoverRow && 'font-weight-bold'} text-nowrap`}
      >
        {props.itemName}
      </p>
    </div>
  );
};

AreaNameUI.displayName = 'AreaNameUI';
