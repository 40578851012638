import { ApiDate } from 'core';
import { GqlComment, LoadableComment, VotingComment } from '../types';

export function canLoadMore(lc: LoadableComment<any>): boolean {
  return lc.type === 'LOADING' || lc.type === 'PARTIALLY_LOADED';
}

export function graphqlCommentToPropsComment(
  comment: GqlComment & {
    readonly isOwnComment?: boolean;
  }
): VotingComment {
  return {
    id: comment.id,
    commenterChoiceId: comment.commenterChoice?.id ?? null,
    comment: comment.comment,
    commenterId: comment.commenter.id,
    commenterFirstName: comment.commenter.firstName,
    commenterLastName: comment.commenter.lastName,
    commenterImageUrl: comment.commenter.avatarUrl,
    upvoteCount: comment.upvoteCount,
    isOwnComment: comment.isOwnComment ?? false,
    updatedAt: ApiDate.fromApi(comment.commentDate),
  };
}
