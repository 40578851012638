import * as React from 'react';
import backgroundImageURL from './assets/not-found.svg';
import { NOT_FOUND_COPY } from './copy';
import { ErrorNotice } from './error-notice';
import { useRedirect } from 'client/shared/hooks';

export const ErrorNotFound = (p: {
  readonly copy: {
    readonly continue: string;
    readonly body: string;
  };
  readonly continueRoute: string;
}) => {
  const redirect = useRedirect();
  return (
    <ErrorNotice
      bodyText={p.copy.body}
      continueButton={{
        title: p.copy.continue,
        action: () => redirect(p.continueRoute, { push: true }),
      }}
      image={{ src: backgroundImageURL, alt: 'Not found' }}
      title={NOT_FOUND_COPY.title}
    />
  );
};
