import { MultiPolygon } from '@turf/helpers';
import * as React from 'react';
import MapView from '@arcgis/core/views/MapView';
import Map from '@arcgis/core/Map';
import { Extent } from '@arcgis/core/geometry';
import { drawPolygons, orderedHexColors } from './helpers';
// import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import './styles.scss';
import { MapExtentBounds } from 'core';
export interface MapProps {
  readonly height?: number;
  readonly shapeBuckets: readonly MapBucket[];
  readonly comparisonGroupId: string;
  readonly mapBounds?: MapExtentBounds;
  readonly onMapReady?: React.Dispatch<React.SetStateAction<MapView | null>>;
}

export interface MapBucket {
  readonly shape: MultiPolygon;
  readonly name: string;
  readonly percentileIndex: number;
  readonly performanceDatumValue: string;
}

const buildLegend = () => {
  const labelMargin = (idx: number, numElements: number) => {
    if (idx === 0) {
      return '-5px';
    } else if (idx === numElements - 1) {
      return '-15px';
    } else {
      return '-10px';
    }
  };
  const entries = [...orderedHexColors, null].map((color, idx) => {
    return (
      <div
        className="pn-track-map-legend-entry d-flex flex-column"
        key={idx}
        style={{ width: color ? '100%' : '0px' }}
      >
        <div
          className="pn-track-map-legend-entry-color mx-0"
          style={{
            backgroundColor: color ?? 'white',
            border: color ? '1px solid #b4b9b4' : 'none',
            width: color ? '100%' : '0px',
          }}
        />
      </div>
    );
  });

  const entryLables = entries.map((_entry, idx) => {
    return (
      <div
        className="pn-track-map-legend-entry-label"
        key={idx}
        style={{
          fontSize: '10px',
          marginLeft: labelMargin(idx, orderedHexColors.length + 1),
        }}
      >
        {idx * 10}
      </div>
    );
  });
  return (
    <div className="pn-track-map-legend d-flex flex-column align-items-center">
      <div className="text-center font-weight-bold pn-track-map-legend-title">
        Percentile Range
      </div>
      <div className="pn-track-map-legend-color-blocks d-flex">{entries}</div>
      <div className="pn-track-map-legend-color-block-labels d-flex justify-content-between w-100">
        {entryLables}
      </div>
    </div>
  );
};

export const TrackMap: React.FC<MapProps> = (props) => {
  const mapDiv = React.useRef(null);
  React.useEffect(() => {
    if (mapDiv.current) {
      const esriMap = new Map({
        basemap: 'streets-navigation-vector',
      });

      const extent = props.mapBounds
        ? new Extent({
            xmin: props.mapBounds.xMin,
            xmax: props.mapBounds.xMax,
            ymin: props.mapBounds.yMin,
            ymax: props.mapBounds.yMax,
          })
        : undefined;
      const view = new MapView({
        container: mapDiv.current,
        map: esriMap,
        zoom: 3,
        center: [-89.681032, 38.5],
        extent,
      });

      drawPolygons(view, props.shapeBuckets);

      if (props.onMapReady) {
        props.onMapReady(view);
      }
    }
  }, [props.onMapReady]);

  return (
    <div className="pn-track-map">
      <div
        ref={mapDiv}
        style={{
          width: '100%',
          height: props.height ?? '100%',
          borderRadius: '16px',
        }}
      />
      {buildLegend()}
    </div>
  );
};

TrackMap.displayName = 'TrackMap';
