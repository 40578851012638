import * as React from 'react';
import './styles.scss';
import classNames from 'classnames';

import muchHigherImage from './assets/benchmark-much-more-favorable.svg';
import higherImage from './assets/benchmark-more-favorable.svg';
import similarImage from './assets/benchmark-similar.svg';
import lowerImage from './assets/benchmark-less-favorable.svg';
import muchLowerImage from './assets/benchmark-much-less-favorable.svg';
import { BENCHMARK_COPY } from './copy';
import { ExpandingPill, PillTypes, ExpandMode } from '../base';
import { BenchmarkValue } from 'core';
import { baseClass as pillBaseClass } from '../base/pill';

export interface Props {
  readonly benchmarkValue: BenchmarkValue | null;
  readonly expandMode: ExpandMode;
  readonly className?: string;
}

type BenchmarkValueFields = {
  readonly benchmarkText: string;
  readonly benchmarkIcon: string | null;
} & (
  | {
      readonly pillType: Exclude<PillTypes, PillTypes.UNSTYLED>;
    }
  | {
      readonly pillType: PillTypes.UNSTYLED;
      readonly pillClass: string;
    }
);

enum BenchmarkIndicatorType {
  NOT_YET_AVAILABLE = 'NOT_YET_AVAILABLE',
}

const baseClass = 'pn-benchmark-indicator';

const INDICATOR_WIDTH = 44;

const benchmarkPill: Record<
  BenchmarkValue | BenchmarkIndicatorType,
  BenchmarkValueFields
> = {
  [BenchmarkValue.MUCH_HIGHER]: {
    pillType: PillTypes.UNSTYLED,
    benchmarkText: BENCHMARK_COPY.muchHigher,
    benchmarkIcon: muchHigherImage,
    pillClass: 'mod-much-higher',
  },
  [BenchmarkValue.HIGHER]: {
    pillType: PillTypes.SUCCESS,
    benchmarkText: BENCHMARK_COPY.higher,
    benchmarkIcon: higherImage,
  },
  [BenchmarkValue.SIMILAR]: {
    pillType: PillTypes.UNSTYLED,
    benchmarkText: BENCHMARK_COPY.similar,
    benchmarkIcon: similarImage,
    pillClass: 'mod-similar',
  },
  [BenchmarkValue.LOWER]: {
    pillType: PillTypes.WARNING,
    benchmarkText: BENCHMARK_COPY.lower,
    benchmarkIcon: lowerImage,
  },
  [BenchmarkValue.MUCH_LOWER]: {
    pillType: PillTypes.UNSTYLED,
    benchmarkText: BENCHMARK_COPY.muchLower,
    benchmarkIcon: muchLowerImage,
    pillClass: 'mod-much-lower',
  },
  [BenchmarkIndicatorType.NOT_YET_AVAILABLE]: {
    pillType: PillTypes.UNSTYLED,
    benchmarkText: BENCHMARK_COPY.notYetAvailable,
    benchmarkIcon: null,
    pillClass: 'mod-not-yet-available',
  },
};

export const BenchmarkIndicator: React.FunctionComponent<Props> = (props: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [expandLeft, setExpandLeft] = React.useState(false);
  React.useEffect(() => {
    const current = ref.current;
    if (!current) return;
    const pill = ref.current.getElementsByClassName(pillBaseClass)[0];
    const container = ref.current.offsetParent;
    if (!pill || !container) return;

    const observer = new ResizeObserver(() => {
      const spaceToRight = container.clientWidth - current.offsetLeft;
      setExpandLeft(spaceToRight < pill.clientWidth);
    });

    observer.observe(pill);
    observer.observe(container);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  const pillTypeToRender =
    benchmarkPill[props.benchmarkValue ?? BenchmarkIndicatorType.NOT_YET_AVAILABLE];
  const classes = classNames(baseClass, props.className ?? '');
  const pillClass = `${baseClass}-pill ${
    pillTypeToRender.pillType === PillTypes.UNSTYLED
      ? pillTypeToRender.pillClass
      : ''
  } ${expandLeft ? 'float-right' : ''}`;

  // Do not support collapsed pill states for indicators without an icon
  if (
    props.expandMode !== ExpandMode.ALWAYS_EXPANDED &&
    !pillTypeToRender.benchmarkIcon
  ) {
    return null;
  }

  return (
    <div className="d-flex" ref={ref}>
      <ExpandingPill
        className={classes}
        expandMode={props.expandMode}
        expandedClassName="font-size-sm"
        expandedText={pillTypeToRender.benchmarkText}
        expandedWidth={INDICATOR_WIDTH}
        pillClass={pillClass}
        type={pillTypeToRender.pillType}
      >
        {pillTypeToRender.benchmarkIcon && (
          <span
            className={`${baseClass}-icon-wrapper d-inline-flex align-items-center justify-content-center`}
          >
            <img
              alt={pillTypeToRender.benchmarkText}
              className={`${baseClass}-icon`}
              src={pillTypeToRender.benchmarkIcon}
            />
          </span>
        )}
      </ExpandingPill>
    </div>
  );
};

BenchmarkIndicator.displayName = 'BenchmarkIndicator';
