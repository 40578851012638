import { makePixel, socialWindow } from './util';
import { values } from 'lodash';
import { Triggers } from './popup-window';
import { ClientPublishingEntityId } from '../core/publishing-entity';
import { TWITTER_AUTH_PUBLISHING_ENTITY_QUERY_PARAM } from 'core';

const TwitterConversions = {
  CompleteSignup: 'nzjkn',
};

export function trackTwitterConversion(type: keyof typeof TwitterConversions) {
  const pixelId = TwitterConversions[type];
  (window as any).twttr.conversion.trackPid(pixelId, {
    tw_sale_amount: 0,
    tw_order_quantity: 0,
  });
}

export function initTwitterPixel() {
  const scriptTwitterOct = document.createElement('script') as HTMLScriptElement;
  scriptTwitterOct.src = 'https://platform.twitter.com/oct.js';
  scriptTwitterOct.type = 'text/javascript';
  document.getElementsByTagName('body')[0].appendChild(scriptTwitterOct);
  values(TwitterConversions).forEach((pixelId) => {
    const url1 = `https://analytics.twitter.com/i/adsct?txn_id=${pixelId}&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0`;
    const url2 = `https://t.co/i/adsct?txn_id=${pixelId}&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0`;
    makePixel(url1, url2);
  });
}

export function associatePublishingEntityWithTwitter(
  pubId: ClientPublishingEntityId,
  cb: (status: '200' | '422' | '500') => void
) {
  Triggers.once('social_connection_modal:close', cb);
  window.open(
    `/auth/twitter?${TWITTER_AUTH_PUBLISHING_ENTITY_QUERY_PARAM}=${pubId}`,
    'twitter-connect',
    'width=626,height=436'
  );
}

export function twitterShare(url: string, text: string = '') {
  const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}${
    text ? `&text=${text}` : ''
  }`;
  socialWindow(shareUrl);
}
