import * as React from 'react';
import backgroundImageURL from './assets/womanwithclipboard.svg';
import { FORBIDDEN_COPY } from './copy';
import { ErrorNotice, ContinueButtonType } from './error-notice';

export const ErrorForbidden = (props: {
  readonly bodyText: string;
  readonly continueButton: ContinueButtonType;
  readonly hideContact?: boolean;
}) => {
  return (
    <ErrorNotice
      image={{ src: backgroundImageURL, alt: 'No permission' }}
      title={FORBIDDEN_COPY.title}
      {...props}
    />
  );
};
