import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import {
  StoreItemProvider,
  StoreItemCategory,
  Brand,
  wrap,
  ApiDate,
  ExtractGql,
} from 'core';

type GqlStoreItem =
  | Gql.AdminStore['adminOrderedStoreItems'][0]
  | NonNullable<Gql.AdminStoreItem['adminStoreItemByIdOrKey']>
  | NonNullable<
      ExtractGql<
        NonNullable<NonNullable<Gql.CurrentAdmin['currentUser']['user']>['admin']>,
        'PrivateAdmin'
      >['activePublishingEntity']
    >['storeTiers'][0];

export interface UIStoreItem {
  readonly id: string;
  readonly provider: StoreItemProvider;
  readonly name: string;
  readonly shortDescription: string;
  readonly longDescription: string;
  readonly freeTrialLengthMs: number | null;
  readonly purchaseLengthMs: number | null;
  readonly repurchaseDelayMs: number | null;
  readonly key: string;
  readonly category: StoreItemCategory;
  readonly thumbnailUrl: string | null;
  readonly mainImageUrl: string | null;
  readonly oneTime: boolean;
  readonly plans: readonly Plan[];
  readonly linkedFeatureSettingId: string | null;
  readonly childStoreItems: readonly UIChildStoreItem[];
  readonly stripeId: string | null;
  readonly isHidden: boolean;
}

export interface UIStoreItemWithStatus {
  readonly storeItem: UIStoreItem;
  readonly expiresAt: Date;
  readonly status: StoreItemPublisherStatus;
  readonly purchasedInStripe: boolean;
}

export interface UIChildStoreItem {
  readonly id: string;
  readonly key: string;
  readonly name: string;
  readonly shortDescription: string;
  readonly category: StoreItemCategory;
  readonly order: number | null;
  readonly isHiddenInUI: boolean;
}

export interface Plan {
  readonly id: string;
  readonly active: boolean | null;
  readonly amount: number | null;
  readonly billingScheme: Gql.BillingScheme | null;
  readonly interval: PlanInterval;
  readonly intervalCount: number;
  readonly nickname: string | null;
}

export enum DiscountMode {
  PERCENTAGE = 'PERCENTAGE',
  DOLLAR_AMOUNT = 'DOLLAR_AMOUNT',
}

export interface Discount {
  readonly id: string;
  readonly discountMode: DiscountMode;
  readonly amountOff: number;
}

export enum StoreItemPublisherStatus {
  ACTIVATED = 'ACTIVATED',
  ACTIVATION_EXPIRED = 'ACTIVATION_EXPIRED',
  NEVER_ACTIVATED_OR_TRIALED = 'NEVER_ACTIVATED_OR_TRIALED',
  TRIAL_ACTIVATED = 'TRIAL_ACTIVATED',
  TRIAL_EXPIRED = 'TRIAL_EXPIRED',
  UNPAID = 'UNPAID',
}

export enum PlanInterval {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

import nrcLogo from 'client/assets/nrc-logo.svg';
import polcoLogo from 'client/assets/polco-logo.svg';

export function getThumbnailImageForStoreItem(storeItem: UIStoreItem): string {
  if (storeItem.thumbnailUrl) {
    return storeItem.thumbnailUrl;
  }

  // Default case
  switch (storeItem.provider) {
    case StoreItemProvider.NRC:
      return nrcLogo;
    default:
      return polcoLogo;
  }
}

export function storeItemAndStatus_gqlToUi(
  gqlStoreItem: GqlStoreItem
): UIStoreItemWithStatus {
  return {
    expiresAt: ApiDate.fromApi(gqlStoreItem.expiresAt),
    storeItem: storeItem_gqlToUi(gqlStoreItem),
    status: storeItemPublisherStatus_gqlToUi(gqlStoreItem.status),
    purchasedInStripe: gqlStoreItem.purchasedInStripe,
  };
}

export function storeItem_gqlToUi(gqlStoreItem: GqlStoreItem): UIStoreItem {
  return {
    ...gqlStoreItem.storeItem,
    isHidden: gqlStoreItem.storeItem.isHiddenInUI,
    plans: gqlStoreItem.storeItem.plans
      ? stripePlan_gqlToUi(gqlStoreItem.storeItem.plans)
      : [],
    provider:
      gqlStoreItem.storeItem.provider === Gql.StoreItemProvider.NRC
        ? StoreItemProvider.NRC
        : StoreItemProvider.POLCO,
    childStoreItems: gqlStoreItem.storeItem.childStoreItems.map((child) => ({
      id: child.id,
      key: child.key,
      name: child.name,
      shortDescription: child.shortDescription,
      category: child.category,
      order: child.order,
      isHiddenInUI: child.isHiddenInUI,
    })),
  };
}

export function stripePlan_gqlToUi(
  gqlPlans: Gql.AdminStore['adminOrderedStoreItems'][0]['storeItem']['plans']
): readonly Plan[] {
  return gqlPlans.map((plan) => ({
    id: plan.id,
    active: plan.active,
    amount: plan.amount,
    billingScheme: plan.billingScheme ?? null,
    interval: wrap(() => {
      switch (plan.interval) {
        case Gql.StripeInterval.DAY:
          return PlanInterval.DAY;
        case Gql.StripeInterval.WEEK:
          return PlanInterval.WEEK;
        case Gql.StripeInterval.MONTH:
          return PlanInterval.MONTH;
        case Gql.StripeInterval.YEAR:
          return PlanInterval.YEAR;
      }
    }),
    intervalCount: plan.intervalCount,
    nickname: plan.nickname,
    product: plan.product,
    usageType: plan.usageType,
  }));
}

export function storeItemPublisherStatus_gqlToUi(
  gqlStatus: Gql.StoreItemPublisherStatus
): StoreItemPublisherStatus {
  switch (gqlStatus) {
    case Gql.StoreItemPublisherStatus.ACTIVATED:
      return StoreItemPublisherStatus.ACTIVATED;
    case Gql.StoreItemPublisherStatus.ACTIVATION_EXPIRED:
      return StoreItemPublisherStatus.ACTIVATION_EXPIRED;
    case Gql.StoreItemPublisherStatus.NEVER_ACTIVATED_OR_TRIALED:
      return StoreItemPublisherStatus.NEVER_ACTIVATED_OR_TRIALED;
    case Gql.StoreItemPublisherStatus.TRIAL_ACTIVATED:
      return StoreItemPublisherStatus.TRIAL_ACTIVATED;
    case Gql.StoreItemPublisherStatus.TRIAL_EXPIRED:
      return StoreItemPublisherStatus.TRIAL_EXPIRED;
    case Gql.StoreItemPublisherStatus.UNPAID:
      return StoreItemPublisherStatus.UNPAID;
  }
}

export type CurrencyString = Brand<string, 'currency'>;

export function centsToDollars(cents: number): CurrencyString {
  return (cents / 100).toLocaleString('en-us', {
    style: 'currency',
    currency: 'USD',
  }) as CurrencyString;
}

export function discountCentsToDollars(
  cents: number,
  discount: Discount
): CurrencyString {
  const discountedCents = wrap(() => {
    if (discount.discountMode === DiscountMode.DOLLAR_AMOUNT) {
      return cents - discount.amountOff;
    } else {
      return cents * (1 - discount.amountOff / 100);
    }
  });

  return centsToDollars(discountedCents);
}
