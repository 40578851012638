import * as React from 'react';
import './styles.scss';
import { useLocalStorage } from 'client/shared/helpers/hooks';

interface DismissableContainerProps {
  readonly dismissedKey: string;
  readonly children: (props: { readonly dismiss: () => void }) => JSX.Element | null;
}

export const DismissableContainerCopy = {
  dismiss: 'Dismiss',
};

const baseClass = 'pn-dismissable-container';

export const DismissableContainer: React.FC<DismissableContainerProps> = (p) => {
  const { dismissedKey } = p;

  const localStorage = useLocalStorage();
  const localStorageDismissed = !!localStorage?.getItem(dismissedKey);
  const [hasDismissed, setHasDismissed] = React.useState(localStorageDismissed);
  const [justDismissed, setJustDismissed] = React.useState(false);
  const dismiss = () => {
    setTimeout(() => setHasDismissed(true), 700);
    setJustDismissed(true);
    localStorage?.setItem(dismissedKey, 'true');
  };

  if (hasDismissed) {
    return null;
  }

  return (
    <div className={`${justDismissed ? `${baseClass}-hide` : ''}`}>
      {p.children({ dismiss })}
    </div>
  );
};
