import * as React from 'react';
import './styles.scss';

interface RadioButtonProps {
  readonly onChange: () => void;
  readonly checked: boolean;
  readonly disabled?: boolean;
  readonly name: string;
  readonly label: string;
  readonly required: boolean;
  readonly uniqueId: string;
}

const baseClass = 'pn-radio-button';

export const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const { onChange, checked, disabled, label, name, required } = props;
  return (
    <label
      className={`${baseClass}-label p-3 ${!disabled ? 'cursor-pointer' : ''}`}
      htmlFor={props.uniqueId}
    >
      <div className="d-none">{label}</div>
      <input
        aria-label={label}
        checked={checked}
        className={`${baseClass}-radio`}
        disabled={disabled}
        id={props.uniqueId}
        name={name}
        onChange={onChange}
        required={required}
        type="radio"
      />
    </label>
  );
};
