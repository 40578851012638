import * as React from 'react';
import { TextInput, TextInputProps } from '../text-input';
import classNames from 'classnames';
import './styles.scss';
import { usePrevious } from 'client/shared/helpers/hooks';

export interface TextAreaProps extends TextInputProps {
  readonly applyAccessibilityStyles?: boolean;
  readonly autoExpand?: boolean;
  readonly noResize?: boolean;
  readonly ariaLabel?: string;
  readonly tabChange?: string;
  readonly optional?: boolean;
}

export const TextArea: React.FC<TextAreaProps> = (p) => {
  let textareaEl: HTMLInputElement;

  const getDimensions = () => {
    return {
      height: textareaEl ? textareaEl.scrollHeight : 0,
    };
  };

  const [dimensions, setDimensions] = React.useState(getDimensions);

  const handleResize = () => {
    setDimensions(getDimensions());
  };

  const prevCount = usePrevious(p.tabChange);
  const prevValue = usePrevious(p.value);

  React.useEffect(() => {
    if (!p.autoExpand) {
      return;
    }
    //If there is a tab view change new height for textarea is calculated.
    //If there is a diff between value also height is re-calculated.
    if (prevCount !== p.tabChange || prevValue !== p.value) {
      handleResize();
    }
  });

  return (
    <TextInput
      applyAccessibilityStyles={p.applyAccessibilityStyles}
      ariaLabel={p.ariaLabel}
      as="textarea"
      className={classNames(p.className, 'pn-text-area', {
        'mod-no-resize': p.noResize,
        'mod-auto-expand': p.autoExpand,
      })}
      inputClassName={p.inputClassName}
      onMount={(el) => {
        textareaEl = el;
        if (dimensions.height !== 0) {
          textareaEl.style.height = `${dimensions.height}px`;
        }
      }}
      {...p}
    />
  );
};
