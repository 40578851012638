import { wrap } from 'core';
import _ from 'lodash';
import React from 'react';

export function commaSeparatedElementsJoin(
  list: readonly JSX.Element[],
  args?: {
    readonly useOxfordComma?: boolean;
    readonly separator?: string;
  }
): JSX.Element {
  const separator = args?.separator ?? 'and';
  const isEmptySeparator = separator.length === 0;
  if (list.length === 0) {
    return <></>;
  }
  if (list.length === 1) {
    return <>{list[0]}</>;
  }
  // Compact is not strictly necessary for rendering, but makes testing easier
  const items = _.compact(
    _.flatMap(list, (item, itemIdx) => {
      const postElement: string | null = wrap(() => {
        if (itemIdx < list.length - 2) {
          return ', ';
        }
        if (itemIdx === list.length - 2) {
          if (isEmptySeparator) {
            return ', ';
          }
          return args?.useOxfordComma && list.length > 2
            ? `, ${separator} `
            : ` ${separator} `;
        }
        return null;
      });
      return [item, postElement];
    })
  );
  return <>{items}</>;
}

export function toSingleLetterCapitalized(title: string): string {
  if (title.length === 0) {
    return title;
  }
  const correctedTitle = title
    .split(' ')
    .map((word) =>
      word !== word.toLocaleUpperCase() ? word.toLocaleLowerCase() : word
    )
    .join(' ');

  return `${correctedTitle[0].toLocaleUpperCase() + correctedTitle.substring(1)}`;
}

export function extractVariableIdFromConcatenatedVisualizationId(
  fullId: string,
  visId: string
) {
  return fullId.replace(`-${visId}`, '');
}
