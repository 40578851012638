import * as React from 'react';
import { Well } from 'client/shared/components/base';
import { useClock } from './use-clock';
import { Props as WellProps } from '../components/base/well';

export interface WellMessageProps extends WellProps {
  readonly message: string;
  readonly timeMs?: number;
}

const DEFAULT_TIMEOUT = 5000;

export function useWellMessage(): readonly [
  React.FC,
  (msg: WellMessageProps) => void
] {
  const [props, setProps] = React.useState<WellMessageProps | null>(null);
  const clock = useClock();

  function setWellMessage(msg: WellMessageProps) {
    setProps(msg);
    clock.setTimeout(() => setProps(null), msg.timeMs ?? DEFAULT_TIMEOUT);
  }

  const wellMessage: React.FC = () =>
    props ? <Well {...props}>{props.message}</Well> : null;

  return [wellMessage, setWellMessage];
}
