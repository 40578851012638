import React from 'react';
import { COPY, MODERATION_EMAIL } from './copy';
import './styles.scss';
import {
  OptionListMenu,
  OptionListMenuOptionProps,
} from 'client/shared/components/base';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { MaterialIconName } from '../base/material-icon';

export enum IdeaActionType {
  SHARE = 'share',
  REPORT = 'report',
}
export interface IdeaMoreMenuProps {
  readonly options: readonly OptionListMenuOptionProps[];
}

const baseClass = 'pn-idea-menu';

export const IdeaMoreMenu: React.FC<IdeaMoreMenuProps> = (p) => {
  return (
    <OptionListMenu
      className="mr-1"
      options={p.options}
      toggle={{
        icon: MaterialIconName.MORE_HORIZ,
        className: `${baseClass}-action-toggle rounded`,
      }}
    />
  );
};

export function mapActionToIcon(type: IdeaActionType): MaterialIconName {
  switch (type) {
    case IdeaActionType.SHARE:
      return MaterialIconName.SHARE;
    case IdeaActionType.REPORT:
      return MaterialIconName.FLAG;
    default:
      return MaterialIconName.OPEN_IN_NEW;
  }
}

function insertEncodedLines(lines: readonly string[]) {
  const encodedLines = lines.map(encodeURIComponent);
  return encodedLines.join('%0D%0A%0D%0A');
}

export function mapIdeaTypeToMenuItem(args: {
  readonly actionType: IdeaActionType;
  readonly idea: {
    readonly id: string;
    readonly title: string;
  };
  readonly action: () => void;
}) {
  const { actionType } = args;

  switch (actionType) {
    case IdeaActionType.SHARE:
      return {
        label: COPY.actions.share,
        action: args.action,
        icon: mapActionToIcon(actionType),
      };
    case IdeaActionType.REPORT:
      return {
        label: COPY.actions.report.label,
        description: COPY.actions.report.description.intro,
        icon: mapActionToIcon(actionType),
        action: args.action,
      };
  }
}

export function reportIdea(args: { readonly id: string; readonly title: string }) {
  const { intro, reasons, link, thankYou } = COPY.actions.report.mailto.body;
  window.location.href = `mailto:${MODERATION_EMAIL}?subject=${encodeURIComponent(
    `${COPY.actions.report.mailto.subject} ${args.title}`
  )}&body=${insertEncodedLines([
    intro,
    reasons,
    `${link} ${shareIdeaUrl(args.id)}`,
    thankYou,
  ])}`;
}

export function shareIdeaUrl(id: string) {
  return ClientUrlUtils.common.pathToUrl(
    ClientUrlUtils.respondent.viewIdea.path(id)
  );
}
