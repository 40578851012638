export const MODERATION_EMAIL = 'moderation@polco.us';

export const COPY = {
  actions: {
    share: 'Share via...',
    report: {
      label: 'Report',
      description: {
        intro: `If the Report link does not work for you, copy the email address ${MODERATION_EMAIL} into your email application.`,
      },
      mailto: {
        subject: 'Report a post: ',
        body: {
          intro:
            'Thank you for bringing this issue to our attention! Please share your reason(s) for reporting this post below.',
          reasons: 'Reason(s) for reporting: ',
          link: 'Link:',
          thankYou: 'Thank you!',
        },
      },
    },
  },
};
