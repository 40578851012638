import { UseUploadOptions } from '../hooks/use-upload';

export interface CloudinaryResult {
  readonly asset_id: string;
  readonly batchId: string;
  readonly bytes: number;
  readonly coordinates?: {
    readonly custom: readonly number[];
  };
  readonly created_at: Date;
  readonly etag: string;
  readonly id: string;
  readonly format?: string; // 'jpg' | 'png' | ...
  readonly height?: number;
  readonly original_extension?: string; // 'jpg' | 'jpeg' | 'png' | ...
  readonly original_filename: string;
  readonly path: string;
  readonly placeholder: boolean;
  readonly public_id: string;
  readonly resource_type: 'image' | 'video' | 'raw';
  readonly secure_url: string;
  readonly signature: string;
  readonly tags: readonly string[];
  readonly thumbnail_url?: string;
  readonly type: 'upload';
  readonly url: string;
  readonly version: number;
  readonly width?: number;
}

export interface CloudinaryError {
  readonly status: string;
  readonly statusText: string;
}

/**
 * @see https://cloudinary.com/documentation/upload_widget_reference
 */

export async function openCloudinaryUpload(
  args?: UseUploadOptions
): Promise<CloudinaryResult | null> {
  return new Promise((resolve, reject) => {
    (window as any).cloudinary.openUploadWidget(
      {
        ...args,
        cloud_name: 'polco-us',
        upload_preset: cloudinaryUploadPreset,
        cropping: true,
        showPoweredBy: false,
      },
      function (
        error: CloudinaryError,
        result:
          | { readonly event: 'success'; readonly info: CloudinaryResult }
          | {
              readonly event: 'upload-added';
              readonly info: { readonly file: File };
            }
          | { readonly event: 'close'; readonly info: unknown }
      ) {
        if (error) {
          reject(error);
        }
        if (result.event === 'success') {
          resolve(result.info);
        } else if (result.event === 'close') {
          resolve(null);
        }
      }
    );
  });
}

let cloudinaryUploadPreset: string | null = null;

export function initCloudinary(uploadPreset: string) {
  if (!cloudinaryUploadPreset) {
    const scr = document.createElement('script') as HTMLScriptElement;
    scr.src = 'https://widget.cloudinary.com/v2.0/global/all.js';
    scr.type = 'text/javascript';
    const existingScr = document.getElementsByTagName('script')[0];
    if (existingScr && existingScr.parentNode) {
      existingScr.parentNode.insertBefore(scr, existingScr);
      cloudinaryUploadPreset = uploadPreset;
    }
  }
}
