import React, { useEffect } from 'react';
import './styles.scss';
import { VoteType } from 'client/shared/graphql-client/graphql-operations.g';
import { DownVoteIcon, UpVoteIcon } from '../vote-icons';
const voteBaseClassName = 'pn-up-down-vote-button';

export interface Props {
  readonly upVoteCount: number;
  readonly downVoteCount: number;
  readonly value: VoteType;
  readonly onValueChanged: (value: VoteType) => Promise<void> | void;
  readonly disableVoting?: boolean;
}

const voteCountFormatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
});

export function getVoteDiffs(args: {
  readonly newVote: VoteType;
  readonly previousVote: VoteType;
}) {
  const defaultVoteDiff = {
    upVoteDiff: 0,
    downVoteDiff: 0,
  };

  const { newVote, previousVote } = args;
  if (newVote === VoteType.UP) {
    return {
      upVoteDiff: previousVote === VoteType.UP ? -1 : 1,
      downVoteDiff: previousVote === VoteType.DOWN ? -1 : 0,
    };
  } else if (newVote === VoteType.DOWN) {
    return {
      upVoteDiff: previousVote === VoteType.UP ? -1 : 0,
      downVoteDiff: previousVote === VoteType.DOWN ? -1 : 1,
    };
  }

  return defaultVoteDiff;
}

export const UpDownVoteButton: React.FC<Props> = (props) => {
  const { upVoteCount, downVoteCount, value, onValueChanged, disableVoting } = props;

  const [inProgressVoteState, setInProgressVoteState] =
    React.useState<VoteType | null>(null);
  const [inProgressUpVotes, setInProgressUpVotes] = React.useState<number | null>(
    null
  );
  const [inProgressDownVotes, setInProgressDownVotes] = React.useState<
    number | null
  >(null);

  useEffect(() => {
    if (
      disableVoting &&
      (inProgressDownVotes || inProgressUpVotes || inProgressVoteState)
    ) {
      setInProgressVoteState(null);
      setInProgressUpVotes(null);
      setInProgressDownVotes(null);
    }
  }, [disableVoting, inProgressDownVotes, inProgressUpVotes, inProgressVoteState]);

  const voteButtonState = disableVoting
    ? VoteType.NONE
    : inProgressVoteState ?? value;

  const upVotes = inProgressUpVotes ?? upVoteCount;
  const downVotes = inProgressDownVotes ?? downVoteCount;

  const handleVoteClick = async (voteButtonType: VoteType) => {
    const updateState = async (state: VoteType) => {
      if (!disableVoting) {
        setInProgressVoteState(state);
      }
      await onValueChanged(state);
    };

    if (voteButtonState === voteButtonType) {
      await updateState(VoteType.NONE);
    } else {
      await updateState(voteButtonType);
    }
    if (disableVoting) return;

    const { upVoteDiff, downVoteDiff } = getVoteDiffs({
      newVote: voteButtonType,
      previousVote: voteButtonState,
    });
    setInProgressUpVotes((v) => (v ?? upVotes) + upVoteDiff);
    setInProgressDownVotes((v) => (v ?? downVotes) + downVoteDiff);
  };
  const upVoteClick = () => handleVoteClick(VoteType.UP);
  const downVoteClick = () => handleVoteClick(VoteType.DOWN);

  return (
    <div className={`${voteBaseClassName} d-flex align-items-center border-gray-10`}>
      <button
        className={`${voteBaseClassName}-up py-2 pl-3 pr-2 border-0`}
        onClick={upVoteClick}
      >
        <UpVoteIcon className={voteButtonState === VoteType.UP ? 'up-voted' : ''} />
        <span className="pl-1 font-size-sm text-gray-50 font-weight-bold">
          {voteCountFormatter.format(upVotes)}
        </span>
      </button>
      <span className="font-size-md text-gray-20 font-weight-bolder">|</span>
      <button
        className={`${voteBaseClassName}-down py-2 pl-2 pr-3 border-0`}
        onClick={downVoteClick}
      >
        <DownVoteIcon
          className={voteButtonState === VoteType.DOWN ? 'down-voted' : ''}
        />
        <span className="pl-1 font-size-sm text-gray-50 font-weight-bold">
          {voteCountFormatter.format(downVotes)}
        </span>
      </button>
    </div>
  );
};

UpDownVoteButton.displayName = 'UpDownVoteButton';

const viewBaseClassName = 'pn-up-down-vote-view';
export const ViewOnlyVoteButton: React.FC<
  Pick<Props, 'upVoteCount' | 'downVoteCount'>
> = (p) => {
  const { upVoteCount, downVoteCount } = p;
  return (
    <div className={`${viewBaseClassName} d-flex align-items-center border-0`}>
      <div className={`${viewBaseClassName}-up py-2 pr-2 border-0`}>
        <UpVoteIcon />
        <span className="pl-1 font-size-m text-gray-50">
          {voteCountFormatter.format(upVoteCount)}
        </span>
      </div>
      <span className="font-size-md text-gray-20 font-weight-bolder">|</span>
      <div className={`${viewBaseClassName}-down py-2 pl-2 pr-3 border-0`}>
        <DownVoteIcon />
        <span className="pl-1 font-size-m text-gray-50">
          {voteCountFormatter.format(downVoteCount)}
        </span>
      </div>
    </div>
  );
};

ViewOnlyVoteButton.displayName = 'ViewOnlyVoteButton';
