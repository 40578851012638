import { Well, WellType } from 'client/shared/components/base';
import { ChartType, TrackAreaMode, isLargeComparisonGroup, wrap } from 'core';
import React from 'react';
import { DOMAIN_CHART_CONTAINER_COPY } from './copy';
import './styles.scss';
import {
  TrackIndicatorBtnType,
  TrackIndicatorCollapseBtn,
} from 'client/shared/components/map-collapse-btn';
import { useId } from 'react-use-id-hook';
import { CollapseBtn } from 'client/shared/components/base/collapse-btn';

const COPY = {
  myCommunity: 'My Community',
};
const baseClass = 'pn-index-scores-chart-container';

interface Props {
  readonly areaMode: TrackAreaMode;
  readonly chartType: ChartType | null;
  readonly indexScoreChart: React.ReactElement;
  readonly resultsExpanded: boolean;
  readonly setChartType: React.Dispatch<React.SetStateAction<ChartType | null>>;
  readonly showMyCommunity: boolean;
  readonly toggleResultsExpanded: () => void;
  readonly areasCount: number;
}
export const GroupIndexScoreChartContainer: React.FC<Props> = (p) => {
  const elementId = useId();

  const isLargerGroup = isLargeComparisonGroup(p.areasCount);

  const chartCopy: string | null = wrap(() => {
    switch (p.chartType) {
      case ChartType.HISTOGRAM:
        return DOMAIN_CHART_CONTAINER_COPY.histogramWell;
      case ChartType.LINE:
        return DOMAIN_CHART_CONTAINER_COPY.lineChartWell;
      case ChartType.MAP:
        return DOMAIN_CHART_CONTAINER_COPY.mapWell;
      case null:
        return null;
    }
  });
  return (
    <div className={`${baseClass} mt-3`} id={elementId}>
      {p.showMyCommunity && (
        <div className="d-flex h-100 align-items-center">
          <div
            className={`bg-liberty border rounded-circle ${baseClass}-color-key`}
          />
          <span className="font-size-sm px-1">{COPY.myCommunity}</span>
        </div>
      )}
      {p.indexScoreChart}
      <Well
        className="mb-0"
        noTopMargin
        type={WellType.TEXT_BLOCK_WHITE_WITH_BORDER}
      >
        {DOMAIN_CHART_CONTAINER_COPY.histogramWell}
      </Well>

      <div className={`${baseClass}-collapse-btns d-flex justify-content-end my-3`}>
        <TrackIndicatorCollapseBtn
          action={() =>
            p.chartType === ChartType.MAP
              ? p.setChartType(null)
              : p.setChartType(ChartType.MAP)
          }
          ariaControls={elementId}
          className="mr-1"
          expanded={p.chartType === ChartType.MAP}
          type={TrackIndicatorBtnType.MAPS}
        />
        <TrackIndicatorCollapseBtn
          action={() =>
            p.chartType === ChartType.LINE
              ? p.setChartType(null)
              : p.setChartType(ChartType.LINE)
          }
          ariaControls={elementId}
          expanded={p.chartType === ChartType.LINE}
          type={TrackIndicatorBtnType.LINE}
        />
        {isLargerGroup && (
          <CollapseBtn
            action={p.toggleResultsExpanded}
            ariaControls={elementId}
            expanded={p.resultsExpanded}
          />
        )}
      </div>
      <div className="my-2">{p.children}</div>
      {chartCopy && (
        <Well
          className="mb-0"
          noTopMargin
          type={WellType.TEXT_BLOCK_WHITE_WITH_BORDER}
        >
          {chartCopy}
        </Well>
      )}
    </div>
  );
};
