import * as React from 'react';
import { TextArea } from 'client/shared/components/base';
import { FREE_TEXT_COPY } from './copy';

import './styles.scss';

export interface FreeTextVoteProps {
  readonly ariaInvalid?: boolean;
  readonly ariaLabel?: string;
  readonly inProcessComment: string;
  readonly id?: string;
  readonly updateCommentText: (comment: string) => void;
  readonly disabled?: boolean;
}

export const FreeTextVote: React.FC<FreeTextVoteProps> = (props) => {
  const baseClass = 'pn-free-text-vote';

  return (
    <div className={baseClass}>
      <TextArea
        ariaInvalid={props.ariaInvalid}
        ariaLabel={props.ariaLabel}
        autoExpand
        disabled={props.disabled}
        id={props.id}
        inputClassName="accessible-input rounded"
        onChange={(comment) => props.updateCommentText(comment)}
        placeholder={FREE_TEXT_COPY.placeholder}
        value={props.inProcessComment}
      />
    </div>
  );
};
