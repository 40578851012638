import * as React from 'react';
import {
  ClientQuestionId,
  QuestionChoice,
  QuestionTypedData_GridRow,
} from 'client/shared/core/question';
import { RadioButton } from 'client/shared/components/radio-button';
import './styles.scss';
import { sumBy } from 'lodash';
import { GridChoiceByRowId, SurveyLoadedEvents } from 'client/shared/core/types';

const baseClass = 'pn-grid-checkbox';

export interface GridVoteCheckBoxProps {
  readonly questionId: ClientQuestionId;
  readonly rows: readonly QuestionTypedData_GridRow[];
  readonly cols: readonly QuestionChoice[];
  readonly disabled?: boolean;
  readonly selectedGridChoiceRecord: GridChoiceByRowId | null;
  readonly selectGridChoice: SurveyLoadedEvents['surveySelectGridChoice'];
  readonly hasInputError: boolean;
  readonly stickyTitle: boolean;
  readonly required: boolean;
}

interface RowProps {
  readonly questionId: ClientQuestionId;
  readonly row: QuestionTypedData_GridRow;
  readonly cols: readonly QuestionChoice[];
  readonly selectedGridChoiceRecord: GridChoiceByRowId | null;
  readonly selectGridChoice: SurveyLoadedEvents['surveySelectGridChoice'];
  readonly hasInputError: boolean;
  readonly disabled?: boolean;
  readonly required: boolean;
}

interface ColsProps {
  readonly questionId: ClientQuestionId;
  readonly row: QuestionTypedData_GridRow;
  readonly cols: readonly QuestionChoice[];
  readonly selectedGridChoiceRecord: GridChoiceByRowId | null;
  readonly selectGridChoice: SurveyLoadedEvents['surveySelectGridChoice'];
  readonly disabled?: boolean;
  readonly required: boolean;
}

interface TableHeaderProps {
  readonly cols: readonly QuestionChoice[];
  readonly questionId: ClientQuestionId;
  readonly stickyTitle: boolean;
}

const renderFirstRowHeader = (
  { stickyTitle }: TableHeaderProps,
  questionHeight: number
) => (
  <td
    className={`${baseClass}-first-row-header ${
      stickyTitle ? `${baseClass}-col-label-sticky` : ''
    } bg-white`}
    style={{ top: `${questionHeight}px` }}
  ></td>
);

export const GridVoteCheckBox: React.FC<GridVoteCheckBoxProps> = (props) => {
  const {
    rows,
    cols,
    selectGridChoice,
    selectedGridChoiceRecord,
    questionId,
    hasInputError,
    disabled,
    stickyTitle,
    required,
  } = props;

  return (
    <div className={`${baseClass} ${disabled ? 'mod-disabled' : ''}`}>
      <table role="grid">
        {shouldWeRotateHeader(cols) ? (
          <TiltedTableHead
            cols={cols}
            questionId={questionId}
            stickyTitle={stickyTitle}
          />
        ) : (
          <RegularTableHead
            cols={cols}
            questionId={questionId}
            stickyTitle={stickyTitle}
          />
        )}
        <tbody className={`${baseClass}-rows`}>
          {rows.map((row) => (
            <Row
              cols={cols}
              disabled={disabled}
              hasInputError={hasInputError}
              key={row.questionId}
              questionId={questionId}
              required={required}
              row={row}
              selectGridChoice={selectGridChoice}
              selectedGridChoiceRecord={selectedGridChoiceRecord}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const TiltedTableHead: React.FC<TableHeaderProps> = (props) => {
  const [questionHeight, setQuestionHeight] = React.useState(DEFAULT_HEIGHT);
  const titleElement = document.getElementById(props.questionId);
  const height = titleElement?.getBoundingClientRect().height;

  React.useEffect(() => {
    setQuestionHeight(height ? height + HEADER_HEIGHT : DEFAULT_HEIGHT);
  }, [height]);

  return (
    <thead className={`${baseClass}-tilted-table-header`}>
      <tr className={`${baseClass}-first-row`}>
        {renderFirstRowHeader(props, questionHeight)}
        {props.cols.map((col) => (
          <th
            className={`${baseClass}-tilted-col-header ${
              props.stickyTitle ? `${baseClass}-col-label-sticky` : ''
            } font-weight-normal font-size-sm bg-white`}
            key={col.id}
            style={{ top: `${questionHeight}px` }}
          >
            <div className={`${baseClass}-tilted-col-text`}>{col.label}</div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const DEFAULT_HEIGHT = 120;
const HEADER_HEIGHT = 48;

export const RegularTableHead: React.FC<TableHeaderProps> = (props) => {
  const [questionHeight, setQuestionHeight] = React.useState(DEFAULT_HEIGHT);
  const titleElement = document.getElementById(props.questionId);
  const height = titleElement?.getBoundingClientRect().height;

  React.useEffect(() => {
    setQuestionHeight(height ? height + HEADER_HEIGHT : DEFAULT_HEIGHT);
  }, [height]);

  return (
    <thead>
      <tr>
        {renderFirstRowHeader(props, questionHeight)}
        {props.cols.map((col) => (
          <th
            className={`${
              props.stickyTitle ? `${baseClass}-col-label-sticky` : ''
            } font-weight-normal font-size-sm bg-white`}
            key={col.id}
            scope="col"
            style={{ top: `${questionHeight}px` }}
          >
            <div className="text-center">{col.label}</div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export const Row: React.FC<RowProps> = (props) => {
  const {
    row,
    cols,
    selectGridChoice,
    selectedGridChoiceRecord,
    questionId,
    hasInputError,
    disabled,
    required,
  } = props;
  return (
    <tr
      className={`my-2 py-2 ${
        hasInputError && !selectedGridChoiceRecord ? 'border border-danger' : ''
      }`}
      role="row"
    >
      <th
        className={`${baseClass}-row-label m-0 font-weight-normal font-size-sm pl-2`}
        scope="row"
      >
        <h3>{row.label}</h3>
      </th>
      <Cols
        cols={cols}
        disabled={disabled}
        questionId={questionId}
        required={required}
        row={row}
        selectGridChoice={selectGridChoice}
        selectedGridChoiceRecord={selectedGridChoiceRecord}
      />
    </tr>
  );
};

export const Cols: React.FC<ColsProps> = (props) => {
  const {
    cols,
    selectGridChoice,
    row,
    questionId,
    selectedGridChoiceRecord,
    disabled,
    required,
  } = props;

  return (
    <>
      {cols.map((col) => {
        return (
          <td
            className={`${baseClass} text-center border-0`}
            key={col.id}
            role="gridcell"
          >
            <RadioButton
              checked={selectedGridChoiceRecord?.[row.questionId] === col.id}
              disabled={disabled}
              label={`${row.label} - ${col.label}`}
              name={row.questionId}
              onChange={() => {
                selectGridChoice({
                  questionId,
                  gridChoice: {
                    colId: col.id,
                    rowId: row.questionId,
                  },
                  numChoices: cols.length,
                });
              }}
              required={required}
              uniqueId={`${col.id}-${row.questionId}`}
            />
          </td>
        );
      })}
    </>
  );
};

function shouldWeRotateHeader(cols: readonly QuestionChoice[]) {
  return 35 < sumBy(cols, (col) => col.label.length);
}
