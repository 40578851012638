import {
  Point,
  PointSymbolProps,
  PointTooltipProps,
  ResponsiveLine,
  Serie,
} from '@nivo/line';
import _ from 'lodash';
import React from 'react';
import './styles.scss';
import { DomainIndicatorsFipsAreaRow } from '../domain-indicators-group-wrapper';
import { formatIndicatorValue } from 'client/shared/core/performance-data';
import { AnalyticsValueType, DateLevel, StatisticType, TrackVariable } from 'core';
import moment from 'moment';
import { useId } from 'react-use-id-hook';
import { TrackLineChartTooltip } from './tooltip';
import { useLineChartScale } from './use-line-chart-scale';

interface Props {
  readonly variable: Pick<
    TrackVariable,
    'id' | 'name' | 'valueType' | 'direction' | 'dateLevel' | 'statisticType'
  >;
  readonly data: readonly DomainIndicatorsFipsAreaRow[];
  readonly fipsAreaVisibility: Record<string, boolean>;
  readonly hoverLine: string | null;
  readonly setHoverLine: (questionId: string | null) => void;
  readonly goalLine?: number;
}

const baseClass = 'pn-domain-indicators-group-line-chart';

export const TrackLineChart: React.FC<Props> = (p) => {
  const { setHoverLine, hoverLine } = p;
  const tooltipId = useId();
  const { yAxisValues, yAxisScaleProps, xAxisValues, xAxisScaleProps } =
    useLineChartScale({
      data: p.data,
      goalLine: p.goalLine,
      variable: p.variable,
    });
  const pointHoverEvent = React.useCallback(
    (point: Point) => {
      setHoverLine(point.serieId as string);
    },
    [setHoverLine]
  );
  const mouseOutEvent = React.useCallback(() => {
    setHoverLine(null);
  }, [setHoverLine]);
  const rows = React.useMemo(() => {
    return transformData(p.data, p.variable.dateLevel);
  }, [p.data, p.variable]);
  const { rowsShown, colorsShown } = React.useMemo<{
    readonly rowsShown: Serie[];
    readonly colorsShown: string[];
  }>(() => {
    const visibleRows = rows.filter((row) => p.fipsAreaVisibility[row.fips]);
    const colors = visibleRows.map((row) => row.color);
    return {
      rowsShown: visibleRows,
      colorsShown: colors,
    };
  }, [rows, p.fipsAreaVisibility]);
  const pointSymbolFn = React.useCallback(
    (point: Readonly<PointSymbolProps>) => {
      return point.datum.fips === hoverLine || point.datum.isSinglePoint ? (
        <circle fill={point.color} r={7} stroke={'black'} />
      ) : null;
    },
    [hoverLine]
  );
  const dataByDataAreaFips = React.useMemo(
    () => _.keyBy(p.data, ({ dataAreaFips }) => dataAreaFips),
    [p.data]
  );

  const markers = React.useMemo(() => {
    return _.compact([
      p.goalLine
        ? ({
            axis: 'y',
            value: p.goalLine,
            lineStyle: {
              stroke: '#B72E1C',
              strokeWidth: 3,
              strokeDasharray: 12,
            },
          } as const)
        : null,
    ]);
  }, [p.goalLine]);
  const tooltipFn = React.useCallback(
    (tooltipProps: React.PropsWithChildren<PointTooltipProps>) => {
      return (
        <TrackLineChartTooltip
          dataByDataAreaFips={dataByDataAreaFips}
          goalLine={p.goalLine}
          id={tooltipId}
          tooltipProps={tooltipProps}
          variable={p.variable}
        />
      );
    },
    [p.variable, tooltipId, p.goalLine, dataByDataAreaFips]
  );

  //TO-DO create data unavailable component if min or max year is unavailable
  if (!xAxisValues.length) {
    return <></>;
  }

  return (
    <div>
      <div className={`${baseClass}-full-chart`}>
        <ResponsiveLine
          axisBottom={{
            tickValues: xAxisValues,
            format: (tick) => formatXAxisValue(tick, p.variable.dateLevel),
            tickSize: 0,
            tickPadding: 10,
          }}
          axisLeft={{
            tickValues: yAxisValues,
            format: (value: number) =>
              formatValueByStatisticType({
                value,
                valueType: p.variable.valueType,
                statisticType: p.variable.statisticType,
                compactDisplay: true,
              }),
            tickSize: 0,
            tickPadding: 20,
          }}
          colors={colorsShown}
          data={rowsShown}
          enableGridX={false}
          enableGridY={true}
          gridYValues={yAxisValues}
          isInteractive
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'lines',
            'points',
            'slices',
            'mesh',
          ]}
          lineWidth={3}
          margin={{ top: 30, right: 40, bottom: 50, left: 65 }}
          markers={markers}
          onMouseLeave={mouseOutEvent}
          onMouseMove={pointHoverEvent}
          pointSize={0}
          pointSymbol={pointSymbolFn}
          theme={{
            grid: {
              line: {
                stroke: '#c6cbc8',
                strokeWidth: 1,
                strokeDasharray: '7 5',
              },
            },

            axis: {
              ticks: {
                text: {
                  fontSize: 14,
                  fontFamily: 'General Grotesque',
                  fill: '#464b47',
                },
              },
            },
          }}
          tooltip={tooltipFn}
          useMesh
          xScale={xAxisScaleProps}
          yScale={yAxisScaleProps}
        />
      </div>
    </div>
  );
};

function transformData(
  rows: readonly DomainIndicatorsFipsAreaRow[],
  dateLevel: DateLevel

  // Disabling readonly rule because charting library does not like readonly arrays
  // eslint-disable-next-line functional/prefer-readonly-type
): Serie[] {
  return rows.map((row) => {
    const performanceDataLength = row.trackAreaData.performanceData?.length ?? 0;

    switch (dateLevel) {
      case DateLevel.MONTH: {
        return {
          id: row.dataAreaFips,
          color: row.color,
          fips: row.dataAreaFips,
          data: performanceDataLength
            ? row.trackAreaData.performanceData
                ?.map((h) => ({
                  x: h.recordedAt,
                  y: h.value,
                  isSinglePoint: performanceDataLength === 1,
                  fips: row.dataAreaFips,
                }))
                .sort((a, b) => a.x.getTime() - b.x.getTime())
            : [],
        };
      }
      case DateLevel.YEAR: {
        return {
          id: row.dataAreaFips,
          color: row.color,
          fips: row.dataAreaFips,
          data: performanceDataLength
            ? row.trackAreaData.performanceData
                ?.map((h) => ({
                  x: h.recordedAt.getUTCFullYear(),
                  y: h.value,
                  isSinglePoint: performanceDataLength === 1,
                  fips: row.dataAreaFips,
                }))
                .sort((a, b) => a.x - b.x)
            : [],
        };
      }
    }
  });
}

export function formatXAxisValue(
  value: number | string | Date,
  dateLevel: DateLevel
) {
  switch (dateLevel) {
    case DateLevel.MONTH:
      return moment(value).utc().format('MMM YYYY');
    case DateLevel.YEAR:
      return value;
  }
}

export function formatValueByStatisticType(args: {
  readonly value: number;
  readonly statisticType: StatisticType;
  readonly valueType: AnalyticsValueType;
  readonly compactDisplay?: boolean;
}) {
  const { value, statisticType, valueType, compactDisplay } = args;
  switch (statisticType) {
    case StatisticType.INDEX:
      return _.round(value).toString();
    case StatisticType.INDICATOR:
    case StatisticType.SENTIMENT_VALUE:
      return formatIndicatorValue(value, valueType, 2, false, compactDisplay);
  }
}

TrackLineChart.displayName = 'TrackLineChart';
