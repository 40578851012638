import * as React from 'react';
import { LanguageKey } from 'core';
import {
  ClientQuestionId,
  QuestionChoice,
  QuestionTypedData_GridRow,
  RandomizedSurveyItems,
} from '../core/question';
import { shuffle, compact } from 'lodash';

type ValidQuestionChoices = QuestionChoice | QuestionTypedData_GridRow;

export interface RandomizeChoicesProps {
  readonly randomizeChoices?: boolean | null;
  readonly randomizedSurveyItems?: readonly RandomizedSurveyItems[] | null;
  readonly setRandomizedSurveyItems?: (
    randomizedSurveyItems: RandomizedSurveyItems
  ) => void;
}

interface Props<T extends ValidQuestionChoices> extends RandomizeChoicesProps {
  readonly choices: ReadonlyArray<T>;
  readonly language: LanguageKey;
  readonly disabled?: boolean;
  readonly questionId: ClientQuestionId;
  readonly getChoiceId: (item: T) => string;
}

export function useRandomizedQuestionChoices<T extends ValidQuestionChoices>(
  props: Props<T>
) {
  const {
    randomizeChoices,
    disabled,
    setRandomizedSurveyItems,
    randomizedSurveyItems,
    choices: inputChoices,
    language,
    questionId,
    getChoiceId,
  } = props;
  const [choices, setChoices] = React.useState<readonly T[]>(inputChoices);

  React.useEffect(() => {
    setChoices(inputChoices);

    if (isRandomizeProps(props) && randomizeChoices && !disabled) {
      const applyRandomizeChoices = () => {
        const randomizedChoices = shuffle(inputChoices);

        const randomizedSurveyItem: RandomizedSurveyItems = {
          id: questionId,
          choices: randomizedChoices.map((choice: T) => getChoiceId(choice)),
        };

        setRandomizedSurveyItems && setRandomizedSurveyItems(randomizedSurveyItem);
        setChoices(randomizedChoices);
      };

      const stateRandomizedSurveyItems = randomizedSurveyItems?.find(
        ({ id }) => id === questionId
      );

      if (stateRandomizedSurveyItems) {
        // if choices array is different size than the randomized version or if there is at least one element missing in the choices array
        if (
          stateRandomizedSurveyItems.choices.length !== inputChoices.length ||
          !stateRandomizedSurveyItems.choices.every((choiceId) =>
            inputChoices.some((choice) => getChoiceId(choice) === choiceId)
          )
        ) {
          applyRandomizeChoices();
          return;
        }

        const randomizedStateArray = compact(
          stateRandomizedSurveyItems.choices.map((choiceId) =>
            inputChoices.find((choice) => getChoiceId(choice) === choiceId)
          )
        );

        setChoices(randomizedStateArray);
        return;
      }

      applyRandomizeChoices();
    }
  }, [inputChoices, language]); // eslint-disable-line react-hooks/exhaustive-deps

  return choices;
}

export function isRandomizeProps(object: object): object is RandomizeChoicesProps {
  return 'randomizedSurveyItems' in object && 'setRandomizedSurveyItems' in object;
}
