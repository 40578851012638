export function initTinyMCE(apiKey: string | null) {
  if (!apiKey) {
    return;
  }

  const tinyScript = document.createElement('script') as HTMLScriptElement;
  tinyScript.src = `https://cdn.tiny.cloud/1/${apiKey}/tinymce/6/tinymce.min.js`;
  tinyScript.type = 'text/javascript';
  tinyScript.referrerPolicy = 'origin';

  document.getElementsByTagName('body')[0].appendChild(tinyScript);
}
