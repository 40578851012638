import * as React from 'react';
import './styles.scss';
import { LoadedEvents, VotingComment, Choice } from 'client/shared/core/types';
import Markdown from 'markdown-to-jsx';
import { NextArrow, PrevArrow } from 'client/shared/components/base';
import { groupBy, reverse, sortBy } from 'lodash';
import { useId } from 'react-use-id-hook';
import Slider, { Settings } from 'react-slick';
import { Comment } from './comment';
import { ClientQuestionId } from 'client/shared/core/question';
import { ArrowBackgroundColors } from '../base/arrows';
import { Dropdown, convertLabelToValueMap } from '../base/dropdown_v2';
import { wrap } from 'core';

export enum CommentsType {
  ADMIN = 'ADMIN',
  RESPONDENT = 'RESPONDENT',
}
export interface AdminCommentsProps {
  readonly type: CommentsType.ADMIN;
  readonly id: ClientQuestionId;
  readonly choices: readonly Choice[];
  readonly comments: readonly VotingComment[];
  readonly isFreeTextQuestion: boolean;
}

export interface RespondentCommentsProps {
  readonly type: CommentsType.RESPONDENT;
  readonly id: ClientQuestionId;
  readonly upvoteCommentIds: readonly VotingComment['id'][];
  readonly choices: readonly Choice[];
  readonly comments: readonly VotingComment[];
  readonly isFreeTextQuestion: boolean;
  readonly isClosedQuestion: boolean;
  readonly events: {
    readonly upvoteComment: LoadedEvents['upvoteComment'];
    readonly unUpvoteComment: LoadedEvents['unUpvoteComment'];
  };
}
enum CommentViews {
  RECENTLY_UPDATED = 'RECENTLY_UPDATED',
  OPINION_LEADERS = 'OPINION_LEADERS',
  ALL = 'ALL',
}

const labelToCommentType: Record<string, CommentViews> = {
  'All comments': CommentViews.ALL,
  'Compare opinion leaders': CommentViews.OPINION_LEADERS,
  'Recently updated comments': CommentViews.RECENTLY_UPDATED,
};

export type CommentsProps = AdminCommentsProps | RespondentCommentsProps;

export const SharedCommentsInner: React.FC<CommentsProps> = (props) => {
  const baseClass = 'pn-voting-comments';

  const [commentView, setCommentView] = React.useState(CommentViews.ALL);
  const dropdownId = useId();

  return (
    <div>
      {!props.isFreeTextQuestion && (
        <Dropdown
          ariaLabel="filter comments"
          className={`${baseClass}-dropdown mb-4`}
          id={`comment-dropdown-${dropdownId}`}
          onChange={(t) => t && setCommentView(t.value)}
          options={convertLabelToValueMap(labelToCommentType)}
          placeholder="All comments"
        />
      )}

      <div className="ml-1 mr-1">
        <CommentsView {...props} commentView={commentView} />
      </div>
    </div>
  );
};

const CommentsView: React.FC<
  CommentsProps & { readonly commentView: CommentViews }
> = (props) => {
  const { commentView, comments, isFreeTextQuestion } = props;

  return (
    <>
      {wrap(() => {
        switch (commentView) {
          case CommentViews.ALL:
            return comments.map((comment) => (
              <Comment
                comment={comment}
                key={comment.id}
                showChoice={!isFreeTextQuestion}
                {...props}
              />
            ));
          case CommentViews.OPINION_LEADERS:
            return <GroupedComments {...props} />;
          case CommentViews.RECENTLY_UPDATED:
            // sortBy defaults to ascending order, which is least recent, so reverse the results.
            return reverse(sortBy(comments, (comment) => comment.updatedAt)).map(
              (comment) => (
                <Comment
                  comment={comment}
                  key={comment.id}
                  showChoice={!isFreeTextQuestion}
                  {...props}
                />
              )
            );
        }
      })}
    </>
  );
};

const GroupedComments: React.FC<CommentsProps> = (props) => {
  const groupedComments = groupBy(props.comments, (c) => c.commenterChoiceId);
  const commentsByChoice = Object.entries(groupedComments);

  const component = commentsByChoice.map(([choiceId, comments]) => {
    const choice = props.choices.find((ch) => ch.id === choiceId);
    return (
      <div key={choiceId}>
        {choice?.text && (
          <div className="text-gray-40 mt-2 mb-1">
            <Markdown>{`Top comments for **${choice.text}**`}</Markdown>
          </div>
        )}
        <SliderComments {...props} comments={comments} />
      </div>
    );
  });

  return <>{component}</>;
};
GroupedComments.displayName = 'GroupedComments';

const SliderComments: React.FC<CommentsProps> = (props) => {
  return (
    <Slider
      {...sliderSetting({
        bg:
          props.type === CommentsType.ADMIN
            ? ArrowBackgroundColors.GRAY
            : ArrowBackgroundColors.WHITE,
      })}
    >
      {reverse(sortBy(props.comments, (comment) => comment.upvoteCount)).map((c) => {
        return (
          <Comment
            comment={c}
            key={c.id}
            setMaxWidth={true}
            showChoice={false}
            {...props}
          />
        );
      })}
    </Slider>
  );
};

function sliderSetting(opts: { readonly bg: ArrowBackgroundColors }): Settings {
  return {
    arrows: true,
    nextArrow: <NextArrow bg={opts.bg} onClick={() => {}} />, // need to pass onClick to make it work with react-slick lib
    prevArrow: <PrevArrow bg={opts.bg} onClick={() => {}} />,
    accessibility: true,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };
}
