import { CurrentUser } from 'client/shared/graphql-client/graphql-operations.g';
import { ExtractGql } from 'core';

type User = NonNullable<CurrentUser['currentUser']['user']>;
type PrivateAdmin = ExtractGql<NonNullable<User['admin']>, 'PrivateAdmin'>;
type PrivateRespondent = ExtractGql<
  NonNullable<User['respondent']>,
  'PrivateRespondent'
>;

export function privateAdminFromUserAdmin<T = PrivateAdmin>(
  admin?:
    | (Omit<T, '__typename'> & { readonly __typename: 'PrivateAdmin' }) // this makes sure the admin param always has a "PrivateAdmin" field
    | { readonly __typename: 'PublicAdmin' }
    | null
): (Omit<T, '__typename'> & { readonly __typename: 'PrivateAdmin' }) | null {
  return admin?.__typename === 'PrivateAdmin' ? admin : null;
}

export function privateRespondentFromUserRespondent<T = PrivateRespondent>(
  respondent?:
    | (Omit<T, '__typename'> & { readonly __typename: 'PrivateRespondent' }) // this makes sure T always has a "PrivateRespondent" field
    | { readonly __typename: 'PublicRespondent' }
    | null
): Omit<T, '__typename'> | null {
  return respondent?.__typename === 'PrivateRespondent' ? respondent : null;
}
