import * as React from 'react';
import 'rc-slider/assets/index.css';
import {
  QuestionTypedData_GridChoice,
  ClientQuestionId,
} from 'client/shared/core/question';
import { GridVoteSlider } from './grid-slider';
import { GridVoteDropdown } from './grid-dropdown';
import { GridVoteCheckBox } from './grid-checkbox';
import { SurveyLoadedEvents, GridChoiceByRowId } from 'client/shared/core/types';
import {
  RandomizeChoicesProps,
  isRandomizeProps,
  useLanguageByQueryParam,
  useRandomizedQuestionChoices,
} from 'client/shared/hooks';

export interface GridVoteProps {
  readonly questionId: ClientQuestionId;
  readonly gridChoice: QuestionTypedData_GridChoice;
  readonly selectGridChoice: SurveyLoadedEvents['surveySelectGridChoice'];
  readonly selectedGridChoice: GridChoiceByRowId | null;
  readonly gridVoteMode: GridVoteMode;
  readonly hasInputError: boolean;
  readonly stickyTitle: boolean;
  readonly required: boolean;
  readonly disabled?: boolean;
}

export interface RandomizeGridVoteProps
  extends GridVoteProps,
    RandomizeChoicesProps {}

export type Props = GridVoteProps | RandomizeGridVoteProps;

const baseClass = 'pn-grid-vote';

export enum GridVoteMode {
  DROPDOWN = 'DROPDOWN',
  SLIDER = 'SLIDER',
  CHECKBOX = 'CHECKBOX',
}

export const GridVote: React.FC<Props> = (props) => {
  const {
    questionId,
    gridChoice,
    selectGridChoice,
    selectedGridChoice,
    gridVoteMode,
    disabled,
    hasInputError,
    stickyTitle,
    required,
  } = props;

  // either slider or dropdown: they share same props
  const GridVoteRow =
    gridVoteMode === GridVoteMode.SLIDER ? GridVoteSlider : GridVoteDropdown;

  const { language } = useLanguageByQueryParam();
  const randomizeEnabled = isRandomizeProps(props) && gridChoice.randomizeChoices;
  const rows = useRandomizedQuestionChoices({
    choices: gridChoice.rows,
    language,
    questionId: questionId,
    randomizeChoices: randomizeEnabled && gridChoice.randomizeChoices,
    randomizedSurveyItems: randomizeEnabled ? props.randomizedSurveyItems : null,
    disabled: props.disabled,
    setRandomizedSurveyItems: randomizeEnabled
      ? props.setRandomizedSurveyItems
      : undefined,
    getChoiceId: (row) => row.questionId,
  });
  return (
    <div>
      {gridVoteMode === GridVoteMode.CHECKBOX ? (
        <GridVoteCheckBox
          cols={gridChoice.columns}
          disabled={disabled}
          hasInputError={hasInputError}
          questionId={questionId}
          required={required}
          rows={rows}
          selectGridChoice={selectGridChoice}
          selectedGridChoiceRecord={
            selectedGridChoice ? selectedGridChoice ?? null : null
          }
          stickyTitle={stickyTitle}
        />
      ) : (
        <>
          {rows.map((row) => {
            return (
              <div className="my-3 mb-4 pb-4" key={row.questionId}>
                {gridVoteMode !== GridVoteMode.DROPDOWN && (
                  <h3
                    className={`${baseClass}-row-label font-weight-bold font-size-sm`}
                  >
                    {row.label}
                  </h3>
                )}

                <GridVoteRow
                  cols={gridChoice.columns.map((c) => ({
                    id: c.id,
                    label: c.label,
                  }))}
                  currentRowId={row.questionId}
                  disabled={disabled}
                  hasInputError={hasInputError}
                  label={row.label}
                  questionId={questionId}
                  selectGridChoice={selectGridChoice}
                  selectedGridChoice={
                    selectedGridChoice
                      ? selectedGridChoice[row.questionId] ?? null
                      : null
                  }
                />
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
