import { useMutationInfo } from 'client/shared/containers/mutation';
import { useQueryInfo } from 'client/shared/containers/query';
import { ClientPublishingEntityId } from 'client/shared/core/publishing-entity';
import {
  CreateNewTrackGoalInput,
  UpdateTrackGoalInput,
} from 'client/shared/core/saved-visualization';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { QueryInfos } from 'client/shared/graphql-queries';
import { StrategicPlan } from 'client/shared/graphql-client/graphql-operations.g';
export type GqlGoal = StrategicPlan['goals'][0];

export function useTrackGoals(args: {
  readonly publishingEntityId: ClientPublishingEntityId;
  readonly skipQueryingPlans?: boolean;
}) {
  const { publishingEntityId } = args;

  const pubStrategicPlans = useQueryInfo(
    QueryInfos.adminPublishingEntityStrategicPlans,
    {
      variables: {
        publishingEntityId,
      },
      skip: args.skipQueryingPlans,
    }
  );

  const plan =
    pubStrategicPlans.data?.openPublishingEntityById?.strategicPlans.at(0);

  const { fn: saveNewGoalMut } = useMutationInfo(
    MutationInfos.adminSaveNewTrackGoal
  );
  const { fn: updateGoalMut } = useMutationInfo(MutationInfos.adminUpdateTrackGoal);
  const { fn: deleteGoalMut } = useMutationInfo(MutationInfos.adminDeleteTrackGoal);

  const saveNewTrackGoal = async (clientInput: CreateNewTrackGoalInput) => {
    await saveNewGoalMut({
      variables: {
        input: {
          ...clientInput,
          publishingEntityId,
        },
      },
    });
  };
  const updateTrackGoal = async (clientInput: UpdateTrackGoalInput) => {
    await updateGoalMut({
      variables: {
        input: {
          ...clientInput,
          publishingEntityId,
        },
      },
    });
  };
  const deleteTrackGoal = async (goalId: string) => {
    await deleteGoalMut({
      variables: {
        input: {
          goalId,
          publishingEntityId,
        },
      },
    });
  };

  return {
    saveNewTrackGoal,
    updateTrackGoal,
    deleteTrackGoal,
    plansLoading: pubStrategicPlans.loading,
    strategicPlan: plan,
  };
}
