import { DashboardBenchmarkSurveys } from 'client/shared/core/track/labels';

export const CTA_COPIES = {
  viewTheNCS: (survey: string) => `View the ${survey}`,
  title: 'Resident Opinion Data Unavailable',
  description: (survey: string) =>
    `We cannot locate a ${survey} associated with your account. Conducting the ${survey} will add to your resident opinion data and provide more accurate performance measurement.`,
};

interface Survey {
  readonly description: string;
  readonly title: string;
  readonly shortTitle: string;
}

export const SURVEYS: Record<string, Survey> = {
  [DashboardBenchmarkSurveys.NCS]: {
    title: 'National Community Survey',
    shortTitle: 'NCS',
    description:
      'The NCS provides a comprehensive and accurate picture of resident perspectives about community livability and local government services.',
  },
  [DashboardBenchmarkSurveys.NLES]: {
    title: 'National Law Enforcement Survey',
    shortTitle: 'NLES',
    description:
      'The NLES provides resident perceptions of safety in their community, their evaluations of police-resident interactions, their ratings of the quality of public safety services, and their opinion about public safety priorities. Results are intended to be used to increase safety, align resident and government priorities, and strengthen relationships between communities and public safety officers.',
  },
  [DashboardBenchmarkSurveys.NBS]: {
    title: 'National Business Survey',
    shortTitle: 'NBS',
    description:
      'The NBS provides important perspective from community business owners and managers across six core indicators of the business environment in the community: community amenities, business climate, employment opportunities and workforce support, workforce readiness, business growth, and local government support and services.',
  },
};

export const NCS_WARNING_COPIES = {
  linkText: 'More information',
  description: `This is a snapshot of the resident sentiment data included in your index score.`,
};
