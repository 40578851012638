// Generated by scripts/generate-colors.ts from _theme_palette.scss

export const GENERATED_COLORS = {
  White: '#ffffff',
  Black: '#000000',
  'Gray-60': '#303330',
  'Gray-50': '#464b47',
  'Gray-40': '#656b65',
  'Gray-30': '#90968f',
  'Gray-20': '#c6cbc8',
  'Gray-10': '#eaeceb',
  'grayscale-0': '#ffffff',
  'grayscale-1': '#fbfbfb',
  'grayscale-2': '#f7f7f7',
  'grayscale-3': '#f2f2f2',
  'grayscale-4': '#e0e0e0',
  'grayscale-5': '#bdbdbd',
  'Polco-Green-XL': '#f4f8ee',
  'Polco-Green-L': '#c7d8a0',
  'Polco-Green': '#97bb57',
  'Polco-Green-D': '#647f34',
  'Polco-Green-XD': '#3c4c21',
  'Jungle-XL': '#ecefee',
  'Jungle-L': '#3a8172',
  Jungle: '#235048',
  'Jungle-D': '#1c3c35',
  'Jungle-XD': '#132d29',
  'NRC-Gold-XL': '#f0e5d4',
  'NRC-Gold-L': '#dec6a2',
  'NRC-Gold': '#c29855',
  'NRC-Gold-D': '#8e6a32',
  'NRC-Gold-XD': '#523d1d',
  'Sky-XL': '#e9eced',
  'Sky-L': '#b0d1da',
  Sky: '#69a8b7',
  'Sky-D': '#427987',
  'Sky-XD': '#24454d',
  'Canary-XL': '#fdf5d3',
  'Canary-L': '#fdf1c2',
  Canary: '#f7d54f',
  'Canary-D': '#d8ae29',
  'Canary-XD': '#54461b',
  'Rose-XL': '#f7e4ef',
  'Rose-L': '#edc1db',
  Rose: '#de92bd',
  'Rose-D': '#a13172',
  'Rose-XD': '#543147',
  'Error-XL': '#fbeceb',
  'Error-L': '#ebb4ad',
  Error: '#b72e1c',
  'Error-D': '#6e1c11',
  'Error-XD': '#370e09',
  Danger: '#f5dad6',
  'Liberty-XL': '#e8eaf5',
  'Liberty-L': '#d2d6ed',
  Liberty: '#4e61ba',
  'Liberty-D': '#293360',
  'Liberty-XD': '#171c33',
  'Valencia-XL': '#f8dbc0',
  'Valencia-L': '#f2b881',
  Valencia: '#e26f03',
  'Valencia-D': '#713701',
  'Valencia-XD': '#381b00',
  'Royal-XL': '#ede9f5',
  'Royal-L': '#ddd4ed',
  Royal: '#7d58bb',
  'Royal-D': '#231a33',
  'Royal-XD': '#140f1c',
  'Aqua-XL': '#c7eae9',
  'Aqua-L': '#8fd2d0',
  Aqua: '#198281',
  'Aqua-D': '#136261',
  'Aqua-XD': '#0c4140',
  'Lime-XL': '#c2ebbf',
  'Lime-L': '#85d77f',
  Lime: '#0ead00',
  'Lime-M': '#0b8400',
  'Lime-D': '#075700',
  'Lime-XD': '#032a00',
  'Brand-XL': '#f4f8ee',
  'Brand-L': '#c7d8a0',
  Brand: '#97bb57',
  'Brand-D': '#6a8639',
  'Brand-XD': '#3c4c21',
} as const;

export type PolcoColor = keyof typeof GENERATED_COLORS;
