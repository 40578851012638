import { BalancingActSimulationType, QuestionSetStatus, SimulationType } from 'core';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';

export namespace BalancingAct {
  export interface Simulation {
    readonly id: number;
    readonly name: string;
    readonly isPrioritization: boolean;
    readonly type: BalancingActSimulationType;
    readonly categories: readonly Category[];
    readonly url: string;
    readonly status: QuestionSetStatus;
    readonly description: string | null;
    readonly createdAt: Date;
    // The GQL schema exposes these but we don't need them yet
    // readonly slug: string;
    // readonly allowCustomMapsInModals: boolean;
    // readonly canPreview: boolean;
    // readonly canPublish: boolean;
    // readonly updatedAt: Date;
    // readonly customizations: string | null;
    // readonly locale: string;
    // readonly pageviews: number;
    // readonly requireCustomMapsInModals: boolean;
    // readonly submissionsAvailable: boolean;
  }

  export interface Category {
    readonly id: number;
    readonly name: string;
    readonly type: CategoryType;
    readonly subcategories: readonly Subcategory[];
  }

  export interface Subcategory {
    readonly id: number;
    readonly name: string;
    readonly amount: number;
    readonly description?: string;
    readonly isLocked: boolean;
  }

  export enum CategoryType {
    REVENUE = 'revenue',
    EXPENSE = 'expense',
  }
}

export interface SimulationNodeData {
  readonly id: number;
  readonly url: string;
  readonly adminData: {
    readonly name: string;
    readonly description: string | null;
    readonly status: QuestionSetStatus;
  } | null;
}

export interface BalancingActReportingDemographics {
  // Null for now
}

export interface AdminBalancingActReportingResult {
  readonly averageSecondsOnSite: number;
  readonly averageSubmittedExpenses: number;
  readonly averageSubmittedRevenue: number;
  readonly budgetName: string;
  readonly clientName: string;
  readonly metrics: BalancingActReportingMetricGroups;
  readonly resetAllClicks: number;
  readonly status: QuestionSetStatus;
  readonly submissionCount: number;
  readonly taxpayerReceiptExperiences: number;
  readonly totalPageviews: number;
  readonly locale: string | null;
  readonly referrerPaths: readonly BalancingActReportingSource[];
  readonly sources: readonly BalancingActReportingSource[];
  readonly socialNetworks: readonly BalancingActReportingSource[];
  readonly categories: readonly BalancingActReportingCategory[];
  readonly demographics: readonly BalancingActReportingDemographics[];
}

export interface BalancingActReportingMetric {
  readonly label: string;
  readonly amount: number;
  readonly rank: number;
  readonly percentage: number;
}

export interface BalancingActReportingSource {
  readonly source: string | null;
  readonly sessions: number | null;
  readonly rank: number | null;
  readonly percentage: number | null;
}

export interface BalancingActReportingMetricGroups {
  readonly age?: readonly BalancingActReportingMetric[];
  readonly city?: readonly BalancingActReportingMetric[];
  readonly deviceType?: readonly BalancingActReportingMetric[];
  readonly gender?: readonly BalancingActReportingMetric[];
  readonly userType?: readonly BalancingActReportingMetric[];
}

export interface BalancingActReportingCategory {
  readonly averageAmountDecreased: number;
  readonly averageAmountIncreased: number;
  readonly averagePercentChange: number;
  readonly averageSessionAmountChanged: number;
  readonly averageSessionDecreased: number;
  readonly averageSessionIncreased: number;
  readonly averageSubmittedAmount: number;
  readonly averageSubmittedAmountChanged: number;
  readonly decreaseClicks: number;
  readonly detailsClicks: number;
  readonly displayOrder: number;
  readonly id: number;
  readonly increaseClicks: number;
  readonly infoClicks: number;
  readonly maxSubmittedAmount: number;
  readonly medianSessionAmountChanged: number;
  readonly medianSubmittedAmountChanged: number;
  readonly modeSessionAmountChanged: number;
  readonly modeSubmittedAmountChanged: number;
  readonly minSubmittedAmount: number;
  readonly name: string;
  readonly openClicks: number;
  readonly percentSubmissionsChanged: number;
  readonly percentSubmissionsDecreased: number;
  readonly percentSubmissionsIncreased: number;
  readonly percentSubmissionsUnchanged: number;
  readonly type: string;
  readonly total: number;
  readonly totalSubmissionsChanged: number;
  readonly totalSubmissionsDecreased: number;
  readonly totalSubmissionsIncreased: number;
  readonly totalSubmissionsUnchanged: number;
  readonly subcategories: readonly BalancingActReportingSubcategory[];
}

export interface BalancingActReportingSubcategory
  extends Omit<
    BalancingActReportingCategory,
    'openClicks' | 'type' | 'total' | 'subcategories'
  > {
  readonly amount: number;
  readonly averageSubmittedRate: number;
  readonly customQuestionText: string | null;
  readonly customizedQuestionJson: string | null;
  readonly questionOptions: readonly BalancingActReportingSubcategoryQuestionOption[];
  readonly comments: readonly BalancingActReportingSubcategoryComment[];
}

export interface BalancingActReportingSubcategoryQuestionOption {
  readonly displayOrder: number;
  readonly title: string;
  readonly deltaAmount: number;
  readonly netAmount: number;
  readonly submittedCount: number;
  readonly submittedPercentage: number;
  readonly clickedCount: number;
  readonly clickedPercentage: number;
}

export interface BalancingActReportingSubcategoryComment {
  readonly text: string;
  readonly timestamp: string;
}

export function gqlSimulationNodeTypeToClient(
  type: Gql.SimulationNodeType
): SimulationType {
  switch (type) {
    case Gql.SimulationNodeType.BUDGET:
      return SimulationType.BUDGET;
    case Gql.SimulationNodeType.HOUSING:
      return SimulationType.HOUSING;
    case Gql.SimulationNodeType.RECEIPT:
      return SimulationType.RECEIPT;
    case Gql.SimulationNodeType.PRIORITIZE:
      return SimulationType.PRIORITIZE;
  }
}
