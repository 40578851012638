import * as React from 'react';
import './styles.scss';
import 'rc-slider/assets/index.css';
import { ClientQuestionId, QuestionChoice } from 'client/shared/core/question';
import Slider, { Handle } from 'rc-slider';
import { sumBy, sortBy } from 'lodash';
import { useSpring, animated } from 'react-spring';
import { PA_VOTE_COPY } from './copy';
import { Flavor } from 'core';
import { sliderStyles } from 'client/shared/components/voting-slider';
import {
  KeyPressKey,
  LoadedEvents,
  PointAllocationChoice,
} from 'client/shared/core/types';
import classNames from 'classnames';

export interface PAVoteProps {
  readonly choices: readonly QuestionChoice[];
  readonly selectPointAllocation: LoadedEvents['selectPointAllocation'];
  readonly questionId: ClientQuestionId;
  readonly selectedChoices: readonly PointAllocationChoice[];
  readonly disabled?: boolean;
  readonly viewOnly?: boolean;
}
export interface AllocationInfoProps {
  readonly choiceLabel: string;
  readonly points: readonly number[];
  readonly point: number;
  readonly onChange: (point: number) => void;
  readonly hasError: boolean;
  readonly key: string;
  readonly disabled?: boolean;
}

export interface PointBarProps {
  readonly currentPoint: number;
}

const baseClass = 'pn-pa-vote';
const pointBarBaseClass = 'pn-point-bar';

export const PAVote: React.FC<PAVoteProps> = (props) => {
  const { choices, selectPointAllocation, questionId, disabled, selectedChoices } =
    props;

  const totalPoints = sumBy(selectedChoices, (sc) => sc.point);
  // make sure previousVote and choices order are same
  const sortedChoices = sortBy(selectedChoices, (ch) =>
    choices.findIndex((i) => i.id === ch.id)
  );
  const remainingText = `${10 - totalPoints} ${PA_VOTE_COPY.remaining}`;

  return (
    <div>
      {!disabled && (
        <div className="bg-white pb-3 w-100 space-top">
          <div className="text-center text-gray-50 mb-3">{PA_VOTE_COPY.info}</div>

          <div className="pb-3">
            <PointBar currentPoint={totalPoints} />
            {totalPoints > 10 ? (
              <div aria-live="polite" className={`${baseClass}-info`}>
                <span className={`${baseClass}-info-warning-text w-100 bg-white`}>
                  {PA_VOTE_COPY.overallocation(totalPoints - 10)}
                </span>
              </div>
            ) : totalPoints === 10 ? (
              <div aria-live="polite" className={`${baseClass}-info`}>
                <span
                  className={`${baseClass}-info-text font-weight-bold w-100 bg-white`}
                >
                  {PA_VOTE_COPY.exactPoint}
                </span>{' '}
              </div>
            ) : (
              <div aria-live="polite" className={`${baseClass}-info`}>
                <span className={`${baseClass}-info-text`}>{remainingText}</span>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="mx-2">
        {choices.map((ch, i) => {
          return (
            <PASlider
              ch={ch}
              choiceIndex={i}
              disabled={disabled}
              key={ch.id}
              questionId={questionId}
              selectPointAllocation={selectPointAllocation}
              selectedChoices={sortedChoices}
            />
          );
        })}
      </div>
    </div>
  );
};

export const PointBar: React.FC<PointBarProps> = (props) => {
  const barAnimation = useSpring({
    width: `${props.currentPoint >= 10 ? 0 : 100 - props.currentPoint * 10}%`,
  });
  return (
    <div
      className={`${pointBarBaseClass} ${
        props.currentPoint > 10 ? `${pointBarBaseClass}-warning` : ''
      } bg-gray-10 rounded-pill mb-1`}
      role="progressbar"
    >
      <animated.span
        className={`${pointBarBaseClass}-fill bg-jungle rounded-pill`}
        style={barAnimation}
      />
    </div>
  );
};

const handle = (props: {
  readonly value: number;
  readonly disabled: boolean;
  readonly dragging: boolean;
  readonly focused: boolean;
  readonly style: any;
}) => {
  const { value, disabled, dragging, focused, style } = props;

  const handleClassName = classNames(
    'd-flex',
    'justify-content-center',
    'align-items-center',
    'pn-handle-style',
    'rounded',
    {
      'pn-handle-style-disabled': disabled,
      'pn-handle-style-dragging': dragging,
      'pn-handle-style-focused': focused,
    }
  );

  return (
    <div>
      <Handle
        className="rc-slider-handle"
        max={10}
        min={0}
        offset={value * 10}
        style={style}
        value={value}
        vertical={false}
      >
        <div className={handleClassName}>{value}</div>
      </Handle>
    </div>
  );
};

const PASlider: React.FC<{
  readonly questionId: ClientQuestionId;
  readonly ch: QuestionChoice;
  readonly disabled?: boolean;
  readonly choiceIndex: number;
  readonly selectedChoices: readonly PointAllocationChoice[];

  readonly selectPointAllocation: (
    choiceIds: readonly PointAllocationChoice[],
    questionId: Flavor<string, 'Question'>
  ) => void;
}> = (props) => {
  const {
    questionId,
    ch,
    disabled,
    selectedChoices,
    choiceIndex,
    selectPointAllocation,
  } = props;
  const [hasSelected, setHasSelected] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = () => {
    if (!disabled) {
      setHasSelected(true);
      setIsFocused(true);
    }
  };
  const handleBlur = () => setIsFocused(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!disabled && isFocused) {
      const currentValue = selectedChoices[choiceIndex].point;

      if (event.key === KeyPressKey.ARROW_LEFT) {
        updateSliderValue(Math.max(currentValue - 1, 0));
      } else if (event.key === KeyPressKey.ARROW_RIGHT) {
        updateSliderValue(Math.min(currentValue + 1, 10));
      }
    }
  };

  const updateSliderValue = (newValue: number) => {
    const newPoints = [...selectedChoices];
    newPoints[choiceIndex] = { id: ch.id, point: newValue };
    selectPointAllocation(newPoints, questionId);
  };

  return (
    <div className="my-3 py-2" key={ch.id}>
      <div className="font-weight-bold text-gray-60 m-1 my-3">{ch.label}</div>
      <div
        className="p-1"
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        role="none"
      >
        <Slider
          {...sliderStyles(hasSelected, dragging || isFocused, !!disabled)}
          defaultValue={selectedChoices[choiceIndex].point}
          disabled={disabled}
          // onChange and onAfterChange are similar but if you can, use onAfterChange for performance. onChange will be trigger every time the value is changed.
          handle={() =>
            handle({
              disabled: !!disabled,
              dragging: dragging,
              focused: isFocused,
              value: selectedChoices[choiceIndex].point,
              style: {
                ...sliderStyles(hasSelected, dragging || isFocused, !!disabled)
                  .handleStyle,
              },
            })
          }
          marks={{
            0:
              selectedChoices[choiceIndex].point !== 0 ? (
                <div
                  className={classNames(
                    `${baseClass}-label d-inline-block font-size-sm`,
                    {
                      'text-jungle-d': !disabled,
                      'text-gray-20': disabled,
                    }
                  )}
                >
                  0
                </div>
              ) : (
                ''
              ),
            2: '',
            4: '',
            6: '',
            8: '',
            10:
              selectedChoices[choiceIndex].point !== 10 ? (
                <div
                  className={classNames(
                    `${baseClass}-label d-inline-block font-size-sm`,
                    {
                      'text-jungle-d': !disabled,
                      'text-gray-20': disabled,
                    }
                  )}
                >
                  10
                </div>
              ) : (
                ''
              ),
          }}
          max={10}
          onAfterChange={(v) => {
            setHasSelected(true);
            setDragging(false);
            updateSliderValue(v);
          }}
          onChange={(v) => {
            setDragging(true);
            updateSliderValue(v);
          }}
          step={1}
        />
      </div>
    </div>
  );
};
PASlider.displayName = 'PASlider';
