import MapView from '@arcgis/core/views/MapView';
import { RGBColor, rawToHex } from '../../core/colors';
import _ from 'lodash';
import Graphic from '@arcgis/core/Graphic';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import Color from '@arcgis/core/Color';
import Polygon from '@arcgis/core/geometry/Polygon';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import { MapBucket } from '.';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';

export const orderedRGBColors: readonly RGBColor[] = [
  [205, 238, 145],
  [191, 222, 135],
  [174, 204, 122],
  [157, 184, 108],
  [136, 162, 92],
  [124, 148, 83],
  [106, 128, 69],
  [101, 121, 64],
  [82, 101, 50],
  [63, 80, 35],
];

export const orderedHexColors = orderedRGBColors.map(rawToHex);

export const colorIndexToStringMap = orderedHexColors.map((_color, idx) => {
  return `${idx * 10}th-${(idx + 1) * 10}th Percentile`;
});

export const getContentForBucket = (bucket: DisplayBucket) => {
  const title = bucket.name;

  const value = bucket.performanceDatumValue;

  return `
    <div class="pn-track-map-info-window-content d-flex flex-column align-items-center">
      <div class="font-weight-bold">${value}</div>
      <div class="d-flex align-items-center pt-1">
        <div class="pn-track-map-info-window-content-color-key rounded-circle" style="background-color: ${rawToHex(bucket.color)}"></div>
        <div class="font-size-xs px-1">${colorIndexToStringMap[bucket.percentileIndex]}</div>
      </div>
      <div class="font-size-xs">${title}</div>
    </div>
  `;
};

export const drawPolygons = (mapView: MapView, mapBuckets: readonly MapBucket[]) => {
  const buckets = transformBucketsForDisplay(mapBuckets);

  const graphicsLayer = createGraphicsLayer(buckets, getContentForBucket);

  mapView.map.removeAll();
  mapView.map.add(graphicsLayer);
};

const createGraphicsLayer = (
  buckets: readonly DisplayBucket[],
  generateContent: (bucket: DisplayBucket) => string
) => {
  const graphics = _.flatten(
    buckets.map((b) => {
      const content = generateContent(b);
      return b.shape.coordinates.map((polygon) => {
        return new Graphic({
          symbol: new SimpleFillSymbol({
            color: new Color([...b.color, 0.75]),
            outline: {
              color: new Color([...b.color, 1]),
              width: 1,
            },
          }),
          geometry: new Polygon({ rings: polygon }),
          popupTemplate: new PopupTemplate({ content }),
        });
      });
    })
  );

  const layer = new GraphicsLayer({
    id: 'trackmap-graphic-layer',
    graphics,
  });

  return layer;
};

interface DisplayBucket extends MapBucket {
  readonly color: RGBColor;
}

const transformBucketsForDisplay = (
  buckets: readonly MapBucket[]
): readonly DisplayBucket[] => {
  const mapColors = orderedRGBColors;

  return buckets.map((b) => {
    const color = mapColors[b.percentileIndex];

    return { ...b, color };
  });
};
