import { useRef } from 'react';
import { isEqual, cloneDeep } from 'lodash';

export function useDeepMemo<T extends object>(memoFn: () => T, key: object) {
  const ref = useRef<{ readonly key: object; readonly value: T }>();
  if (!ref.current || !isEqual(key, ref.current.key)) {
    ref.current = { key: cloneDeep(key), value: memoFn() };
  }
  return ref.current.value;
}

export function useDeepMemoObject<T extends object>(value: T) {
  const ref = useRef<{ readonly value: T }>();
  if (!ref.current || !isEqual(value, ref.current.value)) {
    ref.current = { value: cloneDeep(value) };
  }
  return ref.current.value;
}
