import { BenchmarkSurveyType, SafeDictionary } from 'core';

export enum BenchmarkValue {
  MUCH_LOWER = 'MUCH_LOWER',
  LOWER = 'LOWER',
  SIMILAR = 'SIMILAR',
  HIGHER = 'HIGHER',
  MUCH_HIGHER = 'MUCH_HIGHER',
}

export enum DisparityLevel {
  SIMILAR = 'SIMILAR',
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}

export function disparityLevelToCopy(value: DisparityLevel): string {
  switch (value) {
    case DisparityLevel.LARGE:
      return 'Large';
    case DisparityLevel.SMALL:
      return 'Small';
    case DisparityLevel.SIMILAR:
      return 'No disparity';
  }
}

export function isBenchmarkValue(value: string): value is BenchmarkValue {
  return Object.keys(BenchmarkValue).includes(value);
}

export function benchmarkValueToCopy(value: BenchmarkValue): string {
  switch (value) {
    case BenchmarkValue.MUCH_LOWER:
      return 'Much less favorable';
    case BenchmarkValue.LOWER:
      return 'Less favorable';
    case BenchmarkValue.SIMILAR:
      return 'Similar';
    case BenchmarkValue.HIGHER:
      return 'More favorable';
    case BenchmarkValue.MUCH_HIGHER:
      return 'Much more favorable';
  }
}

export interface BenchmarkResult {
  readonly value: BenchmarkValue;
  readonly rank: number;
  readonly totalDataPoints: number;
  readonly dateRange: {
    readonly start: Date;
    readonly end: Date;
  };
  readonly differenceFromAverage: number;
}

export enum SupportedAverageChoiceValueChoiceSetName {
  HOURS = 'HOURS',
}

export function isSupportedAverageChoiceValueChoiceSetName(
  value: string
): value is SupportedAverageChoiceValueChoiceSetName {
  return Object.keys(SupportedAverageChoiceValueChoiceSetName).includes(
    value.toLocaleUpperCase()
  );
}

export interface AverageChoiceValueData {
  readonly averageChoiceValue: number;
  readonly scaleName: string;
}

export const MIN_BENCHMARK_DATA_POINTS = 5;

export enum TrendValue {
  LOWER = 'LOWER',
  SIMILAR = 'SIMILAR',
  HIGHER = 'HIGHER',
}

export const TREND_SIG_DIFF_PERCENT_POINTS = 7;

// TODO: Remove and implement using statistical significant, this is a temporary fix
export const trendSigDiffForQuestionSetId = (questionSetId: string): number => {
  const trendSigDiffPerQuestionSetId: SafeDictionary<number> = {
    '1999432f-0199-4ac3-b335-d4be3d59a6c1': 2,
    'd20160f1-b7e6-46a3-88af-124094522471': 4,
    '1b7bd53b-1017-439f-b9c8-5f1ef3371d07': 6,
    '16e6bf25-01ff-4e8d-9a1c-b26c994e4fc5': 3,
    'e25ea21e-6c06-4a9e-ba7e-b1b79da225cf': 5,
    'f41f309b-de93-47d2-8cbf-1878ca63b77a': 6,
    '74c0a8bf-632e-4164-aaf8-6d34cfcc57ed': 8,
    '55ede7d0-7696-4dbc-afc5-3f8110043cad': 6,
    '77c2724a-d147-42ef-83d5-f77d417f37f9': 6,
    'baef9cf4-6032-47b9-a2b8-315a198a7e9a': 9,
    '1fd58c5d-4a6c-47d7-afb8-37c98a813027': 9,
    '8fc0f81d-7b5a-49c3-824b-3473881e735d': 9,
    'e25d65fa-bfca-4b1d-8e70-7c92eef8e09c': 10,
    'ed47cf7f-432f-433d-8008-e491b5f47b4f': 9,
    '66959410-3c1d-4799-ab8a-dd0b66e3a1b5': 10,
    '7775c7eb-1e39-47c9-8fd5-a1479fcaaa5e': 11,
    '9d355c1f-9fb4-4091-b28b-dd2dee1049ca': 9,
    'aa59ea50-9cc3-44da-a07b-0fb4dcb48d72': 13,
    '6f9cc1dc-f146-42b3-bb32-6220f13bf347': 12,
    '45da6c3f-f2f2-45a8-82a0-886d2348e9c2': 11,
    'd58e22b2-a104-4b11-b31d-c9b64630127a': 10,
    '901235ca-d441-49c1-844b-5380b94a9735': 14,
    '94869a00-b783-4e36-a81a-441eeaf70246': 20,
    'ea20ad09-11b7-46b2-a1ed-0df8ef931977': 10,
    'ecbe0acd-493a-4b15-b22c-d0ed23cb239c': 18,
    '50ee542f-09b0-4caa-9914-56e08e4585cc': 20,
    'b949e188-984b-49e1-b87a-645e8468d2cc': 14,
    '8bed772b-a712-40e6-ba8e-d2e486548479': 10,
    '1abc83d1-b524-4e8d-85cd-5388b9164dc8': 13,
    'c5b0e29c-00a8-41e2-9e39-c17601b529ef': 11,
    '61652c94-378a-4160-a9e4-518998d5e2bd': 13,
    '686d2f2a-0e51-44cf-9e4b-36b6a70207e1': 12,
    'b28a6421-bb61-4b3e-95ab-f6a5efb24705': 11,
  };
  return (
    trendSigDiffPerQuestionSetId[questionSetId] ?? TREND_SIG_DIFF_PERCENT_POINTS
  );
};

export function isTrendValue(value: string): value is TrendValue {
  return Object.keys(TrendValue).includes(value);
}

export function trendValueToCopy(value: TrendValue): string {
  switch (value) {
    case TrendValue.LOWER:
      return 'Unfavorably';
    case TrendValue.SIMILAR:
      return 'Similar';
    case TrendValue.HIGHER:
      return 'Favorably';
  }
}

export interface TrendResult {
  readonly value: TrendValue;
  readonly historicalDataPoints: readonly {
    readonly date: Date;
    readonly scalePercent: number;
    readonly averageChoiceValueData: AverageChoiceValueData | null;
  }[];
  readonly differenceFromPreviousDataPoint: number;
}

export function createAreaName(area: string, type: BenchmarkSurveyType | null) {
  if (!type) {
    return area;
  }
  switch (type) {
    case BenchmarkSurveyType.ARPA_BUSINESS:
    case BenchmarkSurveyType.NBS:
      return `${area}b`;
    case BenchmarkSurveyType.ARPA_RESIDENT:
    case BenchmarkSurveyType.NCS:
    case BenchmarkSurveyType.NCI:
      return area;
    case BenchmarkSurveyType.CASOA:
    case BenchmarkSurveyType.NCS_OA:
      return `${area}oa`;
    case BenchmarkSurveyType.NES:
    case BenchmarkSurveyType.NES_LE:
    case BenchmarkSurveyType.NLES:
    case BenchmarkSurveyType.NPSS:
      throw new Error('Not supported yet');
  }
}
