import { getConfig } from 'client/shared/core/config';
import { useLocalStorage } from 'client/shared/helpers/hooks';
import { useHistory } from 'react-router';
import qs from 'query-string';
import { TRACKING_EMAIL_PARAMETER } from 'client/admin/core';

const TRACKING_EMAIL_LOCAL_STORAGE_KEY = 'trackingEmail';

export function useTrackingData() {
  const localStorage = useLocalStorage();
  const history = useHistory();

  const parameterTrackingEmail: string | null = getConfig().isDemoEnv
    ? qs.parse(history.location.search)[TRACKING_EMAIL_PARAMETER]
    : null;
  const localStorageTrackingEmail =
    localStorage?.getItem(TRACKING_EMAIL_LOCAL_STORAGE_KEY) ?? null;

  if (
    localStorage &&
    parameterTrackingEmail &&
    localStorageTrackingEmail !== parameterTrackingEmail
  ) {
    localStorage.setItem(TRACKING_EMAIL_LOCAL_STORAGE_KEY, parameterTrackingEmail);
  }

  // Always prefer query parameter value if present
  const trackingEmail = parameterTrackingEmail ?? localStorageTrackingEmail;

  return {
    trackingEmail,
  };
}
