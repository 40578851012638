import { BenchmarkFilter } from '@polco-us/types';
import { analyticsBenchmarkToClient } from 'client/shared/core/performance-data';
import { ExpandMode } from 'client/shared/components/base';
import { BenchmarkIndicator } from 'client/shared/components/benchmark-indicator';
import { TransformedData, baseClass } from '../comparison-group-result-table-column';
import React from 'react';

interface DataPointBenchmarkProps {
  readonly data: TransformedData;
}

export const DataPointBenchmark: React.FC<DataPointBenchmarkProps> = (props) => {
  const recentDatum = props.data.recentDatum;

  const defaultBenchmarkValue = recentDatum?.benchmarkValues?.find(({ filter }) => {
    return filter === BenchmarkFilter.DEFAULT;
  })?.value;

  if (defaultBenchmarkValue && props.data.variableDirection) {
    return (
      <BenchmarkIndicator
        benchmarkValue={analyticsBenchmarkToClient(defaultBenchmarkValue)}
        className={`${baseClass}-benchmark-indicator mb-1`}
        expandMode={ExpandMode.ALWAYS_COLLAPSED}
      />
    );
  }
  return <></>;
};

DataPointBenchmark.displayName = 'DataPointBenchmark';
