import * as React from 'react';
import { Modal } from 'client/shared/components/modal';
import './styles.scss';

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly domain: string;
}

const copies = {
  title: (domainName: String) => `${domainName} Domain`,
  body: (domainName: String) =>
    `In addition to the data you see on the page, your index score for this domain includes all other relevant ${domainName} questions from The NCS.`,
};

const baseClass = 'pn-ncs-factors';

export const NCSFactorsModal: React.FC<Props> = (p) => {
  return (
    <Modal
      centered
      className={`${baseClass}-modal`}
      header={{
        title: copies.title(p.domain),
        bsClassName: 'p-3 border-bottom',
        className: 'font-size-lg',
      }}
      isCloseable
      isOpen={p.isOpen}
      onRequestClose={p.onClose}
    >
      <p>{copies.body(p.domain)}</p>
    </Modal>
  );
};
