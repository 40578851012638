import * as React from 'react';
import 'rc-slider/assets/index.css';
import {
  ClientQuestionId,
  ClientQuestionChoiceId,
  QuestionChoice,
} from 'client/shared/core/question';
import { VotingSlider } from '../voting-slider';
import './styles.scss';
import { SurveyLoadedEvents } from 'client/shared/core/types';
export interface GridVoteSliderProps {
  readonly questionId: ClientQuestionId;
  readonly cols: readonly QuestionChoice[];
  readonly currentRowId: ClientQuestionId;
  readonly selectGridChoice: SurveyLoadedEvents['surveySelectGridChoice'];
  readonly selectedGridChoice: ClientQuestionChoiceId | null;
  readonly disabled?: boolean;
  readonly hasInputError: boolean;
}

export const GridVoteSlider: React.FC<GridVoteSliderProps> = (props) => {
  return (
    <div>
      <VotingSlider
        choices={props.cols}
        currentRowId={props.currentRowId}
        disabled={props.disabled}
        hasInputError={props.hasInputError}
        questionId={props.questionId}
        selectChoice={(i) =>
          props.selectGridChoice({
            gridChoice: {
              rowId: props.currentRowId,
              colId: props.cols[i - 1].id,
            },
            questionId: props.questionId,
            numChoices: props.cols.length,
          })
        }
        selectedChoiceId={props.selectedGridChoice}
      />
    </div>
  );
};
