import * as React from 'react';
import './styles.scss';
import { Dropdown as BsDropdown } from 'react-bootstrap';
import { MaterialIcon, MaterialIconName } from '../material-icon';
import { Checkbox } from '../checkbox';

export interface Props<T> {
  readonly options: readonly T[];
  readonly clearLabel?: string;
  readonly className?: string;
  readonly prompt: string;
  readonly promptIcon?: MaterialIconName;
  readonly values: readonly T[];
  readonly showPromptBadge?: boolean;
  readonly toggleClassName?: string;
  readonly clearSection?: () => void;
  readonly keySelect: (value: T) => string;
  readonly labelSelect: (value: T) => string;
  readonly labelSelectSelected?: string;
  readonly onChange: (value: T, index: number) => void | Promise<any>;
}

const baseClass = 'pn-multiselect-dropdown';

export function MultiselectDropdown<T>(p: Props<T>): React.ReactElement {
  const [show, setShow] = React.useState(false);
  return (
    <BsDropdown
      className={`${baseClass} ${p.className ?? ''}`}
      onToggle={(
        _isOpen: boolean,
        _event: React.SyntheticEvent<BsDropdown>,
        metadata: {
          readonly source: 'select' | 'click' | 'rootClose' | 'keydown';
        }
      ) => {
        if (metadata.source !== 'select') {
          // This allows selecting multiple things before the dropdown closes
          setShow(!show);
        }
      }}
      show={show}
    >
      <BsDropdown.Toggle
        className={`${baseClass}-toggle d-block w-100 text-left ${
          p.toggleClassName ?? ''
        }`}
        variant="white"
      >
        <div className={`d-flex align-items-center pr-5 ${baseClass}-toggle-label`}>
          {!p.showPromptBadge && p.values.length > 0 ? (
            p.values.map((val) => p.labelSelect(val)).join(', ')
          ) : (
            <>
              {p.promptIcon && (
                <MaterialIcon
                  className={`${baseClass}-label-icon mr-2`}
                  icon={p.promptIcon}
                />
              )}
              {p.labelSelectSelected && !!p.values.length
                ? p.labelSelectSelected
                : p.prompt}
              {p.showPromptBadge && !!p.values.length && (
                <span
                  className={`${baseClass}-badge ml-2 px-2 font-size-sm font-weight-normal text-gray-40 bg-grayscale-3`}
                >
                  {p.values.length}
                </span>
              )}
            </>
          )}
        </div>
        <MaterialIcon
          className={`${baseClass}-toggle-icon`}
          icon={
            show
              ? MaterialIconName.KEYBOARD_ARROW_UP
              : MaterialIconName.KEYBOARD_ARROW_DOWN
          }
        />
      </BsDropdown.Toggle>
      <BsDropdown.Menu className={`${baseClass}-menu`}>
        {!!p.clearLabel && !!p.clearSection && (
          <BsDropdown.Header
            className={`${baseClass}-header pl-3 py-2 text-black font-size-sm font-weight-bold`}
          >
            <button
              className={`${baseClass}-clear-section-btn btn btn-link font-weight-bold font-size-xs text-jungle py-0 px-0`}
              onClick={p.clearSection}
            >
              {p.clearLabel}
            </button>
          </BsDropdown.Header>
        )}
        {p.options.map((option, index) => (
          <BsDropdown.Item
            className={`${baseClass}-item py-2 px-3`}
            key={p.keySelect(option)}
            onSelect={() => p.onChange(option, index)}
          >
            <Checkbox
              checked={
                !!p.values.find((val) => p.keySelect(val) === p.keySelect(option))
              }
              className={`${baseClass}-checkbox`}
              htmlId={p.keySelect(option)}
              key={p.keySelect(option)}
              label={p.labelSelect(option)}
              onChecked={() => {}}
            />
          </BsDropdown.Item>
        ))}
      </BsDropdown.Menu>
    </BsDropdown>
  );
}
