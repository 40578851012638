import * as React from 'react';
import { Tooltip } from 'client/shared/components/tooltip';
import { MaterialIcon, MaterialIconName } from 'client/shared/components/base';
import './styles.scss';

const baseClass = 'pn-dashboard-data-unavailable';

interface Props {
  readonly className?: string;
  readonly titleClass?: string;
  readonly title: string;
  readonly hideTooltip?: boolean;
}

export const dataUnavailableCopy = {
  tooltipTitle: 'Data unavailable',
  tooltipDescription: 'Our data science team will add it as it becomes available.',
};

export const DataUnavailable: React.FC<Props> = (p) => {
  return (
    <div
      className={`${baseClass}-unavailable-label d-flex align-items-center ${
        p.className ?? ''
      }`}
    >
      <span className={`${p.titleClass ?? 'font-size-base'} text-gray-40`}>
        {p.title}
      </span>
      {!p.hideTooltip ? (
        <div className={`${baseClass}-tooltip-container`}>
          <Tooltip
            content={[
              dataUnavailableCopy.tooltipTitle,
              dataUnavailableCopy.tooltipDescription,
            ].join('. ')}
            htmlContent={
              <div className="mb-2">
                <div className="font-weight-bold">
                  {dataUnavailableCopy.tooltipTitle}
                </div>
                <div className={`${baseClass}-tooltip-description`}>
                  {dataUnavailableCopy.tooltipDescription}
                </div>
              </div>
            }
            id="data-unavailable-tooltip"
            place="top"
          >
            <MaterialIcon
              className={`${baseClass}-icon text-gray-40 ml-2 cursor-pointer`}
              icon={MaterialIconName.HELP_OUTLINE}
            />
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};
