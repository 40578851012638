import React from 'react';
import moment from 'moment';
import { toPng } from 'html-to-image';
import html2canvas from 'html2canvas';
import _ from 'lodash';

const IMAGE_CLASSES_TO_EXCLUDE = ['no-show-in-image'];

const filter = (node: HTMLElement) => {
  const classesToExclude = IMAGE_CLASSES_TO_EXCLUDE;
  return !classesToExclude.some((className) => node.classList?.contains(className));
};

export const downloadSnapshot = (imageUrl: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = imageUrl;
  link.setAttribute('download', fileName); //or any other extension
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function useGenerateImage(
  ref: React.RefObject<HTMLElement>,
  opts?: {
    readonly imageName?: string;
    readonly afterGenerationAction?: () => any;
    readonly parentClassType?: '-wide';
    readonly beforeGenerationAction?: () => any;
    readonly mapData?: {
      readonly mapView?: __esri.MapView;
      readonly mapContainerClass: string;
      readonly mapLegendClass: string;
      readonly classesToRemove?: readonly string[];
    };
  }
) {
  // This function will capture the non-map visualization components
  const captureMainComponents = React.useCallback(async () => {
    if (!ref.current) {
      console.error('componentRef is null or undefined');
      return null; // Or handle the error as needed
    }

    try {
      const url = await buildPng(ref.current);
      return url;
    } catch (error) {
      console.error('Error capturing other components:', error);
    }
  }, [ref]);

  // this will use Esri to capture the map component itself
  const captureMapScreenshot = React.useCallback(async (view: __esri.MapView) => {
    try {
      const screenshot = await view.takeScreenshot({
        width: view.width,
        height: view.height,
        format: 'png',
        quality: 1,
      });

      return screenshot.dataUrl;
    } catch (error) {
      console.error('Error taking screenshot of the map:', error);
    }
  }, []);

  // this will create the full canvas to merge the pngs, and return the png url for it
  const createFullCanvasUrl = React.useCallback(
    async (initialScrollYVal: number = 0) => {
      if (!ref.current) return;

      const mapData = opts?.mapData ?? null;

      const containersToRemove = _.compact(
        opts?.mapData?.classesToRemove?.map((ctr) => ref.current?.querySelector(ctr))
      );
      // get track map
      const mapContainer = mapData
        ? (ref.current.querySelector(mapData.mapContainerClass) as HTMLElement)
        : null;
      // Get dimensions and positions
      const componentRect = ref.current.getBoundingClientRect();
      // get legend container if its there
      const legendContainer =
        mapData && mapContainer
          ? (mapContainer.querySelector(mapData.mapLegendClass) as HTMLElement)
          : null;

      // set legendURL to determine if legend should be added
      let legendUrl = '';
      if (legendContainer) {
        const legCanvas = await html2canvas(legendContainer, {
          backgroundColor: null, // Keep background transparent if necessary
          logging: true, // Enable logging for troubleshooting
          useCORS: true, // Allow cross-origin resources
        });

        legendUrl = legCanvas.toDataURL('image/png');
      }

      // create main outer canvas to contain all of the merged elements
      const canvas = document.createElement('canvas');
      canvas.width = componentRect.width;
      canvas.height = componentRect.height;
      const context = canvas.getContext('2d');

      // actually grab the string url for the map and the non-map components
      const mapScreenshot =
        mapData?.mapView && mapContainer
          ? await captureMapScreenshot(mapData.mapView)
          : null;
      const componentScreenshot = await captureMainComponents();
      if (!componentScreenshot) return;

      // helper function to resolve the string into the image element
      const loadImage = (src: string): Promise<HTMLImageElement> => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve(img);
          img.onerror = reject;
        });
      };
      if (context) {
        // draw main non-map components
        const componentImg = await loadImage(componentScreenshot);
        context.drawImage(
          componentImg,
          0,
          0,
          componentRect.width,
          componentRect.height
        );

        if (mapScreenshot && mapContainer) {
          // grab and draw map screenshot onto canvas
          // draws on top of non-map elements so it can cover the faulty rendering of the webGL from the buildPNG function return of the non-map elements
          const mapRect = mapContainer.getBoundingClientRect();

          const mapImg = await loadImage(mapScreenshot);

          const totalHeightToRemove = containersToRemove.reduce((prev, curr) => {
            const rectHeight = curr.getBoundingClientRect().height;
            return prev + rectHeight;
          }, 0);
          const offsetY =
            mapRect.top -
            (totalHeightToRemove ?? 0) -
            componentRect.top +
            (window.scrollY - initialScrollYVal);

          context.drawImage(
            mapImg,
            mapRect.left - componentRect.left,
            offsetY,
            mapRect.width,
            mapRect.height
          );

          // finally draw legend on top if it exists and place in bottom right corner then return full canvas url
          if (legendContainer) {
            const legendRect = legendContainer.getBoundingClientRect();

            const legendImg = await loadImage(legendUrl);
            const lOffsetY =
              legendRect.top -
              (totalHeightToRemove ?? 0) -
              componentRect.top +
              (window.scrollY - initialScrollYVal);

            context.drawImage(
              legendImg,
              legendRect.left - componentRect.left,
              lOffsetY,
              legendRect.width,
              legendRect.height
            );
          }
        }
        return canvas.toDataURL('image/png');
      }
    },
    [opts?.mapData, captureMapScreenshot, captureMainComponents, ref]
  );

  return React.useCallback(async () => {
    if (ref.current === null) {
      return;
    }

    const parentClass = `image-generation-parent${opts?.parentClassType ?? ''}`;
    ref.current.classList.add(parentClass);
    const timeStamp = moment().format('MMMM Do YYYY');
    try {
      const initialScrollY = window.scrollY;

      if (opts?.beforeGenerationAction) opts.beforeGenerationAction();
      const fullCanvas = await createFullCanvasUrl(initialScrollY);
      ref.current.classList.remove(parentClass);
      // do we want to do anything here if there's an issue with the canvas?
      if (fullCanvas) {
        downloadSnapshot(
          fullCanvas,
          `Polco ${opts?.imageName + ' - '}${timeStamp}.png`
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (opts?.afterGenerationAction) opts.afterGenerationAction();
      ref.current.classList.remove(parentClass); // An extra remove just in case
    }
  }, [ref, opts, createFullCanvasUrl]);
}

export const buildPng = async (htmlElement: HTMLElement) => {
  // There is a bug in Safari that the toPng promise resolves before the entire image has been generated
  // This causes downloaded images to be missing random elements
  // This looping attempts to give extra time to Safari to generate all of the image
  // It terminates when it sees the image size grow, indicating full completion, or after a set number of loops
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  let dataUrl = '';
  let i = 0;
  let maxAttempts;
  if (isSafari) {
    maxAttempts = 5;
  } else {
    maxAttempts = 1;
  }
  const dataUrlLengthPerLoop = [];

  while (i < maxAttempts) {
    dataUrl = await toPng(htmlElement, {
      cacheBust: true,
      quality: 1,
      style: {
        background: 'white',
        marginTop: '0',
        height: 'fit-content',
        width: 'fit-content',
        minWidth: '100%',
      },
      filter,
    });
    i += 1;
    dataUrlLengthPerLoop[i] = dataUrl.length;

    if (dataUrl.length > dataUrlLengthPerLoop[i - 1]) {
      break;
    }
  }

  return dataUrl;
};
