import { PermissionType } from '@polco-us/types';
import {
  QueryAdminResult,
  useQueryAdminInfo,
} from '../shared/containers/query-admin';
import { isStandardAdminData } from '../shared/types';
import { gqlToPermissionType } from '../graphql-util/transforms/permissions';
import { useMemo } from 'react';

export function useAdminPermissions() {
  const queryAdminResult = useQueryAdminInfo();
  const adminPermissions = useMemo(() => {
    return parsePermissions(queryAdminResult);
  }, [queryAdminResult]);

  const doesCurrentAdminHavePermissions = (
    permissions: ReadonlyArray<PermissionType>,
    comparisonStrategy: 'some' | 'every' = 'some'
  ) => permissions[comparisonStrategy]((perm) => adminPermissions.includes(perm));

  return {
    doesCurrentAdminHavePermissions,
    permissions: adminPermissions,
  };
}

function parsePermissions(
  queryAdminResult: QueryAdminResult
): readonly PermissionType[] {
  if (isStandardAdminData(queryAdminResult.data)) {
    if (queryAdminResult.data.admin.superAdmin) {
      // Return all permissions
      return Object.values(PermissionType);
    }
    const expectedPermissions = gqlToPermissionType(
      queryAdminResult.data.admin.permissions
    );
    return expectedPermissions;
  }
  return [];
}
