import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import { ExtractGql, MenuItem, ElementType } from 'core';

type PrivateAdmin = ExtractGql<
  NonNullable<NonNullable<Gql.CurrentAdmin['currentUser']['user']>['admin']>,
  'PrivateAdmin'
>;

type GqlMenuItem = ElementType<PrivateAdmin['menuItems']>;

export namespace MenuItemsTx {
  export function gqlMenuItemToClient(menuItem: GqlMenuItem): MenuItem {
    return {
      id: menuItem.id,
      text: menuItem.text,
      url: menuItem.url || undefined,
      icon: menuItem.icon || undefined,
      materialIcon: menuItem.materialIcon || undefined,
      isNew: menuItem.isNew || false,
      isShown: menuItem.isShown || false,
      childrenMenuItems: menuItem.childrenMenuItems.map((ch) => ({
        id: ch.id,
        text: ch.text,
        url: ch.url || undefined,
        icon: ch.icon || undefined,
        materialIcon: ch.materialIcon || undefined,
        isNew: ch.isNew || false,
        isShown: ch.isShown || false,
        childrenMenuItems: [],
      })),
    };
  }
}
