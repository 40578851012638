import { AnalyticsDomain } from 'client/shared/graphql-client/graphql-operations.g';
import {
  Flavor,
  Case,
  ReportTabContent,
  QuestionSetType,
  BalancingActSimulationType,
} from 'core';
import { QuestionStatus, SavedSurveyItem, SurveyItemType } from '../question';
export { QuestionSetType } from 'core';

export type ClientQuestionSetId = Flavor<string, 'QuestionSet'>;
export type ClientReportTabId = Flavor<string, 'ReportTabSetting'>;

export enum QuestionSetStatus {
  NOT_STARTED = 'NOT_STARTED',
  SCHEDULED = 'SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
  ARCHIVED = 'ARCHIVED',
  SOFT_DELETED = 'SOFT_DELETED',
  HISTORIC_RECORD = 'HISTORIC_RECORD',
}

export enum SurveyAction {
  STOP_MOVE_TO_DRAFT = 'STOP_MOVE_TO_DRAFT',
  EDIT_KEEP_LIVE = 'EDIT_KEEP_LIVE',
  STOP_ALL_EARLY = 'STOP_ALL_EARLY',
}
export interface SetImage {
  readonly id: string;
  readonly horizontalUrl: string;
}

export function questionStatusFromSetStatus(
  setStatus: QuestionSetStatus
): QuestionStatus {
  switch (setStatus) {
    case QuestionSetStatus.CLOSED:
      return QuestionStatus.CLOSED;
    case QuestionSetStatus.IN_PROGRESS:
      return QuestionStatus.PUBLISHED;
    case QuestionSetStatus.NOT_STARTED:
      return QuestionStatus.DRAFT;
    case QuestionSetStatus.SCHEDULED:
      return QuestionStatus.SCHEDULED;
    case QuestionSetStatus.ARCHIVED:
      return QuestionStatus.ARCHIVED;
    case QuestionSetStatus.SOFT_DELETED:
      return QuestionStatus.SOFT_DELETED;
    case QuestionSetStatus.HISTORIC_RECORD:
      return QuestionStatus.HISTORIC_RECORD;
  }
}

export interface ReportTab {
  readonly id: ClientReportTabId;
  readonly name: string;
  readonly content: ReportTabContent;
  readonly hidden: boolean;
  readonly publiclyShared: boolean;
  readonly trackDomain: AnalyticsDomain | null;
}

export enum OutcomeState {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
}
export interface Outcome {
  readonly id: string;
  readonly text: string;
  readonly state: OutcomeState;
}

export enum QuestionSetModalStates {
  // DELETE = 'DELETE',
  // EDIT = 'EDIT',
  // SAVE = 'SAVE',
  EXIT = 'EXIT',
  REDIRECT_EXIT = 'REDIRECT_EXIT',
}

export type QuestionSetModalState =
  // | Case<QuestionSetModalStates.DELETE>
  // | Case<QuestionSetModalStates.EDIT>
  // | Case<QuestionSetModalStates.SAVE, { questionSet: QuestionSet }>
  | Case<QuestionSetModalStates.EXIT>
  | Case<QuestionSetModalStates.REDIRECT_EXIT, { readonly path: string }>;

export function statusToDisplayText(
  status: QuestionSetStatus,
  setType: QuestionSetType | BalancingActSimulationType
) {
  if (setType === QuestionSetType.CONTENT_POST) {
    return contentPostStatusToDisplayText(status);
  }
  switch (status) {
    case QuestionSetStatus.NOT_STARTED:
      return 'Not Started';
    case QuestionSetStatus.SCHEDULED:
      return 'Scheduled';
    case QuestionSetStatus.IN_PROGRESS:
      return 'In Progress';
    case QuestionSetStatus.CLOSED:
      return 'Closed';
    case QuestionSetStatus.ARCHIVED:
      return 'Archived';
    case QuestionSetStatus.HISTORIC_RECORD:
      return 'Historic Record';
    case QuestionSetStatus.SOFT_DELETED:
      return 'Deleted';
  }
}

function contentPostStatusToDisplayText(status: QuestionSetStatus) {
  switch (status) {
    case QuestionSetStatus.NOT_STARTED:
      return 'Draft';
    case QuestionSetStatus.SCHEDULED:
      return 'Scheduled';
    case QuestionSetStatus.IN_PROGRESS:
      return 'Published';
    case QuestionSetStatus.CLOSED:
      return 'Not Published';
    case QuestionSetStatus.ARCHIVED:
      return 'Archived';
    case QuestionSetStatus.HISTORIC_RECORD:
      return 'Historic Record';
    case QuestionSetStatus.SOFT_DELETED:
      return 'Deleted';
  }
}

export function getSurveyQuestionCount(
  surveyItems: readonly Pick<SavedSurveyItem, 'type'>[]
): number {
  return surveyItems.filter((i) => i.type === SurveyItemType.QUESTION).length;
}

export enum ShareableContentType {
  RECOMMENDED = 'RECOMMENDED',
  REPOST = 'REPOST',
  STANDARD_DEMO_QUESTIONS = 'STANDARD_DEMO_QUESTIONS',
  BENCHMARK = 'BENCHMARK',
}

export function gqlToSetType(
  typename: 'QuestionSet' | 'Survey' | 'PolcoLive' | 'ContentPost'
) {
  switch (typename) {
    case 'QuestionSet':
      return QuestionSetType.SET;
    case 'Survey':
      return QuestionSetType.SURVEY;
    case 'PolcoLive':
      return QuestionSetType.POLCO_LIVE;
    case 'ContentPost':
      return QuestionSetType.CONTENT_POST;
  }
}
