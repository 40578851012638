import React from 'react';

interface ComparisonGroupResultTableCellProps {
  readonly children: React.ReactNode;
  readonly width: number;
  readonly minWidth?: number;
}

export const ComparisonGroupResultTableCell: React.FC<
  ComparisonGroupResultTableCellProps
> = (p) => {
  const minWidth = p.minWidth ?? p.width;
  const flex = `${p.width} 0 auto`;
  return (
    <div
      className="d-flex align-items-center border-gray-5 p-2 justify-content-center border-bottom-1"
      style={{ minWidth, width: p.width, flex }}
    >
      {p.children}
    </div>
  );
};

ComparisonGroupResultTableCell.displayName = 'ComparisonGroupResultTableCell';
