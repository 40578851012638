import {
  SuperAdminPublishingEntityConfigurationDashboard,
  CurrentAdmin,
  AdminPublishingEntityFeatureSettings,
} from 'client/shared/graphql-client/graphql-operations.g';
import { ApiDate, ExtractGql } from 'core';
import { FeatureSetting } from 'client/admin/core/feature-settings';

type GqlFeatureSettings = NonNullable<
  | NonNullable<
      SuperAdminPublishingEntityConfigurationDashboard['openPublishingEntityById']
    >['featureSettings']
  | NonNullable<
      ExtractGql<
        NonNullable<NonNullable<CurrentAdmin['currentUser']['user']>['admin']>,
        'PrivateAdmin'
      >['activePublishingEntity']
    >['featureSettings']
  | NonNullable<
      AdminPublishingEntityFeatureSettings['openPublishingEntityById']
    >['featureSettings']
>;

export function featureSettingGqlToClient(
  featureSettings: GqlFeatureSettings
): readonly FeatureSetting[] {
  return featureSettings.map((featureSetting) => ({
    featureSettingId: featureSetting.featureSettingId,
    featureSettingName: featureSetting.featureSettingName,
    enabledForPublisher: featureSetting.enabled,
    expiresAt: ApiDate.fromApi(featureSetting.expiresAt),
    quantity: featureSetting.quantity,
    isUnlimited: featureSetting.isUnlimited,
  }));
}
