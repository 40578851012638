import { useQueryParameter } from 'client/shared/hooks';
import { isEnum, Maybe } from 'core';
import { useHistory } from 'react-router';

export enum QueryParams {
  BENCHMARK = 'benchmarks',
  DISPARITY = 'disparity',
  NON_SCALED = 'non_scaled',
  PDF_GENERATION = 'pdf_generation',
  QUESTION_ID = 'question_id',
  RESULT_MODE = 'result_mode',
  SECTION_INDEX = 'section_index',
  TEXT = 'text',
  TOPICS = 'topics',
  TREND = 'trends',
  VERIFIED = 'verified',
  BETA = 'beta',
  TAB = 'tab',
  TRY_NOW = 'try_now',
  DEMO_POLLY_FIPS = 'demo_polly_fips',
}

export enum ResultsMode {
  WEIGHTED = 'weighted',
  RAW = 'raw',
  RQC = 'rqc',
}

export function useSearchParams() {
  const { changeQueryParam, updateArrayQueryParamFn } = useQueryParameter();
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  return {
    queryParams,
    benchmark: () => queryParams.get(QueryParams.BENCHMARK)?.split(','),
    disparity: () => !!queryParams.get(QueryParams.DISPARITY),
    nonScaled: () => !!queryParams.get(QueryParams.NON_SCALED),
    pdfGeneration: () => !!queryParams.get(QueryParams.PDF_GENERATION),
    questionId: () => queryParams.get(QueryParams.QUESTION_ID),
    resultsMode: (isSuperAdmin: boolean, inEngageModuleBenchmark?: boolean) => {
      if (!!inEngageModuleBenchmark) return ResultsMode.RAW;
      if (!isSuperAdmin) return ResultsMode.WEIGHTED;

      const qsResultsMode = queryParams.get(QueryParams.RESULT_MODE);
      if (isEnum(ResultsMode, qsResultsMode)) return qsResultsMode;

      return ResultsMode.WEIGHTED;
    },
    sectionIndex: () => queryParams.get(QueryParams.SECTION_INDEX),
    queryText: () => queryParams.get(QueryParams.TEXT) ?? '',
    topics: () => queryParams.get(QueryParams.TOPICS)?.split(','),
    trend: () => queryParams.get(QueryParams.TREND)?.split(','),
    verified: () => queryParams.get(QueryParams.VERIFIED),
    beta: () => queryParams.get(QueryParams.BETA),
    tryNow: () => !!queryParams.get(QueryParams.TRY_NOW),
    demoPollyFips: () => queryParams.get(QueryParams.DEMO_POLLY_FIPS),

    updateParams: (params: QueryParams[]) => {
      const updatedParams = queryParams;
      params.forEach((param) => updatedParams.delete(param));
      history.replace({
        pathname: history.location.pathname,
        search: `?${updatedParams.toString()}`,
      });
    },

    setQuestionId: (questionId: Maybe<string>) =>
      changeQueryParam(QueryParams.QUESTION_ID, questionId),
    setQueryText: (query: string) => changeQueryParam(QueryParams.TEXT, query),
    setNonScaled: (updatedShowNonScaled: boolean) =>
      changeQueryParam(QueryParams.NON_SCALED, updatedShowNonScaled ? 'true' : null),
    setVerified: (updatedVerifiedFilter: Maybe<string>) =>
      changeQueryParam(QueryParams.VERIFIED, updatedVerifiedFilter),
    setResultsMode: (resultsMode: Maybe<ResultsMode>) =>
      changeQueryParam(
        QueryParams.RESULT_MODE,
        // If filter is default, remove query parameter
        resultsMode === ResultsMode.WEIGHTED ? null : resultsMode
      ),
    setTopics: (values: readonly any[]) =>
      updateArrayQueryParamFn(QueryParams.TOPICS, values),
    setBenchmarks: (values: readonly any[]) =>
      updateArrayQueryParamFn(QueryParams.BENCHMARK, values),
    setTrends: (values: readonly any[]) =>
      updateArrayQueryParamFn(QueryParams.TREND, values),
  };
}
