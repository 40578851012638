import * as React from 'react';
import './styles.scss';
import 'rc-slider/assets/index.css';
import {
  ClientQuestionId,
  ClientQuestionChoiceId,
  QuestionChoice,
} from 'client/shared/core/question';
import Slider from 'rc-slider';
import {
  COLORS_GRAY_10_HEX,
  COLORS_GRAY_20_HEX,
  COLORS_GRAY_30_HEX,
  COLORS_GRAY_40_HEX,
  COLORS_GRAY_50_HEX,
  COLORS_JUNGLE_HEX,
} from 'client/shared/core/colors';

export interface SliderProps {
  readonly questionId: ClientQuestionId;
  readonly choices: readonly QuestionChoice[];
  readonly currentRowId: ClientQuestionId;
  readonly selectChoice: (index: number) => void;
  readonly selectedChoiceId: ClientQuestionChoiceId | null;
  readonly disabled?: boolean;
  readonly hasInputError: boolean;
}

interface Mark {
  readonly [key: number]: React.ReactElement;
}

const baseClass = 'pn-voting-slider';
const selectedAnswerColor = COLORS_GRAY_40_HEX;

const dotStyle = {
  width: 7,
  height: 7,
  marginTop: -7,
  border: 'none',
  outline: 'none',
  boxShadow: 'none',
};
const handleStyle = {
  border: 'none',
  outline: 'none',
  boxShadow: 'none',
};

export const VotingSlider: React.FC<SliderProps> = (props) => {
  const [sliderValue, setSliderValue] = React.useState(
    Math.ceil(props.choices.length / 2)
  );
  const [selectedAnswer, setSelectedAnswer] = React.useState(false);
  const [onChange, setOnChange] = React.useState(false);

  // note that slider's value starts from 1 not 0, so we need to add 1
  const prevChoice =
    props.disabled === true
      ? props.choices.findIndex((c) => c.id === props.selectedChoiceId)
      : null;
  const { choices } = props;

  return (
    <div
      className={`${baseClass} d-flex align-items-center justify-content-center py-3 px-3 h-50 ${
        props.hasInputError && !props.selectedChoiceId ? 'border border-danger' : ''
      }`}
      style={{ backgroundColor: onChange ? COLORS_GRAY_10_HEX : 'inherit' }}
    >
      <Slider
        className={'m-3'}
        {...sliderStyles(selectedAnswer, onChange, !!prevChoice)}
        defaultValue={prevChoice ?? sliderValue}
        disabled={!!prevChoice}
        dots
        marks={
          selectedAnswer
            ? selectedLabelForSlider(
                choices.map((c) => c.label),
                sliderValue,
                onChange
              )
            : createLabelsForSlider(choices.map((c) => c.label))
        }
        max={choices.length - 1}
        onAfterChange={(v) => {
          setSliderValue(v);
          props.selectChoice(v);
          setOnChange(false);
          setSelectedAnswer(true);
        }}
        onChange={(v) => {
          setOnChange(true);
          setSliderValue(v);
          setSelectedAnswer(true);
        }}
        step={1}
      />
    </div>
  );
};

// value is label
// example: {0: 'Excellent', 1: 'Great'}
export function createLabelsForSlider(colLabels: readonly string[]): Mark {
  return colLabels.reduce((labels: Mark, c, i) => {
    const ShouldShowLabel = i === 0 || i === colLabels.length - 1;
    return {
      ...labels,
      [i]: (
        <div
          className={`${baseClass}-col-label`}
          style={{
            display: ShouldShowLabel ? 'inherit' : 'none',
            transform: i === 0 ? ' translate(10px)' : '', // somehow first label already overflow to left, this is ugly, but it should fix first label overflow issue in a survey.
          }}
        >
          {c}
        </div>
      ),
    };
  }, {});
}

function selectedLabelForSlider(
  colLabels: readonly string[],
  position: number,
  onChange: boolean
): Mark {
  return {
    [position]: (
      <div
        className={`${baseClass}-col-label`}
        style={{
          paddingLeft: 4,
          paddingRight: 4,
          backgroundColor: onChange ? COLORS_GRAY_50_HEX : 'transparent',
          color: onChange ? 'white' : 'inherit',
          borderRadius: onChange ? 2 : 0,
          fontSize: onChange ? 12 : 'inherit',
        }}
      >
        {colLabels[position]}
      </div>
    ),
  };
}

export const sliderStyles = (
  selectedAnswer: boolean,
  onChange: boolean,
  disabled: boolean
) => {
  const defaultColor = disabled ? COLORS_GRAY_20_HEX : COLORS_GRAY_30_HEX;

  return {
    railStyle: {
      backgroundColor:
        selectedAnswer && onChange && !disabled ? selectedAnswerColor : defaultColor,
      height: 2,
      marginTop: 1,
    },
    trackStyle: { backgroundColor: 'transparent', height: 2 },
    activeDotStyle: {
      backgroundColor:
        selectedAnswer && onChange && !disabled ? selectedAnswerColor : defaultColor,
    },
    dotStyle: {
      ...dotStyle,
      backgroundColor:
        selectedAnswer && onChange && !disabled ? selectedAnswerColor : defaultColor,
    },
    handleStyle: {
      ...handleStyle,
      backgroundColor: disabled ? defaultColor : COLORS_JUNGLE_HEX,
      height: onChange ? 10 : 18,
      width: onChange ? 10 : 18,
      marginTop: onChange ? -3 : -7,
    },
  };
};
