import * as React from 'react';
import { getConfig } from '../../core/config';
import { MaterialIcon, MaterialIconName, Well, WellType } from '../base';

export enum ContentUnavailableType {
  NOT_FOUND = 'NOT_FOUND',
  UNAVAILABLE = 'UNAVAILABLE',
}

export const ContentUnavailableWell: React.FC<{
  readonly type: ContentUnavailableType;
  readonly contentTypeLabel: string;
}> = (props) => {
  const config = getConfig();
  return (
    <Well
      icon={
        <MaterialIcon
          className={'text-gray-50 mr-1'}
          icon={MaterialIconName.INFO}
          iconType={'material-icons-outlined'}
        />
      }
      type={WellType.TEXT_BLOCK_WHITE_WITH_BORDER}
    >
      {props.type === ContentUnavailableType.NOT_FOUND && (
        <p>
          <span className="font-weight-bold">
            Looks like this {props.contentTypeLabel} didn't load
          </span>{' '}
          Please wait a moment and refresh the page. If it persists feel free to
          contact us at{' '}
          <a
            className={'text-polco-green'}
            href={config.contactPageUrl}
            target="_blank"
          >
            Polco.us
          </a>
        </p>
      )}
      {props.type === ContentUnavailableType.UNAVAILABLE && (
        <p>
          <span className="font-weight-bold">
            This {props.contentTypeLabel} is no longer available to display
          </span>{' '}
          If this is an error and needs to be fixed please contact us at{' '}
          <a
            className={'text-polco-green'}
            href={config.contactPageUrl}
            target="_blank"
          >
            Polco.us
          </a>
        </p>
      )}
    </Well>
  );
};
