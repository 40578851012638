import React from 'react';

import './styles.scss';

interface Props {
  readonly color: string;
  readonly isNull?: boolean;
  readonly className?: string;
}

export const ColorIndicator: React.FC<Props> = (props) => {
  const backgroundColor = {
    backgroundColor: props.color,
  };
  return (
    <div
      className={`${props.className ?? ''} color-indicator`}
      style={backgroundColor}
    />
  );
};
