import { getObjectKeys, wrap } from 'core';
import { type RGBColor } from './colors';
import { GENERATED_COLORS, type PolcoColor as PolcoColorType } from './colors.g';

export enum ColorContext {
  BAR_CHART = 'BAR_CHART',
  PIE_CHART = 'PIE_CHART',
  MAP = 'MAP',
  LOADING_BARS = 'LOADING_BARS',
  WORD_CLOUD = 'WORD_CLOUD',
  DEFAULT = 'DEFAULT',
  INDEX_SCORE = 'INDEX_SCORE',
}

interface ColorScale {
  readonly lightest: PolcoColorType;
  readonly base: string;
  readonly darkest: PolcoColorType;
}
export class PolcoColor {
  name: PolcoColorType;
  hex: typeof GENERATED_COLORS[PolcoColorType];
  rgb: RGBColor | null;
  colorScale: ColorScale;
  constructor(colorName: PolcoColorType) {
    this.name = colorName;
    this.hex = GENERATED_COLORS[colorName];
    this.rgb = this.hexToRgb(this.hex);
    this.colorScale = this.buildColorScale(colorName);
  }

  /**
   *
   * @description Color scale lengths are inconsistent so we only return darkest, brightest, and base color
   */
  private buildColorScale(color: PolcoColorType): ColorScale {
    // Remove any -XD, -D, etc and -0, -1, -10 etc
    const baseColor = color.replace(/-(XD|D|M||L|XL|\d+)$/, '');

    const related = getObjectKeys(GENERATED_COLORS).filter(
      (c) => c.startsWith(baseColor) && c !== baseColor
    );

    // For colors with no scale like 'White' and 'Black'
    if (!related.length) {
      return {
        lightest: 'White',
        base: baseColor,
        darkest: 'Black',
      };
    }

    // Retrieve either number scale or XL-XD scale
    const modifiers = related.map((str) => {
      return str.slice(str.lastIndexOf('-') + 1);
    });

    // Gray and grayscale use numbers for scaling
    const numberScale = modifiers.every((str) => Number.isInteger(Number(str)));
    const lightestSuffix = numberScale ? Math.min(...modifiers.map(Number)) : 'XL';
    const darkestSuffix = numberScale ? Math.max(...modifiers.map(Number)) : 'XD';

    const polcoLightColor = `${baseColor}-${lightestSuffix}`;
    const polcoDarkColor = `${baseColor}-${darkestSuffix}`;
    return {
      lightest: this.isPolcoColor(polcoLightColor) ? polcoLightColor : 'White',
      base: baseColor,
      darkest: this.isPolcoColor(polcoDarkColor) ? polcoDarkColor : 'Black',
    };
  }

  private hexToRgb(hex: string): RGBColor | null {
    let hexCopy = hex;
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hexCopy = hex.replace(shorthandRegex, function (_m, r, g, b) {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexCopy);
    return result
      ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
      : null;
  }

  private isPolcoColor(str: string): str is PolcoColorType {
    return str in GENERATED_COLORS;
  }

  static rgbToHex(raw: RGBColor) {
    return `#${raw.map((part) => part.toString(16).padStart(2, '0')).join('')}`;
  }
  static hexToRgb(hex: string): RGBColor {
    return this.hexToRgb(hex);
  }

  static isPolcoColor(str: string): str is PolcoColorType {
    return this.isPolcoColor(str);
  }

  static template(type: ColorContext): readonly PolcoColor[] {
    const colors: PolcoColorType[] = wrap(() => {
      switch (type) {
        case ColorContext.DEFAULT:
        case ColorContext.BAR_CHART:
          return [
            'Canary',
            'NRC-Gold-D',
            'Polco-Green',
            'Jungle',
            'Sky',
            'Rose',
            'Rose-D',
          ];

        case ColorContext.LOADING_BARS:
        case ColorContext.MAP:
          return [
            'Polco-Green',
            'Jungle',
            'Sky',
            'Rose',
            'Rose-D',
            'Canary',
            'NRC-Gold-D',
          ];
        case ColorContext.PIE_CHART:
          return [
            'Aqua',
            'Royal',
            'Polco-Green',
            'Rose-D',
            'Valencia',
            'Sky-D',
            'Liberty',
          ];
        case ColorContext.WORD_CLOUD:
          return [
            'Sky',
            'Rose',
            'Rose-D',
            'Canary',
            'NRC-Gold-D',
            'Polco-Green',
            'Jungle',
          ];
        case ColorContext.INDEX_SCORE:
          return ['Valencia', 'Canary-D', 'Gray-60', 'Aqua', 'Lime'];
      }
    });
    return colors.map((c) => new PolcoColor(c));
  }
}
