import { PermissionType } from '@polco-us/types';
import { PublisherAdminManagementInfo } from 'client/admin/settings/admin-management/containers/administrators';
import { ClientAdminRole } from 'client/admin/shared/types';
import { AdminPublishingEntityRoles } from 'client/shared/graphql-client/graphql-operations.g';
import {
  AdminPermissionCategory,
  AdminPermissionCategoryId,
  AdminRole,
  AdminRoleId,
  isEnum,
} from 'core';
import _ from 'lodash';
type GqlAdminRole = NonNullable<
  NonNullable<AdminPublishingEntityRoles['openPublishingEntityById']>['adminRoles']
>[0];
type GqlPermissionCategory = GqlAdminRole['permissions'][0]['category'];

export function gqlToPermissionType(
  permissions: readonly string[]
): readonly PermissionType[] {
  return permissions.filter((perm): perm is PermissionType =>
    isEnum(PermissionType, perm)
  );
}

export function gqlToAdminRole(role: GqlAdminRole): AdminRole {
  return {
    id: role.id as AdminRoleId,
    name: role.name,
    description: role.description,
    permissions: role.permissions.map((p) => {
      return {
        name: p.name as PermissionType,
        displayName: p.displayName,
        category: {
          id: p.category.id as AdminPermissionCategoryId,
          name: p.category.name,
        },
      };
    }),
  };
}
export function gqlToAdminRoles(
  adminRolesdata: readonly GqlAdminRole[]
): readonly AdminRole[] {
  const adminRoles: AdminRole[] = adminRolesdata.map(gqlToAdminRole);
  return _.sortBy(adminRoles, ({ permissions }) => permissions.length).reverse();
}

export function gqlToPermissionCategory(
  categoryData: GqlPermissionCategory
): AdminPermissionCategory {
  return {
    ...categoryData,
    id: categoryData.id as AdminPermissionCategoryId,
  };
}

export function gqlToClientAdminRoles(
  adminRoles: readonly AdminRole[],
  publisherAdmins: readonly PublisherAdminManagementInfo[]
): readonly ClientAdminRole[] {
  const clientAdminRoles: ClientAdminRole[] = adminRoles.map((role) => {
    const roleCount = _.countBy(publisherAdmins, (a) => a.role.id);
    return {
      ...role,
      numOfActiveRoles: roleCount[role.id] ?? 0,
    };
  });
  return clientAdminRoles;
}
