// We dont force store keys to be in an enum because it would make adding new store items very slow.
// Instead, we use these keys for when we "know" a store item has it. This list should be slowly
// Lagging behind what is actually in the DB
export enum KnownStoreKeys {
  FREE_QUESTION_CONSULTATION_1 = 'free-question-consolation-1',
  FREE_QUESTION_CONSULTATION_2 = 'free-question-consolation-2',

  MAPS = 'maps',
  CSV_RESULTS = 'csv-results',
  GUEST_RESPONDENTS = 'guest-respondents',
  PREMIUM_LIBRARY = 'premium-library',
  ADVANCED_SURVEYS = 'advanced-surveys',
  CUSTOM_GEO_AREA = 'custom-geo-area',
  RESULT_FILTERS = 'result-filters',
  AGE_BREAKDOWN = 'age-breakdown',
  CUSTOM_VERIFICATION = 'custom-verification',
  MAILED_SAMPLE = 'mailed-sample',
  SAMPLED_SURVEY = 'sampled-survey',
  POLCO_LIVE = 'polco-live',
  GENDER_BREAKDOWN = 'gender-breakdown',
  ACCESS_SETTINGS = 'access-settings',
  CONDITIONAL_LOGIC = 'conditions',
  PRINT_VIEW = 'print-view',
  ADD_ADMINS = 'add-admins',

  CUSTOM_TIER = 'custom-tier',
  PERFORMANCE_TIER = 'tier-performance',
  PREMIUM_TIER = 'tier-premium',
}

export enum StoreItemProvider {
  POLCO = 'POLCO',
  NRC = 'NRC',
}

export enum StoreItemCategory {
  COMING_SOON = 'COMING_SOON',
  SERVICE = 'SERVICE',
  SURVEY = 'SURVEY',
  FEATURE = 'FEATURE',
  FREE_SERVICE = 'FREE_SERVICE',
  TIER = 'TIER',
  UNKNOWN = 'UNKNOWN',
}

export enum TransactionType {
  FREE_TRIAL = 'FREE_TRIAL',
  QUOTE = 'QUOTE',
  EXPRESS_INTEREST = 'EXPRESS_INTEREST',
  FREE_ITEM = 'FREE_ITEM',
}

export interface StorePurchaseNoPayment {
  readonly storeItemIds: readonly string[];
  readonly transactionType: TransactionType;
}

export interface StripeInput {
  readonly email: string;
  readonly stripePaymentMethodId: string;
  readonly address: AdminAddress;
  readonly publishingEntityId: string;
  readonly priceIds: readonly string[];
  readonly discountCode: string | null;
}
export interface AdminAddress {
  readonly line1: string;
  readonly city: string;
  readonly country: string;
  readonly line2: string;
  readonly postal_code: string;
  readonly state: string;
}
