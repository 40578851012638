import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useId } from 'react-use-id-hook';
import './styles.scss';
import {
  AppLink,
  EventPropagationStopper,
  ExpandMode,
  MaterialIcon,
  MaterialIconName,
  Pill,
  PillTypes,
  Well,
  WellType,
} from 'client/shared/components/base';
import { BenchmarkIndicator } from 'client/shared/components/benchmark-indicator';
import {
  MMMM_YEAR_FORMAT,
  YEAR_FORMAT,
  AnalyticsDomain,
  ActivationState,
  VisualizationType,
  SavedVisualizationFilterStates,
  VariableDisplayType,
  TrackDatum,
  TrackVariableMetadata,
  TrackVariable,
  SafeRecordDictionary,
  ChartType,
  wrap,
  MapExtentBounds,
  isLargeComparisonGroup,
} from 'core';
import { DifferenceIndicator } from 'client/shared/components/difference-indicator';
import {
  BenchmarkFilter,
  DateLevel,
} from 'client/shared/graphql-client/graphql-operations.g';
import {
  analyticsBenchmarkToClient,
  formatIndicatorValue,
  getFilteredBenchmarkFooter,
  getFilteredBenchmarkValue,
  variableDataToMapBuckets,
} from 'client/shared/core/performance-data';
import { DownloadImageFooter } from 'client/shared/components/download-image-footer';
import { Dropdown as BsDropdown } from 'react-bootstrap';
import { CreateSavedVisualizationInputWithMultipleFips } from 'client/shared/core/saved-visualization';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { EmbedContext } from 'client/shared/contexts/embed-context';
import { EmbedDataPointContext } from 'client/shared/components/visualization-picker';
import { DownloadImageButton } from 'client/shared/components/download-image';
import { EmbedLogoFooter } from 'client/shared/components/embed-logo-footer';
import { Tooltip } from 'client/shared/components/tooltip';
import { DomainIndicatorsGroupWrapper } from 'client/shared/components/domain-indicators-group/domain-indicators-group-wrapper';
import { demographicSegmentLabel } from 'client/shared/components/domain-card-indicator';
import { useGenerateImage } from 'client/shared/hooks';
import { TrackMap } from '../track-map';
import { MultiPolygon } from '@turf/helpers';
import {
  TrackIndicatorBtnType,
  TrackIndicatorCollapseBtn,
} from '../map-collapse-btn';
import { CommunityStatisticVisualizationType } from 'client/admin/track/overview/containers/community-statistics-container';
import { CollapseBtn } from '../base/collapse-btn';
import MapView from '@arcgis/core/views/MapView';
import { GqlGoal } from 'client/admin/hooks/use-track-goals';
import { useAdminPermissions } from 'client/admin/hooks/use-admin-permissions';
import { PermissionType } from '@polco-us/types';
import { useFlagEnabled, KnownFlag } from 'client/shared/contexts/flags-context';
import { ManageGoalModal } from 'client/admin/track/overview/components/manage-goal-modal';
export interface Props {
  readonly benchmarkFilter: BenchmarkFilter;
  readonly className?: string;
  readonly domain: AnalyticsDomain;
  readonly expanded: boolean;
  readonly toggleExpanded: (variableId: string) => void;
  readonly toggleVisualization: (
    variableId: string,
    visualizationType: CommunityStatisticVisualizationType
  ) => void;
  readonly variableVisualizationType: CommunityStatisticVisualizationType | null;
  readonly embedContext?: EmbedDataPointContext;
  readonly showFooter?: boolean;
  readonly saveVisualization?: (
    clientInput: CreateSavedVisualizationInputWithMultipleFips
  ) => Promise<void>;
  readonly disableSaveVisualization?: boolean;
  readonly canNotManageDataPoints?: boolean;
  readonly indicator: TrackVariable;
  readonly currentFips?: string;
  readonly displayType: VariableDisplayType;
  readonly comparisonGroupId?: string;
  readonly fipsShapeByFipsCode: SafeRecordDictionary<string, MultiPolygon>;
  readonly mapBoundingCoordinates?: MapExtentBounds;
  readonly goal?: GqlGoal;
  readonly planId?: string;
  readonly publishingEntityId: string;
}

const baseClass = 'pn-domain-community-statistic';

const communityStatisticsCopy = {
  differenceIndicator:
    "This number represents the change in this indicator's value since the previous measurement.",
  dataUnavailable: 'Data unavailable',
  chartWell: (
    description: string | null,
    chartType?: ChartType,
    fipsName?: string
  ) => {
    const chartTypeCopy = wrap(() => {
      switch (chartType) {
        case ChartType.LINE:
          return 'change over time';
        case ChartType.MAP:
          return 'relative percentile ranges across geographic regions';
        case ChartType.HISTOGRAM:
          return 'distribution of percentiles';
      }
    });
    return (
      <>
        {chartTypeCopy && `This chart illustrates the ${chartTypeCopy} in`}
        <span className="font-weight-bold"> {description}</span>
        {!!fipsName && (
          <>
            {' for '}
            <span className="font-weight-bold">{fipsName}</span>
          </>
        )}
        .
      </>
    );
  },
  noChartText: (description: string | null, fipsName?: string) => {
    return (
      <>
        <span className="font-weight-bold"> {description}</span>
        {!!fipsName && (
          <>
            {' for '}
            <span className="font-weight-bold">{fipsName}</span>
          </>
        )}
        .
      </>
    );
  },
  indicatorMetaData: (args: {
    readonly source: string;
    readonly minRecordedAt: Date | null;
    readonly maxRecordedAt: Date | null;
    readonly dateLevel: DateLevel | null;
    readonly hideDate: boolean;
  }) => {
    const dateFormat =
      args.dateLevel === DateLevel.YEAR ? YEAR_FORMAT : MMMM_YEAR_FORMAT;
    const minDateString = args.minRecordedAt
      ? moment.utc(args.minRecordedAt).format(dateFormat)
      : null;
    const maxDateString = args.maxRecordedAt
      ? moment.utc(args.maxRecordedAt).format(dateFormat)
      : null;
    const useDateString = wrap(() => {
      if ((!minDateString && !maxDateString) || args.hideDate) {
        return null;
      } else if (minDateString === maxDateString) {
        return minDateString;
      } else {
        return `${minDateString} - ${maxDateString}`;
      }
    });
    return (
      <div
        className={`${baseClass}-indicator-metadata font-size-xs mt-3 display-flex justify-content-end`}
      >
        <div>
          Data from <span className="font-weight-bold">{args.source}</span>
          {useDateString && (
            <>
              {' in '}
              <span className="font-weight-bold">{useDateString}</span>
            </>
          )}
        </div>
      </div>
    );
  },
};

// This is a temporary component to begin implementing Track maps
// For now this is tailored to lage groups, but eventually this can be updated to be the real maps implementation
// At which point it can replace the content of GroupIndicatorCard

export const GroupIndicatorCard: React.FC<Props> = React.memo((p) => {
  const communityStatisticsId = useId();
  const isInEmbedApp = React.useContext(EmbedContext);
  const ref = React.useRef<HTMLDivElement>(null);
  const [goalModalOpen, setGoalModalOpen] = React.useState(false);

  const [mapView, setMapView] = React.useState<MapView | null>(null);
  const imageMapData = React.useMemo(
    () => ({
      mapView: mapView ?? undefined,
      mapContainerClass: '.pn-track-map',
      mapLegendClass: '.pn-track-map-legend',
    }),
    [mapView]
  );
  const generateImage = useGenerateImage(ref, {
    imageName: `${p.indicator.label} indicator`,
    mapData: imageMapData,
  });

  const { doesCurrentAdminHavePermissions: havePermissions } = useAdminPermissions();
  const canManageGoals = havePermissions([PermissionType.MANAGE_TRACK_GOALS]);
  const goalsEnabled = useFlagEnabled(KnownFlag.TRACK_GOALS);

  const imageDownloadEnabled = !p.embedContext && !isInEmbedApp;
  const embeddableTrackEnabled =
    !p.canNotManageDataPoints &&
    p.embedContext !== EmbedDataPointContext.VISUALIZATION;

  const variable = p.indicator;

  const viewerValues =
    variable.areasData.find((ad) => ad.fipsArea.id === p.currentFips)
      ?.performanceData ?? [];

  const sortedValues = _.reverse(
    _.sortBy(viewerValues, ({ recordedAt }) => recordedAt)
  );

  const { minRecentRecordedAt, maxRecentRecordedAt } = React.useMemo(() => {
    const mostRecentDataPoints = _.compact(
      variable.areasData.map((ad) =>
        _.maxBy(ad.performanceData, (pd) => pd.recordedAt)
      )
    );
    const minDataPointDate =
      _.minBy(mostRecentDataPoints, (d) => d.recordedAt)?.recordedAt ?? null;
    const maxDataPointDate =
      _.maxBy(mostRecentDataPoints, (d) => d.recordedAt)?.recordedAt ?? null;
    return {
      minRecentRecordedAt: minDataPointDate,
      maxRecentRecordedAt: maxDataPointDate,
    };
  }, [variable.areasData]);

  const recentValue =
    sortedValues.length && sortedValues[0].value !== null ? sortedValues[0] : null;
  const hasComparisonGroupData = variable.areasData.length > 0;
  const benchmarkValue = recentValue
    ? getFilteredBenchmarkValue(recentValue, p.benchmarkFilter)
    : null;

  const footer = variable.direction
    ? getFilteredBenchmarkFooter(p.benchmarkFilter)
    : undefined;
  const allAreas = variable.areasData.map((ad) => ad.fipsArea);

  const mapData = React.useMemo(
    () =>
      variableDataToMapBuckets({
        areasData: variable.areasData,
        fipsShapeByFipsCode: p.fipsShapeByFipsCode,
        variable,
        benchmarkFilter: p.benchmarkFilter,
      }),
    [p.benchmarkFilter, p.fipsShapeByFipsCode, variable]
  );

  const isLargeGroup = React.useMemo(
    () => isLargeComparisonGroup(p.indicator.areasData.length),
    [p.indicator]
  );

  const lineChartExpanded =
    p.variableVisualizationType === CommunityStatisticVisualizationType.LINE;
  const mapExpanded =
    p.variableVisualizationType === CommunityStatisticVisualizationType.MAP;
  const chartType = wrap(() => {
    if (lineChartExpanded) {
      return ChartType.LINE;
    }
    if (mapExpanded) {
      return ChartType.MAP;
    }
  });

  const visualizationType = wrap(() => {
    if (lineChartExpanded) {
      return VisualizationType.LINE;
    }
    if (mapExpanded) {
      return VisualizationType.MAP;
    }
  });

  return (
    <div
      className={`${baseClass} ${p.className || ''} p-3 ${
        p.embedContext === EmbedDataPointContext.VISUALIZATION ? 'border-0' : ''
      }`}
      id={communityStatisticsId}
      ref={ref}
    >
      <div className="d-flex justify-content-between mb-3">
        <div
          className={`${baseClass}-title-container d-flex flex-wrap justify-content-between align-items-start`}
        >
          <div className="d-flex align-items-start flex-column justify-content-start">
            <div className={`font-weight-bold font-size-lg mr-4`}>
              {variable.label}
            </div>
            {p.displayType === VariableDisplayType.OLDER_ADULTS && (
              <div
                className={`${baseClass}-demographic-segment d-flex d-row flex-wrap justify-content-start`}
              >
                <Pill
                  className="segment"
                  key={variable.demographicSegment}
                  type={PillTypes.FACET}
                >
                  {demographicSegmentLabel(variable.demographicSegment)}
                </Pill>
              </div>
            )}
            {benchmarkValue && !!variable.direction && (
              <BenchmarkIndicator
                benchmarkValue={analyticsBenchmarkToClient(benchmarkValue)}
                className={`${baseClass}-benchmark-indicator mb-1`}
                expandMode={ExpandMode.ALWAYS_EXPANDED}
              />
            )}
          </div>
        </div>
        <TrackIndicatorCollapseBtn
          action={() =>
            p.toggleVisualization(
              variable.id,
              CommunityStatisticVisualizationType.MAP
            )
          }
          ariaControls={communityStatisticsId}
          expanded={mapExpanded}
          type={TrackIndicatorBtnType.MAPS}
        />
        <TrackIndicatorCollapseBtn
          action={() =>
            p.toggleVisualization(
              variable.id,
              CommunityStatisticVisualizationType.LINE
            )
          }
          ariaControls={communityStatisticsId}
          className="ml-1"
          expanded={lineChartExpanded}
          type={TrackIndicatorBtnType.LINE}
        />
        {isLargeGroup && (
          <CollapseBtn
            action={() => p.toggleExpanded(variable.id)}
            ariaControls={communityStatisticsId}
            expanded={p.expanded}
          />
        )}

        <div className="d-flex">
          {embeddableTrackEnabled && p.saveVisualization ? (
            <DomainCardOptionsMenu
              allFips={allAreas.map((a) => a.id)}
              benchmarkFilter={p.benchmarkFilter}
              canManageGoals={canManageGoals && goalsEnabled}
              canNotManageDataPoints={p.canNotManageDataPoints}
              currentFips={p.currentFips ?? null}
              disableSaveVisualization={p.disableSaveVisualization}
              domain={p.domain}
              embeddableTrackEnabled={embeddableTrackEnabled}
              events={{
                generateImage,
                saveVisualization: p.saveVisualization,
                setGoalModalOpen,
              }}
              goal={p.goal}
              groupId={p.comparisonGroupId}
              imageDownloadEnabled={imageDownloadEnabled}
              planId={p.planId}
              recentValue={recentValue}
              variable={variable}
              visualizationType={visualizationType ?? null}
            />
          ) : (
            imageDownloadEnabled && (
              <DownloadImageButton
                buttonClassName={`save-image-track-${
                  p.domain
                }-community-statistics-${variable.name.replace(/\s/g, '')}`}
                imageRef={ref}
                name={`${variable.label} Indicator`}
              />
            )
          )}
        </div>
      </div>
      {p.showFooter && (
        <div className="font-size-sm py-2 mb-3 no-show-in-image">{footer}</div>
      )}
      <div
        className={`${baseClass}-data d-flex flex-row flex-wrap justify-content-between align-items-center`}
      >
        {recentValue ? (
          <>
            <div className={`d-flex flex-row align-items-center`}>
              <div
                className={`${baseClass}-indicator-value font-weight-bold mr-2 text-liberty`}
              >
                {formatIndicatorValue(recentValue.value, variable.valueType)}
              </div>
              <div className="font-size-sm text-gray-40">{variable.suffix}</div>
              {recentValue.previousValue && (
                <DifferenceIndicator
                  className="ml-2 cursor-pointer"
                  difference={recentValue.value - recentValue.previousValue?.value}
                  isNeutralPalette
                  tooltip={communityStatisticsCopy.differenceIndicator}
                  tooltipId={`difference-indicator-information-${variable.name}`}
                  valueType={variable.valueType}
                  variableDateLevel={variable.dateLevel}
                />
              )}
            </div>
          </>
        ) : (
          !hasComparisonGroupData && (
            <div
              className={`${baseClass}-data-unavailable font-weight-bold text-gray-40`}
            >
              {communityStatisticsCopy.dataUnavailable}
            </div>
          )
        )}
      </div>
      {mapExpanded && p.comparisonGroupId && (
        <TrackMap
          comparisonGroupId={p.comparisonGroupId}
          height={600}
          mapBounds={p.mapBoundingCoordinates}
          onMapReady={setMapView}
          shapeBuckets={mapData}
        />
      )}
      <DomainIndicatorsGroupWrapper
        expanded={p.expanded}
        fipsAreas={allAreas}
        goal={p.goal}
        isEmbedded={!!p.embedContext}
        label={null}
        showLineChart={lineChartExpanded}
        trackAreaData={variable.areasData}
        variable={p.indicator}
      />
      {(p.expanded || !isLargeGroup) && (
        <div className="mt-2">
          <Well
            className="mb-0"
            noTopMargin
            type={WellType.TEXT_BLOCK_WHITE_WITH_BORDER}
          >
            {communityStatisticsCopy.chartWell(variable.description, chartType)}
          </Well>
        </div>
      )}
      {variable.source &&
        communityStatisticsCopy.indicatorMetaData({
          source: variable.source,
          minRecordedAt: minRecentRecordedAt,
          maxRecordedAt: maxRecentRecordedAt,
          dateLevel: variable.dateLevel,
          hideDate: lineChartExpanded,
        })}
      <DownloadImageFooter footer={footer} />
      {p.embedContext === EmbedDataPointContext.VISUALIZATION && (
        <EmbedLogoFooter
          className={lineChartExpanded || mapExpanded ? 'pt-2' : ''}
        />
      )}
      {p.planId && (
        <ManageGoalModal
          currentGoalData={p.goal}
          events={{
            cancel: () => setGoalModalOpen(false),
          }}
          isOpen={goalModalOpen}
          planId={p.planId}
          publishingEntityId={p.publishingEntityId}
          suffix={variable.suffix}
          valueType={variable.valueType}
          variableId={variable.id}
          variableLabel={variable.label}
        />
      )}
    </div>
  );
});

interface DomainCardOptionsMenuProps {
  readonly currentFips: string | null;
  readonly imageDownloadEnabled: boolean;
  readonly embeddableTrackEnabled: boolean;
  readonly recentValue: TrackDatum | null;
  readonly variable: TrackVariableMetadata;
  readonly allFips: readonly string[];
  readonly groupId?: string;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly domain: AnalyticsDomain;
  readonly visualizationType: VisualizationType | null;
  readonly events: {
    readonly generateImage?: () => Promise<void>;
    readonly saveVisualization?: (
      clientInput: CreateSavedVisualizationInputWithMultipleFips
    ) => Promise<void>;
    readonly setGoalModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  };
  readonly disableSaveVisualization?: boolean;
  readonly canNotManageDataPoints?: boolean;
  readonly className?: string;
  readonly canManageGoals?: boolean;
  readonly goal?: GqlGoal;
  readonly planId?: string;
}

export const DomainCardOptionsMenu: React.FC<DomainCardOptionsMenuProps> = (
  props
) => {
  const { generateImage, saveVisualization, setGoalModalOpen } = props.events;

  const showEnabledSaveDataPointsMenuItem =
    !!props.visualizationType &&
    !props.canNotManageDataPoints &&
    props.embeddableTrackEnabled &&
    !!saveVisualization &&
    !props.disableSaveVisualization;

  const showDisabledSaveDataPointsMenuItem =
    !!props.visualizationType &&
    !props.canNotManageDataPoints &&
    props.embeddableTrackEnabled &&
    !!saveVisualization &&
    props.disableSaveVisualization;

  const showGoalMenuItem =
    props.canManageGoals && !!setGoalModalOpen && !!props.planId;
  return (
    <BsDropdown className={`no-show-in-image ${props.className || ''}`}>
      <EventPropagationStopper>
        <div className={`${baseClass}-toggle-wrapper`}>
          <BsDropdown.Toggle
            className={`${baseClass}-action-toggle p-0 m-0`}
            variant="link"
          >
            <MaterialIcon
              className={`${baseClass}-action-toggle-icon`}
              icon={MaterialIconName.MORE_VERT}
            />
          </BsDropdown.Toggle>
        </div>
      </EventPropagationStopper>

      <BsDropdown.Menu alignRight>
        <EventPropagationStopper>
          {props.imageDownloadEnabled && (
            <BsDropdown.Item
              className={`${baseClass}-dropDown-item save-image-track-${
                props.domain
              }-community-statistics-${props.variable.name.replace(/\s/g, '')}`}
              onClick={generateImage}
            >
              <div>{DROPDOWN_COPY.image}</div>
            </BsDropdown.Item>
          )}

          {showEnabledSaveDataPointsMenuItem && (
            <BsDropdown.Item
              className={`${baseClass}-dropDown-item save-data-point`}
              disabled={props.disableSaveVisualization}
              onClick={async () => {
                await saveVisualization({
                  baseFips: props.currentFips ?? null,
                  recordedAt: props.recentValue?.recordedAt ?? null,
                  benchmarkFilter: props.benchmarkFilter,
                  state: ActivationState.ACTIVE,
                  variableId: props.variable.id,
                  visualizationType: props.visualizationType ?? null,
                  groupFips: props.allFips,
                  comparisonGroupId: props.groupId,
                });
              }}
            >
              <div>{DROPDOWN_COPY.dataPoint}</div>
            </BsDropdown.Item>
          )}
          {showGoalMenuItem && (
            <BsDropdown.Item
              className={`${baseClass}-dropDown-item save-goal`}
              onClick={async () => {
                setGoalModalOpen(true);
              }}
            >
              <div>{DROPDOWN_COPY.goal}</div>
            </BsDropdown.Item>
          )}
          {showDisabledSaveDataPointsMenuItem && (
            <Tooltip
              className={`${baseClass}-tooltip font-weight-normal`}
              content=""
              htmlContent={
                <div>
                  You have exceeded your maximum number of active saved data points.
                  Edit your{' '}
                  <AppLink
                    className="text-white"
                    to={ClientUrlUtils.admin.savedDataPoints.path({
                      tab: SavedVisualizationFilterStates.ACTIVE,
                    })}
                  >
                    Saved Data Points
                  </AppLink>{' '}
                  to make room for more.
                </div>
              }
              id="visualization-explainer"
              place="bottom"
            >
              <BsDropdown.Item
                className={`${baseClass}-dropDown-item save-data-point`}
                disabled={props.disableSaveVisualization}
              >
                <div>{DROPDOWN_COPY.dataPoint}</div>
              </BsDropdown.Item>
            </Tooltip>
          )}
        </EventPropagationStopper>
      </BsDropdown.Menu>
    </BsDropdown>
  );
};

const DROPDOWN_COPY = {
  image: 'Save as Image',
  dataPoint: 'Save Data Point',
  goal: 'Add/Edit Goal',
};

GroupIndicatorCard.displayName = 'GroupIndicatorCard';
