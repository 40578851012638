declare global {
  interface Window {
    readonly Appcues: any;
  }
}

export function initAppcues(appId: string) {
  const appcuesScript = document.createElement('script') as HTMLScriptElement;
  appcuesScript.src = `//fast.appcues.com/${appId}.js`;
  appcuesScript.type = 'text/javascript';
  appcuesScript.referrerPolicy = 'origin';

  document.getElementsByTagName('body')[0].appendChild(appcuesScript);
}

function withAppcues(cb: (Appcues: any) => void) {
  if (window.Appcues) {
    cb(window.Appcues);
  } else if (
    process.env.NODE_ENV !== 'test' &&
    process.env.NODE_ENV !== 'development'
  ) {
    console.warn('Appcues not initialized');
  }
}

export function refreshAppcues() {
  withAppcues((Appcues) => Appcues.page());
}

export function appcuesIdentify(userId: string, properties: object) {
  withAppcues((Appcues) => Appcues.identify(userId, properties));
}

export function appcuesAnonymous() {
  withAppcues((Appcues) => {
    Appcues.reset();
    Appcues.anonymous();
  });
}

export function appcuesReset() {
  withAppcues((Appcues) => Appcues.reset());
}

import notification from './assets/notifications.svg';

export function loadAppcuesLaunchpad(selector: string) {
  withAppcues((Appcues) =>
    Appcues.loadLaunchpad(selector, {
      position: 'left',
      icon: notification,
    })
  );
}
