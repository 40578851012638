import * as React from 'react';
import './styles.scss';
import { truncate } from 'lodash';
import pluralize from 'pluralize';

import { Case, getFullname, TOPIC_SINGLETON_CHOICE_TEXT } from 'core';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { COMMENT_COPY } from './copy';
import { ColorContext, PolcoColor } from 'client/shared/core/colors-class';
import ShowMoreText from 'react-show-more-text';
import { LoadedEvents, VotingComment, Choice } from 'client/shared/core/types';
import { CommentsType } from '../';

interface AdminCommentProps {
  readonly choices: readonly Choice[];
  readonly comment: VotingComment;
  readonly setMaxWidth?: boolean;
  readonly showChoice: boolean;
}

interface RespondentCommentProps {
  readonly upvoteCommentIds: ReadonlyArray<string>;
  readonly choices: readonly Choice[];
  readonly comment: VotingComment;
  readonly setMaxWidth?: boolean;
  readonly showChoice: boolean;
  readonly isClosedQuestion: boolean;
  readonly events: {
    readonly upvoteComment: LoadedEvents['upvoteComment'];
    readonly unUpvoteComment: LoadedEvents['unUpvoteComment'];
  };
}

type CommentProps =
  | Case<CommentsType.RESPONDENT, RespondentCommentProps>
  | Case<CommentsType.ADMIN, AdminCommentProps>;

const baseClass = 'pn-voting-comment';

export const Comment: React.FC<CommentProps> = (props) => {
  const { comment, choices } = props;

  const name = comment.isOwnComment ? (
    <div>{COMMENT_COPY.you}</div>
  ) : (
    getFullname({
      firstName: comment.commenterFirstName,
      lastName: comment.commenterLastName,
    }) || <div>{COMMENT_COPY.anonymous}</div>
  );

  const isRespondentView = props.type === CommentsType.RESPONDENT;
  const respondentUpvoted =
    isRespondentView && props.upvoteCommentIds.includes(comment.id);

  const choiceIndex = choices.findIndex((ch) => ch.id === comment.commenterChoiceId);

  const colorTemplate = PolcoColor.template(ColorContext.LOADING_BARS);
  const color =
    colorTemplate[
      (choiceIndex === -1 ? 0 : choiceIndex) % (colorTemplate.length - 1)
    ];

  const pillBackgroundColor = new PolcoColor(color.colorScale.lightest);
  const pillTextColor = new PolcoColor(color.colorScale.darkest);
  return (
    <article
      className={`${baseClass} ${
        comment.isOwnComment ? 'is-own-comment' : 'bg-white'
      } ${props.setMaxWidth ? 'mod-max-width' : ''} h-100 mb-4 mt-3 p-3`}
    >
      <div className="w-100 h-100 d-flex flex-column justify-content-between">
        <div className="d-flex justify-content-between w-100 align-items-center mb-1">
          <div className={`${baseClass}-name font-weight-bold font-size-sm`}>
            {name}
          </div>
        </div>
        <div className="d-flex">
          <div className={`${baseClass}-text font-size-sm break-word`}>
            <ShowMoreText
              anchorClass="font-weight-bold text-jungle"
              less="Read less"
              lines={3}
              more="Read more"
              width={450}
            >
              {comment.comment}
            </ShowMoreText>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-3 align-items-end">
          <div className={`${baseClass}-upvote mt-1 font-size-sm`}>
            {isRespondentView && !props.isClosedQuestion ? (
              <LikeButton
                alreadyLiked={respondentUpvoted}
                comment={comment}
                likeAction={props.events.upvoteComment}
                unlikeAction={props.events.unUpvoteComment}
              />
            ) : (
              <>{`${pluralize('Like', comment.upvoteCount, true)}`}</>
            )}
          </div>

          {props.showChoice && choiceIndex !== -1 && (
            <div
              className="rounded-pill py-1 px-3 d-flex align-items-center"
              style={{
                backgroundColor: pillBackgroundColor.hex,
                color: pillTextColor.hex,
              }}
            >
              {
                // you should never see this with a free text question, but just in case, removing the possibility of seeing TOPIC_SINGLETON
                choices[choiceIndex].text !== TOPIC_SINGLETON_CHOICE_TEXT && (
                  <div className="font-size-xs">
                    {truncate(choices[choiceIndex].text, { length: 30 })}
                  </div>
                )
              }
            </div>
          )}
        </div>
      </div>
    </article>
  );
};

interface LikeBtnProps {
  readonly alreadyLiked: boolean;
  readonly comment: VotingComment;
  readonly unlikeAction: (id: string) => void;
  readonly likeAction: (id: string) => void;
}

const LikeButton: React.FC<LikeBtnProps> = (p) => {
  const { alreadyLiked, comment, unlikeAction, likeAction } = p;
  if (comment.isOwnComment) return null;
  return (
    <Btn
      action={() =>
        alreadyLiked ? unlikeAction(comment.id) : likeAction(comment.id)
      }
      ariaLabel={alreadyLiked ? 'Unlike comment' : 'Like comment'}
      stopPropagation
      style={{ width: '110px' }}
      type={ButtonTypes.SECONDARY}
    >
      <div className={`${baseClass}-vote-btn`}>
        {alreadyLiked && (
          <MaterialIcon
            className={`${baseClass}-icon align-middle mr-1`}
            icon={MaterialIconName.CHECK}
          />
        )}
        {alreadyLiked ? COMMENT_COPY.liked : COMMENT_COPY.like} (
        {comment.upvoteCount})
      </div>
    </Btn>
  );
};
