import { fbIsInitialized } from 'client/shared/integrations/facebook/core';
import { swallowExpression } from 'core';
import { errorLogger } from 'client/shared/core/error-handler';

export interface FBLoginOptions {
  readonly scope?: string;
  readonly return_scopes?: boolean;
}

export function fbLogin(
  forceServerMode: boolean,
  onSuccess: (token: string) => void,
  onFailure?: (r: fb.AuthResponse) => void,
  options?: FBLoginOptions,
  serverLogin?: () => void
) {
  if (!forceServerMode && fbIsInitialized()) {
    FB &&
      FB.login((response) => {
        if (response.status === 'connected') {
          const accessToken = response.authResponse.accessToken;
          onSuccess(accessToken);
        } else if (onFailure) {
          onFailure(response.authResponse);
          errorLogger.log(
            `Failed to sign up with Facebook: ${response.authResponse}`
          );
        }
      }, options);
  } else {
    swallowExpression(serverLogin?.());
  }
}
