import React from 'react';
import './styles.scss';
import { ClickableDiv } from 'client/shared/components/base';

interface Props<T> {
  readonly options: readonly T[];
  readonly keySelect: (value: T, index: number) => string;
  readonly labelSelect: (value: T, index: number) => string;
  readonly value: T;
  readonly onChange: (value: T) => void;
  readonly disabled?: boolean;
}

const baseClass = 'pn-radio-toggle';

export function RadioToggle<T>(
  p: Props<T> & { readonly children?: React.ReactNode; readonly className?: string },
  _context?: any
): React.ReactElement {
  return (
    <div className={`${baseClass} d-flex`}>
      {p.options.map((option, i) => (
        <RadioToggleItem<T>
          disabled={p.disabled}
          isSelected={p.value === option}
          key={`radio-${option}`}
          label={p.labelSelect(option, i)}
          notFirstItem={i > 0}
          onSelect={(choice) => {
            if (!p.disabled) {
              p.onChange(choice);
            }
          }}
          option={option}
        />
      ))}
    </div>
  );
}

interface RadioToggleItemProps<T> {
  readonly option: T;
  readonly label: string;
  readonly isSelected: boolean;
  readonly onSelect: (value: T) => void;
  readonly notFirstItem?: boolean;
  readonly disabled?: boolean;
}

function RadioToggleItem<T>(
  p: RadioToggleItemProps<T> & { readonly children?: React.ReactNode },
  _context?: any
): React.ReactElement {
  return (
    <ClickableDiv
      action={() => p.onSelect(p.option)}
      className={`${baseClass}-item${p.isSelected ? '-selected' : ''} ${p.notFirstItem ? 'left-border' : ''} ${p.disabled ? 'toggle-disabled' : ''} px-3 py-2 font-weight-bold`}
    >
      {p.label}
    </ClickableDiv>
  );
}
