import * as React from 'react';
import moment from 'moment';
import { SimpleProgressBar } from 'client/shared/components/simple-progress-bar';
import { DataUnavailable } from 'client/shared/components/data-unavailable';
import './styles.scss';
import { Tooltip } from 'client/shared/components/tooltip';
import {
  MaterialIcon,
  MaterialIconName,
  Pill,
  PillTypes,
} from 'client/shared/components/base';
import {
  YEAR_FORMAT,
  wrap,
  MMMM_YEAR_FORMAT,
  TrackVariableMetadata,
  TrackDatum,
  VariableDisplayType,
  VariableDemographicSegment,
} from 'core';
import {
  AnalyticsValueType,
  DateLevel,
} from 'client/shared/graphql-client/graphql-operations.g';
import {
  formatIndicatorValue,
  sanitizePercentageValue,
} from 'client/shared/core/performance-data';

const baseClass = 'pn-dashboard-domain-card-indicator';

export interface Props {
  readonly className?: string;
  readonly indicatorData?: TrackDatum;
  readonly previewMode?: boolean;
  readonly variable?: TrackVariableMetadata;
  readonly displayType?: VariableDisplayType;
}

export const domainCardIndicatorCopy = {
  unavailableData: 'Data unavailable',
};

export const demographicSegmentLabel = (
  demographicSegment: VariableDemographicSegment
) => {
  return (
    <div>
      Represents{' '}
      <span className="font-weight-bold">
        {demographicSegment === VariableDemographicSegment.ALL
          ? 'General'
          : 'Older Adult'}
      </span>{' '}
      Population
    </div>
  );
};
export const DomainCardIndicator: React.FC<Props> = (p) => {
  const { indicatorData, previewMode, displayType, variable } = p;
  if (!variable) {
    return (
      <div className={`${baseClass} ${p.className ?? ''} bg-gray-6`}>
        <DataUnavailable title={domainCardIndicatorCopy.unavailableData} />
      </div>
    );
  }
  const value = indicatorData?.value ? indicatorData.value.toString() : '0';
  const sanitizedValue = sanitizePercentageValue(parseInt(value));
  const hasDataOrIsPreview = indicatorData || previewMode;
  const tooltipContent = buildTooltipContent(
    variable,
    hasDataOrIsPreview ? indicatorData : undefined
  );
  const tooltipTextContent = buildTooltipTextContent(
    variable,
    hasDataOrIsPreview ? indicatorData : undefined
  );
  return (
    <div className={`${baseClass} ${p.className ?? ''} bg-gray-6`}>
      <div
        className={`${baseClass}-header mb-2 d-flex align-items-start justify-content-between`}
      >
        <div className={`${baseClass}-label text-gray-60`}>{variable.label}</div>
        <div className="d-flex align-items-center">
          {displayType === VariableDisplayType.OLDER_ADULTS && (
            <Pill
              className="segment overview"
              key={variable.demographicSegment}
              type={PillTypes.FACET}
            >
              {demographicSegmentLabel(variable.demographicSegment)}
            </Pill>
          )}
          {hasDataOrIsPreview && (
            <Tooltip
              content={tooltipTextContent}
              htmlContent={tooltipContent}
              id={`${variable.name}-tooltip`}
              place="top"
            >
              <MaterialIcon
                className={`${baseClass}-icon text-jungle font-size-base cursor-pointer no-show-in-image ml-2`}
                icon={MaterialIconName.INFO_OUTLINE}
              />
            </Tooltip>
          )}
        </div>
      </div>
      <div className={`${baseClass}-body d-flex flex-row align-items-center `}>
        {!hasDataOrIsPreview && (
          <DataUnavailable title={domainCardIndicatorCopy.unavailableData} />
        )}
        {hasDataOrIsPreview &&
          wrap(() => {
            const styledValue = formatIndicatorValue(
              parseFloat(value),
              variable.valueType,
              2,
              !!previewMode
            );
            switch (variable.valueType) {
              case AnalyticsValueType.AMOUNT:
              case AnalyticsValueType.CURRENCY:
                return (
                  <>
                    <div
                      className={`${baseClass}-body-value mr-1 font-weight-bold font-size-lg text-liberty`}
                    >
                      {styledValue}
                    </div>
                    {variable.suffix && (
                      <div
                        className={`${baseClass}-body-suffix font-size-sm text-gray-40`}
                      >
                        {variable.suffix}
                      </div>
                    )}
                  </>
                );
              case AnalyticsValueType.PERCENT:
                return (
                  <>
                    <div
                      className={`${baseClass}-percentage-value text-liberty mr-2 font-size-lg font-weight-bold`}
                    >
                      {styledValue}
                    </div>
                    {!previewMode && <SimpleProgressBar value={sanitizedValue} />}
                  </>
                );
            }
          })}
      </div>
    </div>
  );
};

function buildTooltipTextContent(
  variable: TrackVariableMetadata,
  indicatorData?: TrackDatum
) {
  const fipAreaName = indicatorData?.fipsArea?.name;
  const recordedAt = getDateIndicatorDataRecordedAt(variable, indicatorData);
  return `
    ${variable?.description || ''}
    \n
    ${fipAreaName && recordedAt ? `For ${fipAreaName} In ${recordedAt}` : ''}
    \n
    ${variable?.source ? `Published by ${variable.source}` : ''}
  `;
}

function getDateIndicatorDataRecordedAt(
  variable: TrackVariableMetadata,
  indicatorData?: TrackDatum
) {
  return moment
    .utc(indicatorData?.recordedAt)
    .format(variable?.dateLevel === DateLevel.YEAR ? YEAR_FORMAT : MMMM_YEAR_FORMAT);
}

function buildTooltipContent(
  variable: TrackVariableMetadata,
  indicatorData?: TrackDatum
) {
  return (
    <div>
      {!!variable.description && (
        <div className="mb-2">
          <div>{variable.description}</div>
        </div>
      )}
      <>
        <>
          <div>
            For{' '}
            <span className="font-weight-bold">
              {capitalizeTokens(indicatorData?.fipsArea?.name)}
            </span>
          </div>
          <div>
            In{' '}
            <span className="font-weight-bold">
              {getDateIndicatorDataRecordedAt(variable, indicatorData)}
            </span>
          </div>
        </>
        <div>
          Published by <span className="font-weight-bold">{variable.source}</span>
        </div>
      </>
    </div>
  );
}

function capitalizeTokens(location?: string) {
  if (!location) {
    return location;
  }
  const tokens = location.split(' ');
  return tokens
    .map((token) => token.charAt(0).toUpperCase() + token.slice(1))
    .join(' ');
}
