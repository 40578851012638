import * as React from 'react';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';

interface Props {
  readonly action: () => void | Promise<any>;
  readonly cta: string;
  readonly disabled?: boolean;
  readonly tabIndex?: 0 | -1;
  readonly materialIcon?: MaterialIconName;
  readonly image?: {
    readonly src: string;
    readonly alt: string;
  };
}

export const SocialButton: React.FC<Props> = (p) => {
  return (
    <Btn
      action={p.action}
      className="text-center w-100 my-1"
      disabled={p.disabled}
      tabIndex={p.tabIndex}
      type={ButtonTypes.SECONDARY}
    >
      {p.materialIcon && (
        <MaterialIcon
          className="text-jungle d-inline-block mr-3"
          icon={p.materialIcon}
        />
      )}
      {p.image && (
        <img
          alt={p.image.alt}
          className="d-inline-block mr-3"
          src={p.image.src}
          style={{ height: 23 }}
        />
      )}
      <div className="d-inline-block">{p.cta}</div>
    </Btn>
  );
};
