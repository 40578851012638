import * as React from 'react';
import { Btn, ButtonTypes } from 'client/shared/components/base';

export interface ContinueButtonType {
  readonly title: string;
  readonly action: () => void | Promise<void>;
}
export interface Props {
  readonly title: string;
  readonly bodyText: string;
  readonly continueButton: ContinueButtonType;
  readonly image: { readonly src: string; readonly alt: string };
  readonly hideContact?: boolean;
}

export const ErrorNotice = (props: Props) => {
  return (
    <section className="container mt-5">
      <div className="row">
        <div className="mt-5 mx-auto">
          <div className="col-12 d-flex justify-content-center">
            <img alt={props.image.alt} src={props.image.src} />
          </div>
          <div className="mt-5 text-jungle text-center font-weight-bold">
            {props.title}
          </div>
          <div className="mt-4 text-center">
            <div className="d-flex justify-content-center">
              <div className="text-gray-60 w-75 font-size-sm">
                <span id="error-notice-body-text">{props.bodyText}</span>
                {!props.hideContact && (
                  <span>
                    {' '}
                    If the problem persists, please contact{' '}
                    <a href="mailto: help@polco.us">help@polco.us</a>
                  </span>
                )}
              </div>
            </div>
            <Btn
              action={props.continueButton.action}
              className="mt-5 mb-3 w-75"
              type={ButtonTypes.PRIMARY}
            >
              {props.continueButton.title}
            </Btn>
          </div>
        </div>
      </div>
    </section>
  );
};

//action={() => redirect(props.continueButton.route, { push: true })}
