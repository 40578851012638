import { Modal } from 'client/shared/components/modal';
import * as React from 'react';
import { SHARE_MODAL_COPY } from './copy';
import {
  ButtonTypes,
  Btn,
  MaterialIconName,
  MaterialIcon,
} from 'client/shared/components/base';
import { LoadedEvents } from 'client/shared/core/types';
import { copyContentToClipboard } from 'client/shared/core/helpers';
import facebookImage from './assets/facebook.svg';
import twitterImage from './assets/twitterX.svg';
import { SocialButton } from '../social-button';

interface ShareModalProps {
  readonly url: string;
  readonly events: {
    readonly facebook: {
      readonly share: () => void;
    };
    readonly twitter: {
      readonly share: () => void;
    };
    readonly cancel: LoadedEvents['cancelInteraction'];
  };
  readonly isOpen: boolean;
}

export const ShareModal: React.FC<ShareModalProps> = (props) => {
  const [copied, setCopied] = React.useState(false);
  const { url, isOpen } = props;

  const handleCopyLink = () => {
    copyContentToClipboard(url);
    setCopied(true);

    setTimeout(() => {
      setCopied(false); // Revert the label back to "Copy URL" after 5 seconds
    }, 5000);
  };

  return (
    <Modal
      bodyClassName="p-4"
      className="d-flex justify-center align-items-center h-100"
      displayIcon={MaterialIconName.SHARE}
      header={{ title: '', bsClassName: 'px-4' }}
      isCloseable
      isOpen={isOpen}
      onRequestClose={props.events.cancel}
      size="md"
    >
      <h2 className="text-gray-60 font-size-xl font-weight-bold mb-0">
        {SHARE_MODAL_COPY.title}
      </h2>

      <div className="mt-4">
        <div className="font-size-sm">{SHARE_MODAL_COPY.description}</div>
      </div>

      <div className="mt-4">
        <div className="font-size-sm font-weight-bold">
          {SHARE_MODAL_COPY.shareableUrl}
        </div>

        <span
          className="d-flex w-100 font-size-xs bg-gray-6 border-gray-10 p-2 mt-2"
          placeholder={url}
        >
          {url}
        </span>
        <div className="mt-2">
          {copied ? (
            <p className="font-size-sm">{SHARE_MODAL_COPY.copiedURL}</p>
          ) : (
            <Btn
              action={handleCopyLink}
              className="font-weight-bold"
              type={ButtonTypes.LINK}
            >
              <MaterialIcon className="mr-1" icon={MaterialIconName.FILE_COPY} />
              {SHARE_MODAL_COPY.copyURL}
            </Btn>
          )}
        </div>
      </div>

      <div className="mt-4">
        <div className="font-size-sm font-weight-bold">
          {SHARE_MODAL_COPY.shareVia}
        </div>

        <div className="mt-2">
          <SocialButton
            action={() => {
              props.events.facebook.share();
              props.events.cancel();
            }}
            cta={SHARE_MODAL_COPY.shareOnFaceBook}
            image={{ alt: 'share via Facebook', src: facebookImage }}
          />
        </div>

        <div className="mt-2">
          <SocialButton
            action={() => {
              props.events.twitter.share();
              props.events.cancel();
            }}
            cta={SHARE_MODAL_COPY.shareOnTwitter}
            image={{ alt: 'share via X', src: twitterImage }}
          />
        </div>
      </div>

      <div className="d-flex justify-content-center mt-2 mb-2">
        <Btn
          action={() => {
            props.events.cancel();
          }}
          className="text-gray-50 border-gray-10 w-100 p-2"
          type={ButtonTypes.SECONDARY}
        >
          {SHARE_MODAL_COPY.cancel}
        </Btn>
      </div>
    </Modal>
  );
};
ShareModal.displayName = 'ShareModal';
