import { sanitizePercentageValue } from 'client/shared/core/performance-data';
import * as React from 'react';
import './styles.scss';

export interface Props {
  readonly className?: string;
  readonly value: number;
}

const baseClass = 'pn-simple-progress-bar';

export const SimpleProgressBar: React.FC<Props> = (p) => {
  const { value: initialValue } = p;
  const value = sanitizePercentageValue(initialValue);
  const [width, setWidth] = React.useState<number>(0);
  React.useEffect(() => setWidth(value), [value]);
  return (
    <div className={`${baseClass}-container ${p.className ?? ''}`}>
      <div
        className={`${baseClass}-fill bg-liberty`}
        style={{ width: `${width || 0}%` }}
      ></div>
    </div>
  );
};
