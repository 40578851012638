import { SafeDictionary, Result } from './helpers';
import _ from 'lodash';

// We run the risk of looping infinitely when executing
// this code. This is an escape hatch to make sure we never hang the users
// page.
const MAX_CONDITION_DEPTH = 1000;
export interface MetaCondition {
  readonly id: string;
  readonly combinationOperator: CombinationType;
  readonly displayName: string;
  readonly childMetaConditionIds: readonly string[];
}

export enum CombinationType {
  ALL = 'ALL',
  NONE = 'NONE',
  SOME = 'SOME',
  NOT_ALL = 'NOT_ALL',
}

export const ReadableCombinationType: Record<CombinationType, string> = {
  [CombinationType.ALL]: 'all',
  [CombinationType.NONE]: 'none',
  [CombinationType.SOME]: 'any',
  [CombinationType.NOT_ALL]: 'not all',
};

export interface QuestionChoiceCondition {
  readonly id: string;
  readonly metaConditionId: string;
  readonly questionId: string;
  readonly questionChoiceId: string;
}

export interface QuestionChoiceInput {
  readonly questionId: string;
  readonly questionChoiceIds: readonly string[];
}

export enum ConditionOutput {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  INCONCLUSIVE = 'INCONCLUSIVE',
}

export enum ConditionalLogicExecutionError {
  MISSING_ROOT_META_CONDITION = 'MISSING_ROOT_META_CONDITION',
  INFINITE_LOOP_DETECTED = 'INFINITE_LOOP_DETECTED',
  CONDITION_TREE_FAILED_TO_CONVERGE = 'CONDITION_TREE_FAILED_TO_CONVERGE',
}

export function executeConditionalLogic(
  conditions: {
    readonly metaConditions: readonly MetaCondition[];
    readonly questionChoiceConditions: readonly QuestionChoiceCondition[];
  },
  inputs: {
    readonly questionChoices: readonly QuestionChoiceInput[];
    // If/When we add more condition types, they should be put here
  }
): Result.Type<ConditionOutput, ConditionalLogicExecutionError> {
  // Find the root condition. It is the condition that is never referenced by a child.
  // We need this because the final result is based on the value of the root condition
  const rootCondition = findRootCondition(conditions.metaConditions);
  if (!rootCondition) {
    return Result.failure(
      ConditionalLogicExecutionError.MISSING_ROOT_META_CONDITION
    );
  }

  // make the question choice input nicer to work with
  const choicesByQuestionId: SafeDictionary<QuestionChoiceInput> = _.keyBy(
    inputs.questionChoices,
    (questionChoice) => questionChoice.questionId
  );

  // Run through all the questionChoiceConditions and evaluate them. At the end of this
  // reduce we will have all the results grouped by meta condition id
  const questionChoiceResults = conditions.questionChoiceConditions.reduce(
    (acc, condition) => {
      const inputValue = choicesByQuestionId[condition.questionId];

      return {
        ...acc,
        [condition.metaConditionId]: [
          ...(acc[condition.metaConditionId] ?? []),
          inputValue
            ? inputValue.questionChoiceIds.includes(condition.questionChoiceId)
              ? ConditionOutput.SUCCESS
              : ConditionOutput.FAIL
            : ConditionOutput.INCONCLUSIVE,
        ],
      };
    },
    {} as {
      readonly [metaConditionId: string]: readonly ConditionOutput[] | undefined;
    }
  );

  // Now that we have (1) all base conditions evaluated, run through the meta conditions evaluating them.
  // It will take an unknown number of iterations to solve all the meta conditions.
  // While we are solving conditions, we will save the values in this object
  const intermediateMetaConditionResults: {
    [metaConditionId: string]: ConditionOutput | undefined;
  } = {};

  // The strategy here is to find what conditions we are able to evaluate (we can evaluate a condition if we have condition
  // results for all dependencies), evaluate them, and then repeat. We are done when there are no more conditions that we can
  // evaluate
  let evaluatableConditions: readonly MetaCondition[] = [];
  let iterations = 0;
  do {
    evaluatableConditions = conditions.metaConditions.filter(
      (metaCondition) =>
        !intermediateMetaConditionResults[metaCondition.id] && // We have not already solved this condition
        metaCondition.childMetaConditionIds.filter(
          (dependency) => !intermediateMetaConditionResults[dependency]
        ).length === 0 // This condition has no unsolved dependencies
    );

    // Solve each of our evaluatable conditions, and save the result off to intermediateMetaConditionResults
    evaluatableConditions.forEach((evaluatableCondition) => {
      const metaDependencyValues = _.compact(
        evaluatableCondition.childMetaConditionIds.map(
          (dep) => intermediateMetaConditionResults[dep]
        )
      );
      intermediateMetaConditionResults[evaluatableCondition.id] = evaluate(
        evaluatableCondition.combinationOperator,
        [
          ...(questionChoiceResults[evaluatableCondition.id] ?? []),
          ...metaDependencyValues,
        ]
      );
    });
    iterations++;
  } while (
    evaluatableConditions.length > 0 &&
    iterations < MAX_CONDITION_DEPTH // A trap door to make sure we never infinite loop
  );

  if (iterations >= MAX_CONDITION_DEPTH) {
    return Result.failure(ConditionalLogicExecutionError.INFINITE_LOOP_DETECTED);
  }

  // Typing allows root value to be undefined, and in theory there could
  // be a set of conditions that didn't converge to a value for root. So
  // we do handle that case with a custom failure value. That said,
  // In the current implementation I know of no way that a root could
  // not converge (even a disconnected root will still evaluate)
  const rootValue = intermediateMetaConditionResults[rootCondition.id];

  return rootValue
    ? Result.success(rootValue)
    : Result.failure(
        ConditionalLogicExecutionError.CONDITION_TREE_FAILED_TO_CONVERGE
      );
}

function evaluate(
  operator: CombinationType,
  conditions: readonly ConditionOutput[]
) {
  switch (operator) {
    case CombinationType.ALL:
      return all(conditions);
    case CombinationType.NONE:
      return none(conditions);
    case CombinationType.NOT_ALL:
      return not(all(conditions));
    case CombinationType.SOME:
      return not(none(conditions));
  }
}

function all(conditions: readonly ConditionOutput[]) {
  return conditions.filter((result) => result !== ConditionOutput.SUCCESS).length ===
    0
    ? ConditionOutput.SUCCESS
    : conditions.some((result) => result === ConditionOutput.FAIL)
    ? ConditionOutput.FAIL
    : ConditionOutput.INCONCLUSIVE;
}

function none(conditions: readonly ConditionOutput[]) {
  return conditions.filter((result) => result !== ConditionOutput.FAIL).length === 0
    ? ConditionOutput.SUCCESS
    : conditions.some((result) => result === ConditionOutput.SUCCESS)
    ? ConditionOutput.FAIL
    : ConditionOutput.INCONCLUSIVE;
}

function not(condition: ConditionOutput) {
  return condition === ConditionOutput.FAIL
    ? ConditionOutput.SUCCESS
    : condition === ConditionOutput.SUCCESS
    ? ConditionOutput.FAIL
    : ConditionOutput.INCONCLUSIVE;
}

export function findRootCondition<
  T extends {
    readonly id: string;
    readonly childMetaConditionIds: readonly string[];
  }
>(conditions: readonly T[]): T | null {
  // To find the "root" condition we need to find the meta condition that is not reference by a subrule
  const allChildrenIds = new Set(
    _.flatten(conditions.map((metaCondition) => metaCondition.childMetaConditionIds))
  );

  return conditions.find((condition) => !allChildrenIds.has(condition.id)) ?? null;
}
