import {
  PublishingEntityActivationState,
  PublishingEntityAssetType,
  PublishingEntityApprovalDecision,
  CurrentAdmin,
} from 'client/shared/graphql-client/graphql-operations.g';
import { ClientPublishingEntityId } from 'client/shared/core/publishing-entity';
import {
  Coordinate,
  Case,
  EmailVerificationStatus,
  unionOfEnum,
  Flavor,
  AdminRole,
  ExtractGql,
} from 'core';
import { FeatureSetting } from '../core/feature-settings';
import { MultiPolygon } from '@turf/helpers';
import { ClientGeoViewId } from 'client/shared/core/geo';
import { UIStoreItemWithStatus } from '../core/premium';

type PrivateAdmin = ExtractGql<
  NonNullable<NonNullable<CurrentAdmin['currentUser']['user']>['admin']>,
  'PrivateAdmin'
>;
export type BalancingActCustomer = NonNullable<
  PrivateAdmin['activePublishingEntity']
>['balancingActCustomer'];

export interface GeoView {
  readonly id: ClientGeoViewId;
  readonly name: string;
}

export interface ActiveLiveEvents {
  readonly id: string;
  readonly slug: string | null;
}

export interface AdminPublisher {
  readonly id: ClientPublishingEntityId;
  readonly name: string;
  readonly presidingArea: {
    readonly name: string | null;
    readonly center: {
      readonly size: number;
      readonly coordinate: Coordinate;
    };
  } | null;
  readonly defaultGeoView: GeoView;
  readonly availableGeoViews: readonly GeoView[];
  readonly slug: string;
  readonly activationState: PublishingEntityActivationState;
  readonly approvalDecision: PublishingEntityApprovalDecision;
  readonly description: string;
  readonly postedBy: string;
  readonly fips: string | null;
  readonly balancingActCustomer: BalancingActCustomer;
  readonly showExampleQuestions: boolean;
  readonly assets: readonly {
    readonly id: string;
    readonly url: string;
    readonly type: PublishingEntityAssetType;
  }[];
  readonly featureSettings: readonly FeatureSetting[];
  readonly systemType: string | null;
  readonly activeLiveEvents: readonly ActiveLiveEvents[];
  readonly isEnterprise: boolean;
  readonly storeTiers: readonly UIStoreItemWithStatus[];
}

export interface Admin {
  readonly id: AdminId;
  readonly createdAt: Date;
  readonly superAdmin: boolean;
  readonly registrationRequests: PrivateAdmin['registrationRequests'];
  readonly activePublishingEntity: AdminPublisher;
  readonly name: string;
  readonly emailVerificationStatus: EmailVerificationStatus;
  readonly permissions: readonly string[];
  readonly isEnvisioUser: boolean;
  readonly demoPollyUrl: string | null;
  readonly menuItems: PrivateAdmin['menuItems'];
}

export type AdminId = Flavor<string, 'admin'>;

export interface ClientAdminRole extends AdminRole {
  readonly numOfActiveRoles: number;
}

export interface LoadedAdminData {
  readonly admin: Admin;
  readonly email: string | null;
  readonly userId: string;
}

export enum AdminState {
  LOADING = 'LOADING',
  NOT_ADMIN = 'NOT_ADMIN',
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
  NO_PUBLISHER_PERMISSIONS = 'NO_PUBLISHER_PERMISSIONS',
  INCOMPLETE_PUB_PROFILE_ADMIN = 'INCOMPLETE_PUB_PROFILE_ADMIN',
  PENDING_PUB_ACTIVATION_ADMIN = 'PENDING_PUB_ACTIVATION_ADMIN',
  PENDING_APPROVAL_ADMIN = 'PENDING_APPROVAL_ADMIN',
  PENDING_PUB_ADMIN = 'PENDING_PUB_ADMIN',
  PENDING_EMAIL_VERIFICATION_ADMIN = 'PENDING_EMAIL_VERIFICATION_ADMIN',
  ADMIN = 'ADMIN',
  ERROR = 'ERROR',
  REJECTED = 'REJECTED',
}

export type StandardAdminData =
  | Case<AdminState.ADMIN, LoadedAdminData>
  | Case<AdminState.PENDING_APPROVAL_ADMIN, LoadedAdminData>
  | Case<AdminState.INCOMPLETE_PUB_PROFILE_ADMIN, LoadedAdminData>
  | Case<AdminState.PENDING_PUB_ACTIVATION_ADMIN, LoadedAdminData>
  | Case<AdminState.PENDING_EMAIL_VERIFICATION_ADMIN, LoadedAdminData>;

export type AdminData =
  | StandardAdminData
  | Case<AdminState.LOADING>
  | Case<AdminState.NOT_LOGGED_IN>
  | Case<AdminState.NO_PUBLISHER_PERMISSIONS>
  | Case<AdminState.REJECTED>
  | Case<
      AdminState.NOT_ADMIN,
      {
        readonly email: string | null;
        readonly userId: string;
        readonly name?: string;
      }
    >
  | Case<AdminState.PENDING_PUB_ADMIN, { readonly registrationId: string }>
  | Case<AdminState.ERROR>;

export const AdminDataBuilder = () => {
  return unionOfEnum(AdminState, { ...AdminState }).andType<AdminData>();
};
export const AdminData = AdminDataBuilder();

export function isStandardAdminData(
  adminData: AdminData
): adminData is StandardAdminData {
  return (
    adminData.type === AdminState.ADMIN ||
    adminData.type === AdminState.PENDING_APPROVAL_ADMIN ||
    adminData.type === AdminState.INCOMPLETE_PUB_PROFILE_ADMIN ||
    adminData.type === AdminState.PENDING_PUB_ACTIVATION_ADMIN ||
    adminData.type === AdminState.PENDING_EMAIL_VERIFICATION_ADMIN
  );
}

export interface MapData {
  readonly questionMapStats: {
    readonly hits: number;
    readonly buckets: readonly MapStatBucketGroup[];
    readonly center: CoordinateCenter;
  };
  readonly mapStatsStatus: MapStatsStatus;
}

export enum MapStatsStatus {
  NOT_ENOUGH_DATA = 'NOT_ENOUGH_DATA',
  NOT_ENABLED = 'NOT_ENABLED',
  ENABLED = 'ENABLED',
}

export interface CoordinateCenter {
  readonly coordinate: Coordinate;
  readonly size: number;
}

export interface MapStatBucketGroup {
  readonly key: string;
  readonly hits: number;
  readonly buckets: readonly StatBucket[] | null;
  readonly shape: MultiPolygon;
}
export interface StatBucket {
  readonly key: string;
  readonly hits: number;
}
