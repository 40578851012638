export const DOMAIN_CONTAINER_COPY = {
  communityStatistics: {
    header: 'Community Statistics',
    CTA: {
      expand: 'Expand All',
      collapse: 'Collapse All',
    },
    noResults: {
      header: 'No results found',
      footer: {
        main: 'Please check back later. If the issue persists, get in touch with support at ',
        email: 'success@polco.us',
      },
    },
  },
  indexModal: {
    external: {
      main: 'Some indicators are not included in the index score calculation.',
      button: 'More Information',
    },
    internal: {
      title: (domainName: String) => `${domainName} Domain`,
      explanation:
        'Certain indicators in the dataset do not have a clear “directionality”, meaning we do not believe a higher or lower value equates to better or worse performance.',
      indciators:
        "The following indicators are not included in this domain's index score calculation:",
      headers: {
        resident: 'Resident Sentiment',
        community: 'Community Statistics',
      },
    },
  },
  residentSentiment: {
    header: 'Resident Sentiment',
  },
  libraryBanner: {
    header: 'Data Collection',
    button: 'Polco Library',
    body: (domainName: string) =>
      `Dig deeper into community sentiment — use specialized Polco Library content to post surveys to gather additional ${domainName}-related insights from community members.`,
  },
  activeVisualizations: (max: number) =>
    `You have exceeded your maximum number of ${max} active saved datapoints. Set some to inactive to make room for more.`,
};
