import * as React from 'react';
import { useClock } from './use-clock';
import { Props as WellProps } from '../components/base/well';
import {
  SnackbarNotification,
  Props as SnackbarProps,
  SnackbarPositions,
} from '../components/snackbar-notification';

export interface SnackbarMessageProps
  extends WellProps,
    Omit<SnackbarProps, 'position'> {
  readonly position?: SnackbarPositions;
}

const DEFAULT_TIMEOUT = 5000;

export function useSnackbarMessage(): readonly [
  React.FC,
  (msg: SnackbarMessageProps) => void
] {
  const [props, setProps] = React.useState<SnackbarMessageProps | null>(null);
  const clock = useClock();

  function setSnackbarMessage(msg: SnackbarMessageProps) {
    setProps(msg);
    clock.setTimeout(() => setProps(null), msg.timeMs ?? DEFAULT_TIMEOUT);
  }

  const snackbarMessage: React.FC = () =>
    props ? (
      <SnackbarNotification
        {...props}
        position={props.position ?? SnackbarPositions.BOTTOM_RIGHT}
      />
    ) : null;

  return [snackbarMessage, setSnackbarMessage];
}
