import * as React from 'react';
import './styles.scss';
import { MaterialIcon, MaterialIconName } from '../material-icon';

export const PremiumIcon: React.FC = () => (
  <MaterialIcon
    className="pn-premium-icon text-jungle px-2"
    icon={MaterialIconName.STARS}
  />
);
