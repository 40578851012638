import * as React from 'react';
import {
  MaterialIcon,
  MaterialIconName,
  EventPropagationStopper,
} from 'client/shared/components/base';
import { Dropdown as BsDropdown } from 'react-bootstrap';
import './styles.scss';
import { sanitizeElementSelector } from 'client/shared/core/helpers';

export interface Option {
  readonly action: () => void | Promise<any>;
  readonly disabled?: boolean;
  readonly label: string;
  readonly danger?: boolean;
  readonly icon?: MaterialIconName;
  readonly description?: string;
}

interface Props {
  readonly className?: string;
  readonly options: readonly Option[];
  readonly toggle?: {
    readonly icon: MaterialIconName;
    readonly className?: string;
  };
}

export const baseClass = 'pn-option-list-menu';
export const menuToggleClassName = `${baseClass}-action-toggle`;

export const generateOptionListId = (optionLabel: string) =>
  sanitizeElementSelector(`${baseClass}-item_${optionLabel.toLowerCase()}`);

export const OptionListMenu: React.FC<Props> = (p) => {
  return (
    <BsDropdown className={`${p.className ?? ''}`}>
      <EventPropagationStopper>
        <BsDropdown.Toggle
          className={`${menuToggleClassName} p-0 m-0 d-flex align-items-center justify-content-center ${
            p.toggle?.className ?? ''
          }`}
          variant="link"
        >
          <MaterialIcon
            className={`${baseClass}-action-toggle-icon`}
            icon={p.toggle?.icon ?? MaterialIconName.MORE_VERT}
          />
        </BsDropdown.Toggle>
      </EventPropagationStopper>
      <BsDropdown.Menu
        alignRight
        className={`${baseClass}-menu py-1 rounded border-gray-30`}
      >
        {p.options.map((option, index) => {
          return (
            <EventPropagationStopper key={`${index}-${option.label}`}>
              <BsDropdown.Item
                className={`${baseClass}-menu-item py-2 px-4 ${
                  option.danger ? 'danger' : ''
                }`}
                disabled={option.disabled}
                id={generateOptionListId(option.label)}
                onSelect={option.action}
              >
                <div
                  className={`${baseClass}-item d-flex font-size-sm flex-column text-wrap`}
                >
                  <div className="d-flex flex-row">
                    {option.icon && (
                      <MaterialIcon className={`mr-3`} icon={option.icon} />
                    )}
                    <span className={`${baseClass}-item-label`}>{option.label}</span>
                  </div>
                  {option.description && (
                    <div className={`${baseClass}-item-description`}>
                      {option.description}
                    </div>
                  )}
                </div>
              </BsDropdown.Item>
            </EventPropagationStopper>
          );
        })}
      </BsDropdown.Menu>
    </BsDropdown>
  );
};
