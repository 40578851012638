import {
  IdeaInteractionAction,
  VoteType,
} from 'client/shared/graphql-client/graphql-operations.g';
import { ApiDate } from 'core';
import moment from 'moment';

export const IDEAS_TITLE_LIMIT = 70;

export function shortenIdeaTitle(title: string) {
  return title.length > IDEAS_TITLE_LIMIT
    ? `${title.substring(0, IDEAS_TITLE_LIMIT)}...`
    : title;
}

export function timeAgo(date: { readonly raw: number } | Date): string {
  if (date instanceof Date) {
    return moment(date).fromNow();
  }

  return moment(ApiDate.fromApi(date)).fromNow();
}

export function voteTypeToIdeaInteractionAction(voteType: VoteType) {
  switch (voteType) {
    case VoteType.NONE:
      return IdeaInteractionAction.REMOVE_VOTE;
    case VoteType.DOWN:
      return IdeaInteractionAction.VOTE_DOWN;
    case VoteType.UP:
      return IdeaInteractionAction.VOTE_UP;
    default:
      throw new Error(`Invalid vote type ${voteType}`);
  }
}
