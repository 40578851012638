import * as React from 'react';
import ReactWordcloud, { Word, Props as ReactWordcloudProps } from 'react-wordcloud';

import { loadingBarHexColorsAccessible } from 'client/shared/core/colors';
import { useDeepMemoObject } from 'client/shared/hooks';
import { maybeMap, toMutable } from 'core';

export interface Props {
  readonly height: number;
  readonly width: number;
  readonly maxWords?: number;
  readonly words: readonly Word[];
  readonly colors?: readonly string[];

  // IMPORTANT: given font sizes affects the rendering time of the word
  // cloud because of the algorithm used by d3-cloud. If drawing times become higher,
  // either sizes or height/width must be tuned for faster results
  readonly fontSizes?: readonly [number, number];
}

const MIN_SIZE: readonly [number, number] = [200, 100];
const FONT_SIZES: readonly [number, number] = [14, 40];
const FONT_FAMILY = '"Open Sans", sans-serif';
const DEFAULT_PALETTE = loadingBarHexColorsAccessible;

export const WordCloud: React.FC<Props> = (p) => {
  const wcProps = useDeepMemoObject<ReactWordcloudProps>({
    words: toMutable(p.words),
    maxWords: p.maxWords,
    options: {
      colors: maybeMap(p.colors, toMutable) ?? undefined,
      deterministic: true,
      fontFamily: FONT_FAMILY,
      fontSizes: p.fontSizes as [number, number] | undefined,
      fontWeight: 'bold',
      padding: 2,
      rotations: 2,
      rotationAngles: [0, 0],
      scale: 'log',
      transitionDuration: 0,
    },
    size: [p.width, p.height],
    minSize: MIN_SIZE as [number, number],
  });

  if (process.env.NODE_ENV === 'test') {
    return <div>{p.words.map((w) => w.text).join(' / ')}</div>;
  }
  return <ReactWordcloud {...wcProps} />;
};

WordCloud.defaultProps = {
  colors: DEFAULT_PALETTE,
  fontSizes: FONT_SIZES,
  maxWords: 20,
};
