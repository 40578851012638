import * as React from 'react';
import './styles.scss';
import {
  BtnLink,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { CtaLinkType, CtaLinkProps } from 'core';
import polcoLibraryLogo from 'client/assets/polco_library_logo.svg';

export interface ContentProps {
  readonly blockType: BlockType;
  readonly className?: string;
  readonly ctaProps: CtaLinkProps;
  readonly dismiss?: () => void;
  readonly textContent: string;
  readonly title: string;
}

export enum BlockType {
  NCS_BENCHMARK = 'NCS_BENCHMARK',
  POLCO_LIBRARY = 'POLCO_LIBRARY',
  POLCO_CALLOUT = 'POLCO_CALLOUT',
}

export const DismissableContainerCopy = {
  dismiss: 'Dismiss',
};

const baseClass = 'pn-cta-polco-site-content';

export const CTAPolcoSiteContent: React.FC<ContentProps> = (p) => {
  const {
    blockType,
    className,
    ctaProps: { text: textLink, type, link },
    textContent,
    title,
    dismiss,
  } = p;
  const isPolcoLibrary = blockType === BlockType.POLCO_LIBRARY;
  const isPolcoCallout = blockType === BlockType.POLCO_CALLOUT;

  const getIcon = (bType: BlockType) => {
    switch (bType) {
      case BlockType.POLCO_LIBRARY:
        return (
          <div className={`${baseClass}-title-icon-library`}>
            <img alt="Polco Library Logo" height="100%" src={polcoLibraryLogo} />
          </div>
        );

      case BlockType.NCS_BENCHMARK:
        return (
          <MaterialIcon
            className={`${baseClass}-title-icon-ncs font-size-lg`}
            icon={MaterialIconName.WARNING}
          />
        );

      case BlockType.POLCO_CALLOUT:
        return null;
    }
  };

  return (
    <div
      className={`${baseClass}-container ${className} ${
        isPolcoLibrary || isPolcoCallout ? 'bg-polco-green-xl' : 'bg-canary-xl'
      }
      ${!(isPolcoLibrary || isPolcoCallout) ? `${baseClass}-ncs-container` : ''}
      ${isPolcoCallout ? `${baseClass}-callout-container` : ''}`}
    >
      {isPolcoLibrary && (
        <div className={`${baseClass}-large-icon`}>
          <img alt="Polco Library Logo" src={polcoLibraryLogo} />
        </div>
      )}
      <div>
        <div className={`d-flex align-items-center mb-2`}>
          {getIcon(blockType)}
          <span
            className={`font-weight-bold font-size-lg ${
              isPolcoLibrary
                ? 'text-brand-d'
                : isPolcoCallout
                ? 'text-gray-60'
                : 'text-canary-xd'
            }`}
          >
            {title}
          </span>
        </div>
        <div
          className={`${baseClass}-content font-size-base font-weight-normal text-gray-60`}
        >
          {textContent}
        </div>
        <div className="d-flex flex-wrap">
          <BtnLink
            className={isPolcoCallout ? 'text-jungle' : ''}
            customPadding={isPolcoCallout ? 'py-2 px-0' : ''}
            target={type === CtaLinkType.EXTERNAL ? '_blank' : ''}
            to={link}
            type={isPolcoCallout ? ButtonTypes.SEAMLESS : ButtonTypes.SECONDARY}
          >
            {isPolcoLibrary && (
              <MaterialIcon
                className="text-jungle mr-1"
                icon={MaterialIconName.LOCAL_LIBRARY}
              />
            )}
            {textLink}
          </BtnLink>
          {dismiss && (
            <button
              className="btn btn-link font-weight-bold text-jungle py-2 px-3 ml-3"
              onClick={dismiss}
              type="button"
            >
              {DismissableContainerCopy.dismiss}
            </button>
          )}
        </div>
      </div>
      {dismiss && (
        <button
          aria-label="Close"
          className="close ml-auto align-self-start"
          onClick={dismiss}
          type="button"
        >
          <MaterialIcon
            className={`${baseClass}-close-icon`}
            icon={MaterialIconName.CLOSE}
          />
        </button>
      )}
    </div>
  );
};
