import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import Graphic from '@arcgis/core/Graphic';
import Color from '@arcgis/core/Color';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import Polygon from '@arcgis/core/geometry/Polygon';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import Renderer from '@arcgis/core/renderers/UniqueValueRenderer';
import LabelClass from '@arcgis/core/layers/support/LabelClass';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import Sketch from '@arcgis/core/widgets/Sketch';
import { MultiPolygon } from '@turf/helpers';
import { toWgs84 } from '@turf/projection';
import { compact, flatten } from 'lodash';

import {
  COLORS_AQUA,
  COLORS_CANARY,
  COLORS_JUNGLE,
  COLORS_POLCO_GREEN,
  COLORS_ROYAL_PURPLE,
  RGBColor,
} from 'client/shared/core/colors';
import {
  CoordinateCenter,
  MapData,
  MapStatBucketGroup,
  MapStatsStatus,
  StatBucket,
} from 'client/admin/shared/types';
import {
  AdminComparisonGroupFipsShapes,
  AdminContentSetRespondentsStatsLocation,
  AdminDashboardLocation,
} from 'client/shared/graphql-client/graphql-operations.g';
import { getObjectKeys, ExtractGql, MapExtentBounds } from 'core';

type GqlMultiPolygon = Omit<
  ExtractGql<
    NonNullable<
      ExtractGql<
        NonNullable<AdminContentSetRespondentsStatsLocation['openContentSetById']>,
        'Survey'
      >['locationBreakdown']
    >,
    'StatBreakdownLocation'
  >['values'][0]['shape'],
  '__typename'
>;

type GqlBoundingBox = ExtractGql<
  NonNullable<AdminComparisonGroupFipsShapes['openComparisonGroupById']>,
  'ComparisonGroup'
>['boundingBox'];
type LocationBreakdown = ExtractGql<
  NonNullable<
    NonNullable<
      AdminDashboardLocation['openPublishingEntityById']
    >['locationBreakdown']
  >,
  'StatBreakdownLocation'
>;

export interface DisplayMapStatBucketGroup extends MapStatBucketGroup {
  readonly color: RGBColor;
  readonly opacity: number;
  readonly winingBucketKey: StatBucket['key'] | null;
}

/**
 * Resolves a new ArcGIS map with a center based on the provided data and a
 * default zoom level of 12
 * @param mapData The current map data set
 */
export function createMap(center?: CoordinateCenter): MapView {
  const esriMap = new Map({
    basemap: 'streets-navigation-vector',
  });

  return new MapView({
    container: 'viewDiv',
    map: esriMap,
    zoom: center?.size,
    center: {
      type: 'point',
      latitude: center?.coordinate.lat,
      longitude: center?.coordinate.lng,
    },
  });
}

export function transformLocationBreakdownToMapData(
  locationBreakdown: LocationBreakdown | null
): MapData | null {
  return locationBreakdown
    ? {
        mapStatsStatus: MapStatsStatus.ENABLED,
        questionMapStats: {
          ...locationBreakdown,
          hits: locationBreakdown.values.reduce(
            (acc: number, locationBreakdownValue) =>
              acc + locationBreakdownValue.total,
            0
          ),
          buckets: compact(
            locationBreakdown.values.map((locationBreakdownValue) => {
              const shape = transformGqlMultiPolygonToClientMultiPolygon(
                locationBreakdownValue.shape
              );
              return shape
                ? {
                    key: locationBreakdownValue.key,
                    hits: locationBreakdownValue.total,
                    buckets: null,
                    shape,
                  }
                : null;
            })
          ),
        },
      }
    : null;
}

export function transformGqlMultiPolygonToClientMultiPolygon(
  gqlMultiPolygon: GqlMultiPolygon
): MultiPolygon | null {
  if (gqlMultiPolygon.type === 'MultiPolygon') {
    return {
      type: 'MultiPolygon',
      // We could do this by calling toMutable for each depth of the nested coordinates array,
      // but as this is running on the client and this is a huge amount of data,
      // that is a lot of wasted compute for very little benefit
      coordinates: gqlMultiPolygon.coordinates as unknown as number[][][][],
    };
  } else {
    console.error(`Unexpected GeoJson type: ${gqlMultiPolygon.type}`);
    return null;
  }
}

export function transformGeoBoundingBoxToClientBounds(
  gqlBoundingBox?: GqlBoundingBox
): MapExtentBounds | undefined {
  return gqlBoundingBox
    ? {
        xMin: gqlBoundingBox.xMin,
        xMax: gqlBoundingBox.xMax,
        yMin: gqlBoundingBox.yMin,
        yMax: gqlBoundingBox.yMax,
      }
    : undefined;
}

/**
 * Uses the transformed map data and uses it to generate an array of map
 * graphics for the provided policy type.
 * @param buckets A collection of map data.
 */
export async function createGraphicsLayer(
  buckets: readonly DisplayMapStatBucketGroup[],
  generateContent: (bucket: DisplayMapStatBucketGroup) => string
): Promise<GraphicsLayer> {
  const graphics = flatten(
    buckets.map((bucket) => {
      const content = generateContent(bucket);
      return bucket.shape.coordinates.map((polygon) => {
        return new Graphic({
          symbol: new SimpleFillSymbol({
            color: new Color([...bucket.color, bucket.opacity]),
            outline: {
              color: new Color([...bucket.color, 1]),
              width: 1,
            },
          }),
          geometry: new Polygon({
            rings: polygon,
          }),
          popupTemplate: new PopupTemplate({
            content,
          }),
        });
      });
    })
  );

  const layer = new GraphicsLayer({
    id: 'graphic-layer',
    graphics,
  });

  return layer;
}

/**
 * @see https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-FeatureLayer.html
 * @see https://developers.arcgis.com/javascript/latest/sample-code/layers-featurelayer-collection/
 * TODO: When we make the sentiment map, we can move this color map to the appropriate place
 */
export async function createFeatureLayer(
  buckets: readonly DisplayMapStatBucketGroup[]
) {
  const colors: Record<string, RGBColor> = {
    green: COLORS_POLCO_GREEN,
    canary: COLORS_CANARY,
    jungle: COLORS_JUNGLE,
    aqua: COLORS_AQUA,
    purple: COLORS_ROYAL_PURPLE,
  };

  /**
   * @see https://developers.arcgis.com/javascript/latest/api-reference/esri-Graphic.html
   */
  const graphics = flatten(
    buckets.map((bucket, i) => {
      return bucket.shape.coordinates.map((polygon) => {
        // For Graphics generated for FeatureLayers, colors are determined by Renderer
        const colorNames = Object.keys(colors);
        // Attributes set here are accessible at higher levels
        return new Graphic({
          attributes: {
            id: i + 1,
            color: colorNames[i % colorNames.length],
          },
          geometry: new Polygon({
            rings: polygon,
          }),
        });
      });
    })
  );

  /**
   * @see https://developers.arcgis.com/javascript/latest/labeling/
   * @see https://developers.arcgis.com/javascript/latest/sample-code/labels-basic/
   * @see https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-support-LabelClass.html
   * @description ESRI webmap currently only supports 'always-horizontal' for Polygon geometries and can only be over the Polygon centroid
   *
   * I tried using the color map to maybe color the labels to help with the confusing placement but can't get it to work.
   */
  const labels = graphics.map(() => {
    return new LabelClass({
      allowOverrun: false,
      symbol: new TextSymbol({
        color: new Color([0, 0, 0, 0.7]),
        font: {
          family: 'Ubuntu Mono',
          size: 14,
        },
      }),
      labelExpressionInfo: {
        expression: '$feature.id',
      },
      labelPlacement: 'always-horizontal',
      maxScale: 0,
      minScale: 350_000,
    });
  });

  /**
   * @see https://developers.arcgis.com/javascript/latest/api-reference/esri-renderers-UniqueValueRenderer.html
   */
  const renderer = new Renderer({
    field: 'color',
    uniqueValueInfos: getObjectKeys(colors).map((colorName) => {
      return {
        value: colorName,
        symbol: new SimpleFillSymbol({
          color: new Color([...colors[colorName], 0.8]),
          outline: {
            color: new Color([...colors[colorName], 1]),
            width: 1,
          },
        }),
      };
    }),
  });

  /**
   * @see https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-FeatureLayer.html
   */
  const featureLayer = new FeatureLayer({
    id: 'feature-layer',
    fields: [
      { name: 'id', type: 'oid' },
      { name: 'color', type: 'string' },
    ],
    source: graphics,
    labelingInfo: labels,
    objectIdField: 'id',
    popupTemplate: new PopupTemplate({
      title: 'Area #{id}',
    }),
    renderer,
  });

  return featureLayer;
}

export async function addDrawTool(
  mapView: MapView,
  polygonUpdateCallback: (geometry: MultiPolygon) => Promise<any> | void
) {
  const layer = new GraphicsLayer({});
  const sketch = new Sketch({
    layer: layer,
    view: mapView,
    creationMode: 'update',
    availableCreateTools: ['polygon'],
  });

  const stateToMultiPolygon = async (e: { readonly state?: string }) => {
    if (!e.state || e.state === 'complete') {
      // Esri doesn't seem to provide any decent way to get the geometry data out of their sketch widget.
      // This is the most robust approach I could find. It pulls all the graphics, but unfortunately their
      // graphics are in Spacial Reference 900913(AKA 3857). We want things in Spatial Reference 4326, so
      // we have to do a little extra math to get the multipolygon we want
      const rawGeometryData = layer.graphics
        .toArray()
        .map((graphic) => graphic.geometry.toJSON()) as readonly {
        readonly rings: readonly (readonly (readonly [number, number])[])[];
      }[];
      const shape = rawGeometryData.map((polygon) =>
        polygon.rings.map((ring) =>
          ring.map((coord) => spacialReferenceTransform(coord))
        )
      );

      await polygonUpdateCallback({ type: 'MultiPolygon', coordinates: shape });
    }
  };

  sketch.on(
    ['create', 'update', 'delete', 'undo', 'redo'] as any, // The typing is slightly broken on Esri. This is correct via their docs (and functionality), but not via their types
    stateToMultiPolygon
  );

  mapView.map.layers.add(layer);

  mapView.ui.add(sketch, 'top-right');
}

/**
 * Converts from Spacial Reference WKID 900913(AKA 3857) to WKID 4326
 */
function spacialReferenceTransform([x, y]: readonly [number, number]) {
  return toWgs84([x, y]);
}
