import * as React from 'react';
import './styles.scss';
import { makeStyles, Tab, Tabs } from '@material-ui/core';
import { MaterialIcon, MaterialIconName } from '../material-icon';
import scrollIntoView from 'scroll-into-view-if-needed';
import uuid from 'uuid';
import { PolcoColor } from 'client/shared/core/colors-class';

export interface TabBarItem<T = string> {
  readonly key: T;
  readonly label: string;
  readonly icon?: MaterialIconName;
  readonly badge?: number;
}

export interface TabBarProps<T = string> {
  readonly tabs: ReadonlyArray<TabBarItem<T>>;
  readonly selected: TabBarItem<T>;
  readonly className?: string;
  readonly tabSelected: (tab: TabBarItem<T>) => void;
}

const useTabsStyles = makeStyles(() => ({
  root: {
    borderBottom: `1px solid ${new PolcoColor('Gray-20').hex}`,
    backgroundColor: '#fff',
  },
  indicator: {
    backgroundColor: `${new PolcoColor('Jungle').hex}`,
  },
}));

const useTabStyle = makeStyles(() => ({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
    '&:focus': {
      outlineColor: 'transparent',
    },
  },
}));

export const baseClass = 'pn-tab-bar';

export function TabBar<T extends string = string>(props: TabBarProps<T>) {
  const { current: tabBarId } = React.useRef(uuid.v4());
  const initialRenderRef = React.useRef(true);
  const [selectedTabLabel, setSelectedTabLabel] = React.useState(
    props.selected.label
  );

  React.useEffect(
    () => {
      if (initialRenderRef.current) {
        initialRenderRef.current = false;
        // We dont want to do anything because this is the very first render. We only want to scroll around when the tab changes,
        // not the first time the tab load
        return;
      }

      const tabBar = document.getElementById(tabBarId);
      if (tabBar) {
        scrollIntoView(tabBar, {
          scrollMode: 'if-needed',
          behavior: 'smooth',
        });
      }

      setSelectedTabLabel(props.selected.label);
    },
    [
      tabBarId,
      props.selected.label,
    ] /** This triggers anytime the tab change due to internal cause (i.e. a tab was clicked), or an external cause (i.e. the url changed) */
  );

  const tabsStyles = useTabsStyles();
  const tabStyles = useTabStyle();
  const tabs = props.tabs.map((tab, i) => {
    return (
      <Tab
        className={`${tabStyles.root} text-capitalize px-3`}
        key={i}
        label={
          <div className="d-flex align-items-center">
            {tab.icon && <MaterialIcon className="mr-1" icon={tab.icon} />}
            {tab.label}
            {tab.badge !== undefined && (
              <div className={`${baseClass}-badge`}>{tab.badge}</div>
            )}
          </div>
        }
        tabIndex={0}
        wrapped={false}
      />
    );
  });
  return (
    <div
      className={`${baseClass} ${props.className ?? ''} ${tabsStyles.root} mb-2`}
      id={tabBarId}
    >
      <Tabs
        classes={{ indicator: tabsStyles.indicator }}
        onChange={(_event, newSelection) => {
          props.tabSelected(props.tabs[newSelection]);
        }}
        scrollButtons="auto"
        value={props.tabs.findIndex((tab) => tab.key === props.selected.key)}
        variant="scrollable"
      >
        {tabs}
      </Tabs>

      <h2 className="sr-only">{selectedTabLabel}</h2>
    </div>
  );
}
TabBar.displayName = 'TabBar';
