import { take } from 'lodash';

export interface Name {
  readonly firstName: string | null;
  readonly lastName: string | null;
}

/**
 * A function that takes in a fullname and does its best to split it into first and last name.
 * @param name The full name being passed in.
 */
export function splitFullname(name: string = ''): Name {
  const names = name.split(' ');
  return {
    firstName: names[0] || null,
    lastName: names.slice(1).join(' ') || null,
  };
}

/**
 * Joins a name to return the fullname string.
 * @param name The name to be joined
 * @returns The string representation of the full name.
 */
export function getFullname(name: Name): string {
  return `${name.firstName || ''} ${name.lastName || ''}`.trim();
}

export const getInitials = (
  name: string,
  initialsToTake: number = 2,
  joinChar: string = ''
) =>
  take(name.split(' '), initialsToTake)
    .map((n) => n[0])
    .join(joinChar);
