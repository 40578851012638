import { Flavor } from 'core';

export type ClientPublishingEntityId = Flavor<string, 'publishing_entities'>;

export enum PublishingEntityAssetType {
  BACKGROUND = 'BACKGROUND',
  BANNER = 'BANNER',
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  PRIMARY_SIGNATURE = 'PRIMARY_SIGNATURE',
  SECONDARY_SIGNATURE = 'SECONDARY_SIGNATURE',
  MAILING_LOGO = 'MAILING_LOGO',
  GIS_PRIMARY = 'GIS_PRIMARY',
  GIS_SECONDARY = 'GIS_SECONDARY',
  PROFILE_COVER = 'PROFILE_COVER',
}

function getPubEntityAsset(
  assets: readonly {
    readonly type: PublishingEntityAssetType;
    readonly url: string;
  }[],
  type: PublishingEntityAssetType
): string | null {
  return assets.find((ast) => ast.type === type)?.url ?? null;
}
export function publishingEntityPrimaryLogoUrl(
  assets: readonly {
    readonly type: PublishingEntityAssetType;
    readonly url: string;
  }[]
): string | null {
  return getPubEntityAsset(assets, PublishingEntityAssetType.PRIMARY);
}

export function publishingEntityMailingLogoUrl(
  assets: readonly {
    readonly type: PublishingEntityAssetType;
    readonly url: string;
  }[]
): string | null {
  return getPubEntityAsset(assets, PublishingEntityAssetType.MAILING_LOGO);
}

export function publishingEntityPrimarySignatureUrl(
  assets: readonly {
    readonly type: PublishingEntityAssetType;
    readonly url: string;
  }[]
): string | null {
  return getPubEntityAsset(assets, PublishingEntityAssetType.PRIMARY_SIGNATURE);
}

export function publishingEntityProfileCoverUrl(
  assets: readonly {
    readonly type: PublishingEntityAssetType;
    readonly url: string;
  }[]
): string | null {
  return getPubEntityAsset(assets, PublishingEntityAssetType.PROFILE_COVER);
}

export function publishingEntitySecondarySignatureUrl(
  assets: readonly {
    readonly type: PublishingEntityAssetType;
    readonly url: string;
  }[]
): string | null {
  return getPubEntityAsset(assets, PublishingEntityAssetType.SECONDARY_SIGNATURE);
}

export function publishingEntityGisPrimaryUrl(
  assets: readonly {
    readonly type: PublishingEntityAssetType;
    readonly url: string;
  }[]
): string | null {
  return getPubEntityAsset(assets, PublishingEntityAssetType.GIS_PRIMARY);
}

export function publishingEntityGisSecondaryUrl(
  assets: readonly {
    readonly type: PublishingEntityAssetType;
    readonly url: string;
  }[]
): string | null {
  return getPubEntityAsset(assets, PublishingEntityAssetType.GIS_SECONDARY);
}
