import * as React from 'react';

/**
 * This is a simple element that stops propagation on keyboard/click events.
 * It is useful for when you need to stop an event for propagating, but you
 * do not control the event emitter (i.e. bootstrap elements)
 * @param p
 */
export const EventPropagationStopper: React.FC<{
  readonly children: React.ReactNode;
}> = (p) => {
  const propagationStopper = (
    e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => e.stopPropagation();

  return (
    <div onClick={propagationStopper} onKeyDown={propagationStopper} role="none">
      {p.children}
    </div>
  );
};
