import React from 'react';
import {
  Dropdown,
  MaterialIconName,
  MultiselectDropdown,
} from 'client/shared/components/base';
import './styles.scss';
import { TabBar } from 'client/shared/components/base/tab-bar';
import { AnalyticsSubdomain } from 'client/shared/graphql-client/graphql-operations.g';
import { subdomainLabels } from 'client/shared/core/track/labels';
import {
  PerformanceDatum,
  FullPerformanceDatum,
  AnalyticsVariable,
  TrackVariable,
} from 'core';
import _ from 'lodash';
import { RadioToggle } from 'client/shared/components/radio-toggle';
import { ShowGoalsState } from '../community-statistics-container';
import { Tooltip } from 'client/shared/components/tooltip';
import { KnownFlag, useFlagEnabled } from 'client/shared/contexts/flags-context';

export interface Props {
  // Full only because our parent only displays full indicators, so we won't accidentally
  // include null indicators when calculating e.g. badge numbers here.
  readonly variables: readonly AnalyticsVariable[];
  readonly selectedSubdomain: AnalyticsSubdomain | null;
  readonly setSelectedSubdomain: React.Dispatch<
    React.SetStateAction<AnalyticsSubdomain | null>
  >;
  readonly sortBy: SortByItem;
  readonly selectedDataSources: readonly DataSourceItem[];
  readonly setSortBy: React.Dispatch<React.SetStateAction<SortByItem>>;
  readonly setSelectedDataSources: React.Dispatch<
    React.SetStateAction<readonly DataSourceItem[]>
  >;
  readonly dataSources: readonly DataSourceItem[];
  readonly publisherDataAvailable: boolean;
  readonly showGoalsState: ShowGoalsState;
  readonly setShowGoalsState: (state: ShowGoalsState) => void;
  readonly showGoalsToggleDisabled?: boolean;
}

enum OrderTypes {
  DESC = 'desc',
  ASC = 'asc',
}

export interface SortByItem {
  readonly id: string;
  readonly name: string;
  readonly order: OrderTypes;
  readonly selector: (
    option: CommunityStatisticsDomainOption
  ) => Date | string | number | undefined;
}

export interface DataSourceItem {
  readonly id: string;
  readonly name: string;
}

export interface CommunityStatisticsDomainOption {
  readonly benchmarkNumber: number;
  readonly indicators: readonly PerformanceDatum[];
  readonly recentIndicator?: FullPerformanceDatum;
  readonly variable: TrackVariable;
  readonly recordedAt?: Date;
  readonly label?: string;
}

export const sortOptions = [
  {
    id: 'last_updated',
    name: 'Last Updated',
    order: OrderTypes.DESC,
    selector: (option: CommunityStatisticsDomainOption) => option?.recordedAt,
    requiresPubData: true,
  },
  {
    id: 'oldest_updated',
    name: 'Oldest Updated',
    order: OrderTypes.ASC,
    selector: (option: CommunityStatisticsDomainOption) => option?.recordedAt,
    requiresPubData: true,
  },
  {
    id: 'alphabetical_a_z',
    name: 'Alphabetical A-Z',
    order: OrderTypes.ASC,
    selector: (option: CommunityStatisticsDomainOption) => option.label,
    requiresPubData: false,
  },
  {
    id: 'alphabetical_z_a',
    name: 'Alphabetical Z-A',
    order: OrderTypes.DESC,
    selector: (option: CommunityStatisticsDomainOption) => option.label,
    requiresPubData: false,
  },
  {
    id: 'benchmark_performance_asc',
    name: 'Benchmark Performance Asc.',
    order: OrderTypes.ASC,
    selector: (option: CommunityStatisticsDomainOption) => option.benchmarkNumber,
    requiresPubData: true,
  },
  {
    id: 'benchmark_performance_desc',
    name: 'Benchmark Performance Desc.',
    order: OrderTypes.DESC,
    selector: (option: CommunityStatisticsDomainOption) => option.benchmarkNumber,
    requiresPubData: true,
  },
];

const baseClass = 'pn-filter-sort';

export const FilterSort: React.FC<Props> = (p) => {
  const goalsEnabled = useFlagEnabled(KnownFlag.TRACK_GOALS);
  const uniqueVariables = _.uniqBy(p.variables, (v) => v.id);
  const uniqueSubdomains = _.uniq(
    p.variables.flatMap((v) => _.compact(v.domains.map((d) => d.subdomain)))
  );
  const allSubdomainsTab = {
    key: 'ALL',
    label: 'All Subdomains',
    icon: undefined,
    badge: uniqueVariables.length,
  };
  const subdomainTabs = uniqueSubdomains.map((subdomain) => {
    const variablesInSubdomain = uniqueVariables.filter((v) =>
      v.domains.map((d) => d.subdomain).includes(subdomain)
    );
    return {
      key: subdomain.toString(),
      label: subdomainLabels[subdomain],
      icon: undefined,
      badge: variablesInSubdomain.length,
    };
  });

  const reset = () => {
    p.setSelectedDataSources([]);
    p.setSortBy(sortOptions[0]);
  };

  const dropdownClasses = `${baseClass}-dropdown-toggle font-size-base font-weight-normal text-gray-50`;

  return (
    <div className="d-flex flex-column">
      <TabBar
        selected={
          subdomainTabs.find((tab) => tab.key === p.selectedSubdomain) ??
          allSubdomainsTab
        }
        tabSelected={(tab) => {
          p.setSelectedSubdomain(
            tab.key === allSubdomainsTab.key ? null : (tab.key as AnalyticsSubdomain)
          );
          reset();
        }}
        tabs={[allSubdomainsTab].concat(subdomainTabs)}
      />
      <div className="d-flex flex-wrap align-items-center justify-content-between pb-4">
        <div className="d-flex flex-wrap align-items-center">
          <Dropdown
            className={`${baseClass}-sort-dropdown mr-3`}
            keySelect={(v) => v.id}
            labelSelect={(v) => v.name}
            labelSelectSelected={(v) => `Sort by: ${v.name}`}
            onChange={(v) => p.setSortBy(v)}
            options={sortOptions.filter(
              (option) => p.publisherDataAvailable || !option.requiresPubData
            )}
            prompt={``}
            promptIcon={MaterialIconName.SORT}
            toggleClassName={dropdownClasses}
            value={p.sortBy}
          />
          <MultiselectDropdown
            className={`${baseClass}-data-source-dropdown`}
            clearLabel="Clear selection"
            clearSection={() =>
              p.setSelectedDataSources ? p.setSelectedDataSources([]) : undefined
            }
            keySelect={(item) => item.id}
            labelSelect={(item) => item.name}
            onChange={(selected) => {
              if (!p.selectedDataSources.find(({ id }) => id === selected.id)) {
                p.setSelectedDataSources([...p.selectedDataSources, selected]);
              } else {
                p.setSelectedDataSources(
                  p.selectedDataSources.filter(({ id }) => id !== selected.id)
                );
              }
            }}
            options={p.dataSources}
            prompt="Data Source"
            promptIcon={MaterialIconName.DATA_USAGE}
            showPromptBadge
            toggleClassName={dropdownClasses}
            values={p.selectedDataSources}
          />
          <button
            aria-label="Reset"
            className="btn btn-link text-jungle font-size-base font-weight-bold"
            onClick={reset}
          >
            Reset
          </button>
        </div>

        {goalsEnabled && (
          <div className="d-flex align-items-center">
            <p className="mr-2">Show Goals</p>
            <Tooltip
              content={
                'Set a goal for any data points below to connect your community analytics with strategic planning and track your progress centrally here'
              }
              disable={!p.showGoalsToggleDisabled}
              id={'show-goals-toggle-tooltip'}
            >
              <RadioToggle<ShowGoalsState>
                disabled={p.showGoalsToggleDisabled}
                keySelect={(k) => k}
                labelSelect={(k) => k}
                onChange={p.setShowGoalsState}
                options={[ShowGoalsState.OFF, ShowGoalsState.ON]}
                value={p.showGoalsState}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};
