import * as React from 'react';
import logo from 'client/assets/polco-logo-dark-text.svg';
import { AppLink } from '../base';

interface Props {
  readonly className?: string;
}

export const EmbedLogoFooter: React.FC<Props> = (p) => {
  return (
    <AppLink
      className={`${p.className ?? ''} pr-2 d-flex justify-content-end`}
      target={'_blank'}
      to="https://info.polco.us"
    >
      <img alt="Polco's Logo" src={logo} style={{ height: '1rem', marginTop: 0 }} />
    </AppLink>
  );
};
