import * as React from 'react';

import { ButtonTypes, Well, WellType } from '../base';
import { Modal } from './';

export interface Props {
  readonly className?: string;
  readonly isOpen: boolean;
  readonly prompt: string | null;
  readonly copy?: React.ReactNode;
  readonly confirmLabel?: string;
  readonly confirmDisabled?: boolean;
  readonly cancelLabel?: string;
  readonly buttonsCentered?: boolean;
  readonly primaryActionButtonType?: ButtonTypes;
  readonly events: {
    readonly confirm: () => Promise<any> | void;
    readonly cancel: () => Promise<any> | void;
  };
  readonly showWell?: {
    readonly text: string;
    readonly type: WellType;
  };
}

export type ConfirmationModalProps = Props;

export const ConfirmationModal: React.FunctionComponent<Props> = ({
  buttonsCentered,
  cancelLabel,
  className,
  confirmDisabled,
  confirmLabel,
  copy,
  events,
  isOpen,
  prompt,
  showWell,
  primaryActionButtonType,
}) => {
  return (
    <Modal
      centered={true}
      className={className}
      footer={{
        buttonsCentered,
        primaryAction: {
          label: confirmLabel ?? defaultPropValues.confirmLabel,
          disabled: confirmDisabled,
          action: events.confirm,
          type: primaryActionButtonType,
        },
        secondaryAction: {
          label: cancelLabel ?? defaultPropValues.cancelLabel,
          action: events.cancel,
        },
      }}
      header={{
        title: showWell ? (
          <>
            <Well type={showWell.type}>{showWell.text}</Well>
            {prompt}
          </>
        ) : (
          prompt
        ),
      }}
      isCloseable={false}
      isOpen={isOpen}
      onRequestClose={events.cancel}
      size="md"
    >
      {copy && <div className="py-3">{copy}</div>}
    </Modal>
  );
};
ConfirmationModal.displayName = 'ConfirmationModal';

const defaultPropValues = {
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
};
