import React from 'react';
import { useLanguageByQueryParam } from 'client/shared/hooks';
import { Language, LanguageKey } from 'core';
import { LANGUAGE_SELECTION_DROPDOWN_COPY } from './copy';
import { Dropdown } from '../base/dropdown_v2';
import { MaterialIconName } from '../base';

interface Props {
  readonly alternateLanguages: readonly Pick<Language, 'name' | 'key'>[];
  readonly className?: string;
}

const DEFAULT_LANGUAGE_OPTION = { name: 'English', key: LanguageKey.ENGLISH };

const baseClass = 'pn-language-selection-dropdown';

export const LanguageSelectionDropdown: React.FC<Props> = (p) => {
  const { alternateLanguages } = p;
  const { changeLanguage, language } = useLanguageByQueryParam();
  const languages = [DEFAULT_LANGUAGE_OPTION, ...alternateLanguages].map((lang) => ({
    value: lang.key,
    label: lang.name,
  }));
  const currentLanguage = languages.find((l) => l.value === language) || {
    label: DEFAULT_LANGUAGE_OPTION.name,
    value: DEFAULT_LANGUAGE_OPTION.key,
  };

  return languages.length > 1 ? (
    <Dropdown
      ariaLabel={LANGUAGE_SELECTION_DROPDOWN_COPY.label}
      className={`${baseClass} ${p.className ?? ''}`}
      displayInline
      id="pn-language-selection-dropdown"
      label={LANGUAGE_SELECTION_DROPDOWN_COPY.label}
      labelIcon={MaterialIconName.PUBLIC}
      onChange={(l) => changeLanguage(l ? l.value : null)}
      options={languages}
      placeholder={LANGUAGE_SELECTION_DROPDOWN_COPY.prompt}
      value={currentLanguage}
    />
  ) : null;
};
