import * as React from 'react';
import './styles.scss';

interface Props {
  readonly text: string;
}
const baseClass = 'pn-text-with-line-break';

export const TextWithLineBreak: React.FC<Props> = (p) => {
  return <span className={baseClass}>{p.text}</span>;
};

TextWithLineBreak.displayName = 'TextWithLineBreak';
