import * as React from 'react';
import './styles.scss';
import { Btn, ButtonTypes, Props } from '../base/btn';
import { MaterialIcon, MaterialIconName } from '../base/material-icon';

const baseClass = 'pn-track-indicator-collapse-button';

export enum TrackIndicatorBtnType {
  MAPS = 'MAPS',
  LINE = 'LINE',
}

const COPY = {
  [TrackIndicatorBtnType.MAPS]: {
    expandAction: 'Show map',
    collapseAction: 'Hide map',
    materialIcon: MaterialIconName.MAP,
  },
  [TrackIndicatorBtnType.LINE]: {
    expandAction: 'Show line chart',
    collapseAction: 'Hide line chart',
    materialIcon: MaterialIconName.STACKED_LINE_CHART,
  },
};

export const TrackIndicatorCollapseBtn: React.FC<
  Pick<Props, 'action'> & {
    readonly ariaControls: string;
    readonly expanded: boolean;
    readonly type: TrackIndicatorBtnType;
    readonly className?: string;
  }
> = (p) => (
  <div
    className={`${baseClass}-expand-container ${p.className || ''} no-show-in-image `}
  >
    <Btn
      action={p.action}
      ariaControls={p.ariaControls}
      ariaExpanded={p.expanded}
      ariaLabel={
        p.expanded ? COPY[p.type].collapseAction : COPY[p.type].expandAction
      }
      className={`${baseClass}-expand-container-button ${p.expanded ? 'expanded' : ''}`}
      customPadding="0px"
      type={ButtonTypes.SEAMLESS}
    >
      <MaterialIcon
        className={`${baseClass}-expand-container-button-icon`}
        icon={COPY[p.type].materialIcon}
      />
    </Btn>
  </div>
);

TrackIndicatorCollapseBtn.displayName = 'TrackIndicatorCollapseBtn';
