import * as React from 'react';
import './styles.scss';
import { range } from 'lodash';
import { COLORS_NRC_GOLD_D_HEX } from 'client/shared/core/colors';

const SECTIONS = 4;
const barHeight = 33;

interface Props {
  readonly barColor?: string;
  readonly percentage: number;
  readonly showGrid: boolean;
  readonly showPercent: boolean;
}

const baseClass = 'pn-horizontal-bar';

export const HorizontalBar: React.FC<Props> = (props) => {
  //allow room for labels but keep the proportion
  const totalWidth = 90;
  const sectionWidth = totalWidth / SECTIONS;
  const circleWidth = 17;
  const margin = `${circleWidth}px`;
  const barColor = props.barColor ?? COLORS_NRC_GOLD_D_HEX;

  // Used for animation - we start at width of 0 and then remove it so the width below is then applied with a slide in animation
  const [width, setWidth] = React.useState<string>('w-0');

  React.useEffect(() => {
    setWidth('');
  }, []);

  return (
    <div aria-hidden="true" className={`${baseClass} overflow-hidden w-100`}>
      <div className={`${baseClass}-sections d-flex flex-row h-100`}>
        {props.showGrid &&
          range(SECTIONS - 1).map((i) => (
            <div
              className={`${baseClass}-section h-100`}
              key={i}
              style={{
                width: `${sectionWidth}%`,
              }}
            ></div>
          ))}
      </div>
      <div
        className={`${baseClass}-bar ${width}`}
        style={{
          width: `calc(${props.percentage}% - ${barHeight}px)`,
          backgroundColor: barColor,
        }}
      >
        <div
          className={`${baseClass}-circle text-center font-size-sm ${
            props.showPercent ? 'has-percentage' : ''
          }`}
          style={{
            backgroundColor: barColor,
            // low percentages that don't have a large enough bar run too much out of the component. -9 is the lowest value that still fits a 2 digit number to display
            marginLeft: `max(-9px, calc(100% - ${margin}))`,
          }}
        >
          {props.percentage.toFixed()}
          {props.showPercent ? '%' : ''}
        </div>
      </div>
    </div>
  );
};
