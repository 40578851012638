import React from 'react';
import { TransformedData, baseClass } from '../comparison-group-result-table-column';
import { AnalyticsValueType } from '@polco-us/types';
import { DifferenceIndicator } from 'client/shared/components/difference-indicator';

interface PreviousDataPointDifferenceProps {
  readonly data: TransformedData;
}

export const PreviousDataPointDifference: React.FC<
  PreviousDataPointDifferenceProps
> = (props) => {
  const recentValue = props.data.recentDatum?.value;
  const previousValue = props.data.recentDatum?.previousValue?.value;
  if (recentValue && previousValue) {
    return (
      <div className={`${baseClass}-difference-indicator-cell d-flex w-100`}>
        <DifferenceIndicator
          anchorClassName="w-100"
          className={`${baseClass}-difference-indicator ml-2 cursor-pointer justify-content-center`}
          difference={recentValue - previousValue}
          isNeutralPalette
          tooltip={
            "This number represents the change in this indicator's value since the previous measurement."
          }
          tooltipId={`difference-indicator-information-${props.data.name}`}
          valueType={AnalyticsValueType.AMOUNT}
          variableDateLevel={props.data.variableDateLevel}
        />
      </div>
    );
  }
  return <div>--</div>;
};

PreviousDataPointDifference.displayName = 'PreviousDataPointDifference';
