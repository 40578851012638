import * as React from 'react';
import {
  ClientQuestionId,
  ClientQuestionChoiceId,
  QuestionChoice,
} from 'client/shared/core/question';
import './styles.scss';
import { SurveyLoadedEvents } from 'client/shared/core/types';
import { Dropdown } from '../base/dropdown_v2';
import { useId } from 'react-use-id-hook';

export interface GridVoteDropdownProps {
  readonly questionId: ClientQuestionId;
  readonly cols: readonly QuestionChoice[];
  readonly currentRowId: ClientQuestionId;
  readonly selectGridChoice: SurveyLoadedEvents['surveySelectGridChoice'];
  readonly selectedGridChoice: ClientQuestionChoiceId | null;
  readonly disabled?: boolean;
  readonly hasInputError: boolean;
  readonly label: string;
}

export const GridVoteDropdown: React.FC<GridVoteDropdownProps> = (props) => {
  const {
    cols,
    selectedGridChoice,
    selectGridChoice,
    currentRowId,
    questionId,
    disabled,
    hasInputError,
    label,
  } = props;
  const initValue = cols.find((c) => c.id === selectedGridChoice);

  return (
    <div className={hasInputError && !initValue ? 'border border-danger' : ''}>
      <Dropdown
        ariaLabel={label}
        className="w-100"
        containerClassName="w-100"
        disabled={disabled}
        id={`pn-grid-vote-dropdown-${useId()}`}
        label={label}
        labelClassName="font-weight-bold font-size-sm"
        onChange={(c) => {
          c &&
            selectGridChoice({
              questionId,
              gridChoice: {
                colId: c.value,
                rowId: currentRowId,
              },
              numChoices: cols.length,
            });
        }}
        options={cols.map((c) => ({ value: c.id, label: c.label }))}
        placeholder="Please select"
        showSelectedIcon={true}
        value={
          initValue ? { value: initValue.id, label: initValue.label } : undefined
        }
      />
    </div>
  );
};
