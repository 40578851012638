import { MetaConditionCombinationOperator } from 'client/shared/graphql-client/graphql-operations.g';
import { CombinationType, MetaCondition, QuestionChoiceCondition } from 'core';
import {
  ClientQuestionChoiceId,
  ClientQuestionId,
  DEFAULT_QUESTION_CONDITIONS,
  QuestionConditions,
} from '../question';

export function gqlToCore_combinationOperator(
  gqlOperator: MetaConditionCombinationOperator
) {
  switch (gqlOperator) {
    case MetaConditionCombinationOperator.ALL:
      return CombinationType.ALL;
    case MetaConditionCombinationOperator.NONE:
      return CombinationType.NONE;
    case MetaConditionCombinationOperator.NOT_ALL:
      return CombinationType.NOT_ALL;
    case MetaConditionCombinationOperator.SOME:
      return CombinationType.SOME;
  }
}

export function clientToGql_combinationOperator(clientOperator: CombinationType) {
  switch (clientOperator) {
    case CombinationType.ALL:
      return MetaConditionCombinationOperator.ALL;
    case CombinationType.NONE:
      return MetaConditionCombinationOperator.NONE;
    case CombinationType.NOT_ALL:
      return MetaConditionCombinationOperator.NOT_ALL;
    case CombinationType.SOME:
      return MetaConditionCombinationOperator.SOME;
  }
}

export interface GqlConditions {
  readonly metaConditions: readonly {
    readonly id: string;
    readonly combinationOperator: MetaConditionCombinationOperator;
    readonly displayName: string;
    readonly childMetaConditionIds: readonly string[];
  }[];
  readonly questionChoiceConditions: readonly {
    readonly id: string;
    readonly questionId: ClientQuestionId;
    readonly questionChoiceId: ClientQuestionChoiceId;
    readonly metaConditionId: string;
  }[];
}

export function gqlToClient_conditions(
  gqlConditions: GqlConditions | null
): QuestionConditions {
  return gqlConditions
    ? {
        metaConditions: gqlConditions.metaConditions.map(
          (metaCondition): MetaCondition => ({
            id: metaCondition.id,
            combinationOperator: gqlToCore_combinationOperator(
              metaCondition.combinationOperator
            ),
            displayName: metaCondition.displayName,
            childMetaConditionIds: metaCondition.childMetaConditionIds,
          })
        ),
        questionChoiceConditions: gqlConditions.questionChoiceConditions.map(
          (questionChoiceCondition): QuestionChoiceCondition => ({
            id: questionChoiceCondition.id,
            questionId: questionChoiceCondition.questionId,
            questionChoiceId: questionChoiceCondition.questionChoiceId,
            metaConditionId: questionChoiceCondition.metaConditionId,
          })
        ),
      }
    : { ...DEFAULT_QUESTION_CONDITIONS };
}
