import { useHistory } from 'react-router-dom';
import * as State from 'client/shared/state';
import * as H from 'history';

export type RedirectFunction = (path: string, opts: State.StateRedirect) => void;

/**
 * Hook-based redirect.
 * Ideally we should probably change name to useNavigation()
 */
export function useRedirect(): RedirectFunction {
  const history: H.History = useHistory();
  return (path, opts) => {
    opts.push ? history.push(path) : history.replace(path);
  };
}
