import React from 'react';
import { useWindowSize } from '../helpers/hooks';

export enum RespondentLayout {
  ContentWidth = 1170,
  LeftSidebarWidth = 208,
  RightSidebarWidth = 312,
}

export function useLayout({
  mobileBreakingPoint = RespondentLayout.ContentWidth,
}: {
  readonly mobileBreakingPoint?: number;
}) {
  const { width } = useWindowSize();
  const isMobile = width < mobileBreakingPoint;
  const layout = React.useMemo(() => {
    return { isMobile };
  }, [isMobile]);

  return layout;
}
