import * as React from 'react';
import './styles.scss';
import { Form } from 'react-bootstrap';
import { MaterialIcon, MaterialIconName } from '../material-icon';
import { PremiumIcon } from '../premium-icon';

const BASE_CLASS = 'pn-label';
export interface Props {
  readonly className?: string;
  readonly icon?: MaterialIconName;
  readonly text?: string;
  readonly htmlFor: string;
  readonly required?: boolean;
  readonly premium?: boolean;
  readonly disabled?: boolean;
}

export const Label: React.FC<Props> = (p) =>
  p.text ? (
    <Form.Label className={p.className} htmlFor={p.htmlFor}>
      <div className="d-flex align-items-center">
        {p.icon && (
          <MaterialIcon className={`${BASE_CLASS}-icon mr-1`} icon={p.icon} />
        )}
        {p.text}
        {p.required && (
          <div className="text-error pl-1 font-size-sm">(Required)</div>
        )}
        {p.disabled && (
          <div className="text-gray-20 pl-1 font-size-sm">(Disabled)</div>
        )}
        {p.premium && <PremiumIcon />}
      </div>
    </Form.Label>
  ) : null;
