import { PermissionType } from '@polco-us/types';
import { Brand } from './helpers';

export interface PubProfileInfo {
  readonly description: string; // we treat no description as an empty string in both server and database.
  readonly logoUrl: string | null;
}

// Note: Using default Polco image is okay
export function checkProfileComplete(pubProfileInfo: PubProfileInfo): boolean {
  if (pubProfileInfo.description.length === 0) {
    return false;
  }
  if (pubProfileInfo.logoUrl === null) {
    return false;
  }
  return true;
}

export enum PublishingEntityActivationState {
  ACTIVATED = 'ACTIVATED',
  ACTIVATION_REQUESTED = 'ACTIVATION_REQUESTED',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
}
export interface AdminPermissionCategory {
  readonly id: AdminPermissionCategoryId;
  readonly name: string;
}

export type AdminPermissionCategoryId = Brand<string, 'adminPermissionCategory'>;
export interface AdminPermission {
  readonly name: PermissionType;
  readonly displayName: string;
  readonly category: AdminPermissionCategory;
}

export interface AdminRole {
  readonly id: AdminRoleId;
  readonly name: string;
  readonly description: string;
  readonly permissions: readonly AdminPermission[];
}
export type AdminRoleId = Brand<string, 'adminRole'>;
