import * as React from 'react';
import classNames from 'classnames';
import Select, {
  components,
  SingleValueProps,
  GroupBase,
  SingleValue,
  MenuPlacement,
  MenuPosition,
} from 'react-select';
import { Label } from '../label';
import { MaterialIcon, MaterialIconName } from '../material-icon';
import './styles.scss';

export interface OptionType<T extends any> {
  readonly value: T;
  readonly label: string;
}

interface Props<T> {
  readonly options: readonly (T | GroupBase<T>)[];
  readonly ariaLabel: string;
  readonly id: string;
  readonly placeholder: string;
  readonly onChange: (newValue: SingleValue<T>) => void;

  readonly value?: SingleValue<T>;
  readonly className?: string;
  readonly label?: string;
  readonly labelIcon?: MaterialIconName;
  readonly labelClassName?: string;
  readonly displayInline?: boolean;
  readonly searchable?: boolean;
  readonly disabled?: boolean;
  readonly containerClassName?: string;
  readonly showSelectedIcon?: boolean;
  readonly onSearchChange?: (value: string) => void;
  readonly onMenuClose?: () => void;
  readonly required?: boolean;
  readonly menuPlacement?: MenuPlacement;
  readonly menuPosition?: MenuPosition;
}

export const baseClass = 'pn-dropdown-v2';

/**
 * @param options Either `{ value: any, label: string }[]` or an array of values where the label will match the value.
 *
 */
export function Dropdown<T extends any>(props: Props<T>): React.ReactElement {
  const {
    ariaLabel,
    onChange,
    options,
    value,
    className,
    label,
    id,
    displayInline,
    labelIcon,
    searchable,
    placeholder,
    disabled,
    labelClassName,
    containerClassName,
    showSelectedIcon,
    onSearchChange,
    onMenuClose,
    required,
    menuPlacement = 'bottom',
    menuPosition = 'absolute',
  } = props;

  return (
    <div
      className={classNames(className, {
        'd-flex align-items-center': displayInline,
      })}
    >
      {label && (
        <Label
          className={classNames(`${baseClass}-label`, labelClassName, {
            'is-disabled': disabled,
            'mt-2 pr-3': displayInline,
          })}
          htmlFor={id}
          icon={labelIcon}
          required={required}
          text={label}
        />
      )}
      <Select
        aria-label={ariaLabel}
        className={baseClass}
        classNamePrefix={baseClass}
        classNames={{
          control: (state) =>
            `${state.isFocused ? 'border-gray-60' : 'border-gray-30'}`,
          dropdownIndicator: (state) =>
            `${state.isFocused ? 'text-jungle' : 'text-gray-50'} ${
              state.selectProps.menuIsOpen ? 'rotate-180' : ''
            }`,
          indicatorSeparator: () => 'd-none',
          container: () => containerClassName ?? '',
          placeholder: () => 'text-gray-50',
        }}
        components={{
          SingleValue: showSelectedIcon
            ? ExtendedSingleValue
            : components.SingleValue,
        }}
        escapeClearsValue={false}
        inputId={id}
        isDisabled={disabled}
        isMulti={false}
        isSearchable={searchable || false}
        menuPlacement={menuPlacement}
        menuPosition={menuPosition}
        onChange={onChange}
        onInputChange={onSearchChange}
        onMenuClose={onMenuClose}
        options={options}
        placeholder={placeholder}
        required={required}
        value={value}
      />
    </div>
  );
}

const ExtendedSingleValue = <T, U extends boolean, V extends GroupBase<T>>({
  children,
  ...props
}: SingleValueProps<T, U, V>) => {
  return (
    <components.SingleValue {...props}>
      <div className="d-flex align-items-center">
        <MaterialIcon
          className={`${baseClass}-selected-icon mr-2`}
          icon={MaterialIconName.CHECK_CIRCLE}
        />
        {children}
      </div>
    </components.SingleValue>
  );
};

/**
 *
 * @description In the case where we want options where the values and labels don't exactly match up
 * This helper takes in an object `Record<Label, Value>` and converts it to the Dropdowns expected shape
 */
export const convertLabelToValueMap = <T extends any>(
  obj: Record<string, T>
): readonly OptionType<T>[] => {
  return Object.entries(obj).map(([label, value]) => ({ label, value }));
};
