import React from 'react';
import {
  AnalyticsDomain,
  BenchmarkFilter,
  ChartType,
  FipsAreaWithShortName,
  MapExtentBounds,
  NationalDataFips,
  SafeRecordDictionary,
  TrackAreaMode,
  TrackVariableWithDistributions,
  VariableDisplayType,
} from 'core';
import { DomainCardContent } from 'client/shared/components/domain-card';
import _ from 'lodash';
import { IndexScoreChart } from 'client/shared/components/index-score-chart';
import { DomainIndicatorsGroupWrapper } from 'client/shared/components/domain-indicators-group/domain-indicators-group-wrapper';
import { EmbedDataPointContext } from 'client/shared/components/visualization-picker';
import { TrackMap } from 'client/shared/components/track-map';
import { MultiPolygon } from '@turf/helpers';
import {
  createHistogramFromIndexScores,
  variableDataToMapBuckets,
} from 'client/shared/core/performance-data';
import { GroupIndexScoreChartContainer } from '../group-index-score-chart-container';
import { MapViewContext } from 'client/shared/components/dashboard-card';

interface GroupIndexScoresProps {
  readonly comparisonGroupId: string;
  readonly trackVariables: readonly TrackVariableWithDistributions[];
  readonly currentFips?: FipsAreaWithShortName;
  readonly domain: AnalyticsDomain;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly chartType: ChartType | null;
  readonly setChartType: React.Dispatch<React.SetStateAction<ChartType | null>>;
  readonly displayType: VariableDisplayType;
  readonly embedContext?: EmbedDataPointContext;
  readonly fipsShapeByFipsCode?: SafeRecordDictionary<string, MultiPolygon>;
  readonly mapBoundCoordinates?: MapExtentBounds;
  readonly groupDoesNotContainPublisher: boolean;
}

export const GroupIndexScoresContainer: React.FC<GroupIndexScoresProps> = (p) => {
  const context = React.useContext(MapViewContext);
  const setView = context ? context.setView : undefined;
  const [resultsExpanded, setResultsExpanded] = React.useState(false);
  const toggleResultsExpanded = () => setResultsExpanded(!resultsExpanded);
  const variable = React.useMemo(() => p.trackVariables[0], [p.trackVariables]);
  const areasData = React.useMemo(
    () =>
      p.trackVariables.flatMap((v) => {
        return v.areasData;
      }),
    [p.trackVariables]
  );
  const isNationalData = p.currentFips?.id === NationalDataFips;

  const recentValue = React.useMemo(() => {
    const viewerValues =
      areasData.find((ad) => ad.fipsArea.id === p.currentFips?.id)
        ?.performanceData ?? [];
    const sortedValues = _.reverse(
      _.sortBy(viewerValues, ({ recordedAt }) => recordedAt)
    );
    return sortedValues.length && sortedValues[0].value !== null
      ? sortedValues[0]
      : null;
  }, [areasData, p.currentFips]);

  const allAreas = areasData.map((ad) => ad.fipsArea);
  const mapData = React.useMemo(() => {
    if (p.fipsShapeByFipsCode) {
      return variableDataToMapBuckets({
        areasData,
        fipsShapeByFipsCode: p.fipsShapeByFipsCode,
        variable,
        benchmarkFilter: p.benchmarkFilter,
      });
    } else {
      return [];
    }
  }, [areasData, p.benchmarkFilter, p.fipsShapeByFipsCode, variable]);

  const comparisonGroupDist = React.useMemo(() => {
    if (
      p.groupDoesNotContainPublisher ||
      !recentValue?.distributions.find(
        (d) => d.comparisonGroupId && d.comparisonGroupId === p.comparisonGroupId
      )
    ) {
      return createHistogramFromIndexScores(
        variable,
        p.benchmarkFilter,
        p.comparisonGroupId ?? null
      );
    }
  }, [
    p.groupDoesNotContainPublisher,
    variable,
    p.comparisonGroupId,
    p.benchmarkFilter,
    recentValue,
  ]);

  const indexScoreChart = React.useMemo(
    () => (
      <IndexScoreChart
        benchmarkFilter={p.benchmarkFilter}
        className="mb-3"
        comparisonGroupId={p.comparisonGroupId ?? null}
        distributions={comparisonGroupDist ?? recentValue?.distributions ?? []}
        groupDoesNotContainPublisher={p.groupDoesNotContainPublisher}
        hideBorder
        indexScoreData={recentValue ?? undefined}
        maintainAspectRatio
        variable={variable}
      />
    ),
    [
      comparisonGroupDist,
      p.groupDoesNotContainPublisher,
      variable,
      p.comparisonGroupId,
      p.benchmarkFilter,
      recentValue,
    ]
  );

  return (
    <div>
      <DomainCardContent
        className="mb-3"
        displayType={p.displayType}
        domain={p.domain}
        indexScoreData={recentValue ?? undefined}
        showDemographicPill
        variable={variable}
      />
      {p.children}
      <GroupIndexScoreChartContainer
        areaMode={TrackAreaMode.LOCAL}
        areasCount={allAreas.length}
        chartType={p.chartType}
        indexScoreChart={indexScoreChart}
        resultsExpanded={resultsExpanded}
        setChartType={p.setChartType}
        showMyCommunity={!isNationalData && !p.groupDoesNotContainPublisher}
        toggleResultsExpanded={toggleResultsExpanded}
      >
        {p.chartType === ChartType.MAP && (
          <TrackMap
            comparisonGroupId={p.comparisonGroupId}
            height={600}
            mapBounds={p.mapBoundCoordinates}
            onMapReady={setView}
            shapeBuckets={mapData}
          />
        )}
        <DomainIndicatorsGroupWrapper
          expanded={resultsExpanded}
          fipsAreas={allAreas}
          isEmbedded={!!p.embedContext}
          label={null}
          showLineChart={p.chartType === ChartType.LINE}
          trackAreaData={areasData}
          variable={variable}
        />
      </GroupIndexScoreChartContainer>
    </div>
  );
};
