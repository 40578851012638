import * as React from 'react';
import { useHistory } from 'react-router';
import { History } from 'history';
import { DEFAULT_LANGUAGE, LanguageKey, LANGUAGE_PARAM } from 'core';

export interface MultiLanguageContent {
  readonly defaultValue: string;
  readonly alternates: readonly {
    readonly languageKey: string;
    readonly value: string;
  }[];
}

export type SelectLanguageTextFunction = <
  T extends MultiLanguageContent | undefined | null
>(
  content: T
) => undefined extends T
  ? string | undefined
  : null extends T
  ? string | undefined
  : string;

export type SelectLanguageForContentFunction = (
  content: MultiLanguageContent
) => LanguageKey;

export interface UseLanguageOutput {
  readonly language: LanguageKey;
  readonly selectLanguageText: SelectLanguageTextFunction;
  readonly selectLanguageForContent: SelectLanguageForContentFunction;
  readonly changeLanguage: (language: string | null) => void;
}

export function useLanguage(
  language: LanguageKey
): Pick<UseLanguageOutput, 'selectLanguageForContent' | 'selectLanguageText'> {
  const selectLanguageText = React.useCallback<SelectLanguageTextFunction>(
    (content) => {
      const ret: string | undefined = content
        ? content.alternates.find((a) => a.languageKey === language)?.value ??
          content.defaultValue
        : undefined;
      return ret as any; // typescript can't handle the narrowing within function back to conditional return type
    },
    [language]
  );
  const selectLanguageForContent = React.useCallback(
    (content: MultiLanguageContent): LanguageKey => {
      const hasContent = content.alternates.some((a) => a.languageKey === language);
      return hasContent ? language : DEFAULT_LANGUAGE;
    },
    [language]
  );
  return {
    selectLanguageText,
    selectLanguageForContent,
  };
}

export function useDefaultLanguage() {
  return useLanguage(DEFAULT_LANGUAGE).selectLanguageText;
}

export function useLanguageByQueryParam(): UseLanguageOutput {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const language = (params.get(LANGUAGE_PARAM) ?? DEFAULT_LANGUAGE) as LanguageKey;
  const selectLanguageFunctions = useLanguage(language);
  const changeLanguageFn = React.useCallback(
    (newLanguage: string | null) => {
      changeLanguage(history, newLanguage);
    },
    [history]
  );
  return {
    ...selectLanguageFunctions,
    language,
    changeLanguage: changeLanguageFn,
  };
}

function changeLanguage(history: History, newLanguage: string | null) {
  const params = new URLSearchParams(history.location.search);
  newLanguage
    ? params.set(LANGUAGE_PARAM, newLanguage)
    : params.delete(LANGUAGE_PARAM);
  history.push({
    pathname: history.location.pathname,
    search: params.toString(),
  });
}
