import * as React from 'react';
import moment from 'moment';
import { YEAR_FORMAT, MMMM_YEAR_FORMAT, TrackDatum } from 'core';
import { Tooltip } from 'client/shared/components/tooltip';
import './styles.scss';
import {
  AnalyticsValueType,
  DateLevel,
} from 'client/shared/graphql-client/graphql-operations.g';
import { formatIndicatorValue } from 'client/shared/core/performance-data';

interface Props {
  readonly anchorClassName?: string;
  readonly className?: string;
  readonly difference?: number;
  readonly isNeutralPalette?: boolean;
  readonly tooltip?: string;
  readonly tooltipId?: string;
  readonly valueType?: AnalyticsValueType;
  readonly variableDateLevel: DateLevel;
  readonly performanceData?: Omit<TrackDatum, 'fipsArea'>;
}

const differenceIndicatorCopy = {
  differenceIndicatorTooltip: (previous: string, current: string) =>
    `This number represents the index score change from ${previous} to ${current}.`,
};

const getScoreStyles = (isNeutralPalette: boolean, difference?: number) => {
  let classes, symbol;

  if (difference === undefined || difference > 0) {
    classes = 'bg-lime-xl text-lime-d';
    symbol = '+';
  } else if (difference < 0) {
    classes = 'bg-valencia-xl text-valencia-d';
    symbol = '–';
  } else {
    classes = 'bg-gray-10 text-gray-40';
    symbol = '+';
  }

  if (isNeutralPalette) {
    classes = 'bg-liberty-xl text-liberty-d';
  }

  return {
    classes,
    symbol,
  };
};

export const DifferenceIndicator: React.FC<Props> = (p) => {
  const { performanceData, difference, tooltip, className, variableDateLevel } = p;

  const { classes, symbol } = getScoreStyles(!!p.isNeutralPalette, difference);
  const shouldRenderDifferenceIndicatorTooltip = !!tooltip || !!performanceData;
  const renderContent = () => (
    <div
      className={`d-flex px-2 py-1 rounded font-size-xs font-weight-bold cursor-default ${classes} ${
        className ?? ''
      }`}
    >
      <span>{symbol}</span>
      <span className="ml-1">
        {difference === undefined
          ? 'X'
          : formatIndicatorValue(
              Math.abs(difference),
              p.valueType ?? AnalyticsValueType.AMOUNT
            )}
      </span>
    </div>
  );

  const tooltipContent = performanceData
    ? buildDifferenceIndicatorTooltipCopy({
        variableDateLevel,
        datum: performanceData,
      })
    : tooltip;

  return (
    <>
      {shouldRenderDifferenceIndicatorTooltip ? (
        <Tooltip
          anchorClassName={`cursor-default ${p.anchorClassName}`}
          content={tooltipContent || ''}
          htmlContent={
            <div className="pn-difference-indicator-tooltip">{tooltipContent}</div>
          }
          id={p.tooltipId ?? 'difference-indicator-tooltip-information'}
          place="right"
        >
          {renderContent()}
        </Tooltip>
      ) : (
        renderContent()
      )}
    </>
  );
};

function buildDifferenceIndicatorTooltipCopy(args: {
  readonly variableDateLevel: DateLevel;
  readonly datum: Omit<TrackDatum, 'fipsArea'>;
}) {
  const { variableDateLevel, datum } = args;
  const dateFormat =
    variableDateLevel === DateLevel.YEAR ? YEAR_FORMAT : MMMM_YEAR_FORMAT;
  const current = moment.utc(datum.recordedAt).format(dateFormat);
  const previous = moment.utc(datum.previousValue?.recordedAt).format(dateFormat);
  return differenceIndicatorCopy.differenceIndicatorTooltip(previous, current);
}
