import * as React from 'react';
import './styles.scss';
import { AnalyticsValueType, DateLevel } from '@polco-us/types';
import { TrackDatum, VariableDirection } from 'core';
import { AreaName } from './comparison-group-result-table-cells/area-name';
import { ComparisonGroupResultTableCell } from './comparison-group-result-table-cells/comparison-group-result-table-cell';
import { PreviousDataPoint } from './comparison-group-result-table-cells/previous-data-point';
import { PreviousDataPointDifference } from './comparison-group-result-table-cells/previous-data-point-difference';
import { DataPointBenchmark } from './comparison-group-result-table-cells/data-point-benchmark';

export interface TransformedData {
  readonly id: string;
  readonly dataAreaFips: string;
  readonly variableName: string;
  readonly variableValueType: AnalyticsValueType;
  readonly name: string;
  readonly recentDatum: TrackDatum | null;
  readonly color: string;
  readonly rank: number;
  readonly variableDateLevel: DateLevel;
  readonly variableDirection: VariableDirection | null;
}

export interface ComparisonGroupResultTableColumnProps {
  readonly data: readonly TransformedData[];
  readonly isActionable: boolean;
  readonly onRowClick: (rowId: string) => any;
  readonly setHoverLine: (rowId: string | null) => void;
  readonly entriesPerPage: number;
  readonly hoverLine: string | null;
  readonly fipsAreaVisibility: Record<string, boolean>;
}

export const baseClass = 'pn-comparison-group-result-table-column';

export const ComparisonGroupResultTableColumn: React.FC<
  ComparisonGroupResultTableColumnProps
> = (p) => {
  const { data, isActionable, entriesPerPage } = p;
  const hideBottomBorder = data.length <= entriesPerPage;

  return (
    <div
      className={`${baseClass} mt-2 mr-2 border border-gray-20 w-100 ${hideBottomBorder ? 'border-bottom-0' : ''}`}
    >
      {data.map((d) => (
        <div className="d-flex" key={d.id}>
          <ComparisonGroupResultTableCell minWidth={100} width={150}>
            <AreaName
              data={d}
              isActionable={isActionable}
              isHidden={!p.fipsAreaVisibility[d.dataAreaFips]}
              isHoverRow={d.dataAreaFips === p.hoverLine}
              onRowClick={p.onRowClick}
              setHoverLine={p.setHoverLine}
            />
          </ComparisonGroupResultTableCell>
          <ComparisonGroupResultTableCell width={85}>
            <PreviousDataPoint data={d} />
          </ComparisonGroupResultTableCell>
          <ComparisonGroupResultTableCell width={90}>
            <PreviousDataPointDifference data={d} />
          </ComparisonGroupResultTableCell>
          <ComparisonGroupResultTableCell width={60}>
            <DataPointBenchmark data={d} />
          </ComparisonGroupResultTableCell>
        </div>
      ))}
    </div>
  );
};
