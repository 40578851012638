import * as React from 'react';
import './styles.scss';
import logo from 'client/assets/polco-logo-dark-text.svg';

interface Props {
  readonly footer?: string | React.ReactElement;
  readonly className?: string;
}

const baseClass = 'pn-download-image-footer';

export const DownloadImageFooter: React.FC<Props> = (p) => {
  return (
    <div
      className={`${baseClass} ${
        p.className ?? ''
      } mt-2 only-show-on-image-flex align-items-center font-size-sm text-gray-50`}
    >
      {p.footer}
      <img
        alt="Polco's Logo"
        className="ml-auto mr-2 h-100"
        src={logo}
        style={{ marginTop: 0 }}
      />
    </div>
  );
};
