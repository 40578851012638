export const SHARE_MODAL_COPY = {
  title: 'Share with your network',
  ariaLabel: 'Copy URL link',
  copyURL: 'Copy URL',
  copiedURL: 'Link copied to clipboard',
  description: 'Use the following options to share this content.',
  shareableUrl: 'Shareable URL',
  shareVia: 'Share Via...',
  shareOnFaceBook: 'Share on Facebook',
  shareOnTwitter: 'Share on X',
  cancel: 'Cancel',
};
