import React from 'react';

interface IconProps {
  readonly width?: number;
  readonly height?: number;
  readonly className?: string;
}

export const UpVoteIcon: React.FC<IconProps> = ({ className, width, height }) => {
  return (
    <svg
      className={className ?? ''}
      fill="none"
      height={height ?? 24}
      viewBox="0 0 24 24"
      width={width ?? 24}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1361_68731)">
        <path
          d="M5.88 13.88L12 7.77333L18.12 13.88L20 12L12 4L4 12L5.88 13.88Z"
          fill="currentColor"
        />
        <circle cx="12" cy="16" fill="currentColor" r="2" />
      </g>
      <defs>
        <clipPath id="clip0_1361_68731">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DownVoteIcon: React.FC<IconProps> = ({ className, width, height }) => {
  return (
    <svg
      className={className ?? ''}
      fill="none"
      height={height ?? 24}
      viewBox="0 0 24 24"
      width={width ?? 24}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1361_68732)">
        <path
          d="M5.88 10L12 16.1067L18.12 10L20 11.88L12 19.88L4 11.88L5.88 10Z"
          fill="currentColor"
        />
        <circle cx="12" cy="8" fill="currentColor" r="2" />
      </g>
      <defs>
        <clipPath id="clip0_1361_68732">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
