import _ from 'lodash';
import React from 'react';

import { COLORS_LIBERTY_HEX } from 'client/shared/core/colors';
import { IndexScoreChart } from 'client/shared/components/index-score-chart';
import './styles.scss';
import { BenchmarkFilter } from 'client/shared/graphql-client/graphql-operations.g';
import {
  ChartType,
  FipsArea,
  TrackAreaMode,
  TrackDatumWithDistributions,
  TrackVariableWithDistributions,
  getMostRecentDatumCurrentPub,
} from 'core';
import { IndexScoreChartContainer } from '../index-score-chart-container';
import { TrackLineChart } from 'client/shared/components/domain-indicators-group/domain-indicators-group-line-chart';
import { DomainIndicatorsFipsAreaRow } from 'client/shared/components/domain-indicators-group/domain-indicators-group-wrapper';
import { GqlGoal } from 'client/admin/hooks/use-track-goals';

interface Props {
  readonly areaMode: TrackAreaMode;
  readonly indexScore: TrackVariableWithDistributions;
  readonly currentFips: FipsArea;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly defaultChart: ChartType;
  readonly setChart: React.Dispatch<React.SetStateAction<ChartType | null>>;
  readonly goal?: GqlGoal;
}

export const DomainChartContainer: React.FC<Props> = (props) => {
  const [hoverLine, setHoverLine] = React.useState<string | null>(null);
  const indexScoreData = getMostRecentDatumCurrentPub<
    TrackDatumWithDistributions,
    TrackVariableWithDistributions
  >(props.currentFips, props.indexScore);

  const isNationalData = props.areaMode === TrackAreaMode.NATIONAL;
  const lineChartData: readonly DomainIndicatorsFipsAreaRow[] = React.useMemo(() => {
    return _.compact([
      indexScoreData
        ? {
            id: props.indexScore.id,
            rank: 0,
            dataAreaFips: indexScoreData.fipsArea.id,
            name: indexScoreData.fipsArea.shortName ?? indexScoreData.fipsArea.name,
            color: COLORS_LIBERTY_HEX,
            recentDatum: indexScoreData,
            trackAreaData: {
              fipsArea: indexScoreData.fipsArea,
              performanceData:
                props.indexScore.areasData.at(0)?.performanceData ?? [],
            },
            variableName: props.indexScore.name,
            variableValueType: props.indexScore.valueType,
            variableDateLevel: props.indexScore.dateLevel,
            variableDirection: props.indexScore.direction,
          }
        : null,
    ]);
  }, [indexScoreData, props.indexScore]);
  const fipsAreaVisibility = React.useMemo(
    () => (indexScoreData ? { [indexScoreData.fipsArea.id]: true } : {}),
    [indexScoreData]
  );
  let chart;
  if (
    props.defaultChart === ChartType.HISTOGRAM ||
    props.areaMode === TrackAreaMode.NATIONAL
  ) {
    chart = (
      <IndexScoreChart
        benchmarkFilter={props.benchmarkFilter}
        comparisonGroupId={null}
        distributions={indexScoreData?.distributions ?? []}
        hideBorder
        indexScoreData={isNationalData ? undefined : indexScoreData}
        maintainAspectRatio
        variable={props.indexScore}
      />
    );
  } else if (props.defaultChart === ChartType.LINE) {
    chart = (
      <TrackLineChart
        data={lineChartData}
        fipsAreaVisibility={fipsAreaVisibility}
        goalLine={props.goal?.value}
        hoverLine={hoverLine}
        setHoverLine={setHoverLine}
        variable={props.indexScore}
      />
    );
  }

  return (
    <IndexScoreChartContainer
      areaMode={props.areaMode}
      chartType={props.defaultChart}
      setChartType={props.setChart}
      showMyCommunity={!isNationalData}
    >
      {chart}
    </IndexScoreChartContainer>
  );
};
