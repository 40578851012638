import * as React from 'react';
import './styles.scss';
import { Dropdown as BsDropdown } from 'react-bootstrap';
import classNames from 'classnames';
import { Label } from '../label';
import { MaterialIcon, MaterialIconName } from '../material-icon';
import { TextInput } from '../text-input';
import _ from 'lodash';
import { wrap } from 'core';
import { ClickableDiv } from '../clickable-div';

const baseClass = 'pn-dropdown';

// I was unable to extract the actually typing (see BsDropdown.Menu popover),
// So im just typing the parts that were using
interface UsedPopperConfig {
  // eslint-disable-next-line functional/prefer-readonly-type
  readonly modifiers: {
    readonly name: 'flip';
    readonly options: {
      readonly padding: {
        readonly top?: number;
        readonly bottom?: number;
        readonly left?: number;
        readonly right?: number;
      };
    };
  }[];
}

export interface Props<T> {
  readonly options: readonly T[];
  readonly prompt: string;
  readonly keySelect: (value: T, index: number) => string;
  readonly labelSelect: (value: T, index: number) => string;
  readonly labelIconSelect?: (value: T, index: number) => MaterialIconName;
  readonly labelSelectSelected?: (value: T, index: number) => string;

  readonly promptIcon?: MaterialIconName;
  readonly collapsedIcon?: MaterialIconName;
  readonly expandedIcon?: MaterialIconName;
  readonly headerSelect?: (value: T, index: number) => string;
  readonly displayInline?: boolean;
  readonly label?: string;
  readonly labelIcon?: MaterialIconName;
  readonly labelClassName?: string;
  readonly className?: string;
  readonly toggleClassName?: string;
  readonly togglePadding?: string;
  readonly value?: T;
  readonly disabled?: boolean;
  readonly search?: boolean;
  readonly onChange?: (value: T) => void;
  readonly onSearchChange?: (value: string) => void;
  readonly clickableOption?: string;
  readonly clickableAction?: () => void;
  readonly clickableDisabled?: boolean;
  readonly disabledOptions?: readonly string[];
  readonly popperConfig?: UsedPopperConfig;
  readonly error?: boolean;
  readonly menuClassName?: string;
  readonly wrapItems?: boolean;
  readonly dropLocation?: DropLocation;
  readonly valueClassName?: string;
}

export enum DropLocation {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
}

export function Dropdown<T>(
  props: Props<T> & { readonly children?: React.ReactNode },
  _context?: any
): React.ReactElement {
  const [searchString, setSearchString] = React.useState('');
  const [show, setShow] = React.useState(false);
  const options =
    !props.search || searchString.length === 0
      ? props.options
      : props.options.filter((opt, i) =>
          props
            .labelSelect(opt, i)
            .toLowerCase()
            .includes(searchString.toLowerCase())
        );

  const renderToggleLabel = (): string => {
    if (props.value) {
      const index = props.options.findIndex((v) => v === props.value);

      return props.labelSelectSelected
        ? props.labelSelectSelected(props.value, index)
        : props.labelSelect(props.value, index);
    }

    return props.prompt;
  };

  return (
    <div
      className={classNames(baseClass, props.className, {
        'is-disabled': props.disabled,
        'd-flex align-items-center': props.displayInline,
      })}
    >
      {props.label && (
        <Label
          className={classNames(`${baseClass}-label`, props.labelClassName, {
            'is-disabled': props.disabled,
            'mt-2 mr-3': props.displayInline,
          })}
          htmlFor={props.label}
          icon={props.labelIcon}
          text={props.label}
        />
      )}
      <BsDropdown
        className={classNames({ 'd-inline-block': props.displayInline })}
        drop={props.dropLocation}
        onToggle={() => setShow(!show)}
        show={show}
      >
        <BsDropdown.Toggle
          className={`${props.toggleClassName ?? ''} d-block w-100 ${props.togglePadding ?? 'pr-5'} text-left`}
          disabled={props.disabled}
          id={props.label ?? props.prompt}
          variant={props.error ? 'outline-danger' : 'white'}
        >
          <div
            className={`pr-5 d-flex align-items-center ${props.valueClassName ?? ''}`}
            style={{ overflow: 'hidden' }}
          >
            {props.promptIcon && (
              <MaterialIcon
                className={`${baseClass}-label-icon mr-2 no-show-in-image`}
                icon={props.promptIcon}
              />
            )}
            {renderToggleLabel()}
          </div>
          <MaterialIcon
            className={`dropdown-arrow-icon no-show-in-image`}
            icon={
              show
                ? props.expandedIcon ?? MaterialIconName.KEYBOARD_ARROW_UP
                : props.collapsedIcon ?? MaterialIconName.KEYBOARD_ARROW_DOWN
            }
          />
        </BsDropdown.Toggle>
        <BsDropdown.Menu
          className={`${baseClass}-menu ${props.menuClassName ?? ''}`}
          popperConfig={props.popperConfig}
        >
          {props.search && (
            <TextInput
              className="px-2"
              onChange={(val) => {
                setSearchString(val);
                props.onSearchChange && props.onSearchChange(val);
              }}
              placeholder="Search"
              value={searchString}
            />
          )}
          {_.flatMap(options, (opt, index) => {
            // Add a header element if headerSelect is provided and the current item has a new header from the previous item
            const MaybeHeader = wrap(() => {
              if (props.headerSelect) {
                const currentHeader = props.headerSelect(opt, index);
                const Header = (
                  <BsDropdown.Header
                    className={`${baseClass}-header pl-3 py-2 text-black font-size-sm font-weight-bold`}
                    key={`${currentHeader}-${index}`}
                  >
                    {currentHeader}
                  </BsDropdown.Header>
                );
                if (index === 0) {
                  return Header;
                }
                const previousHeader = props.headerSelect(
                  options[index - 1],
                  index - 1
                );
                if (currentHeader !== previousHeader) {
                  return Header;
                }
              }
              return null;
            });
            const Item = (
              <BsDropdown.Item
                className={`${baseClass}-item d-flex align-items-center ${
                  props.headerSelect ? `${baseClass}-item-with-header` : ''
                } py-2 font-size-sm ${!!props.wrapItems && 'text-wrap'}`}
                key={index}
                onSelect={() => props.onChange && props.onChange(opt)}
              >
                {props.labelIconSelect && (
                  <MaterialIcon
                    className="mr-2"
                    icon={props.labelIconSelect(opt, index)}
                  />
                )}
                {props.labelSelect(opt, index)}
              </BsDropdown.Item>
            );
            return _.compact([MaybeHeader, Item]);
          })}
          {props.clickableOption && (
            <BsDropdown.Item
              className={`${baseClass}-item d-flex align-items-center py-2 font-size-sm ${!!props.wrapItems && 'text-wrap'}`}
              disabled={props.clickableDisabled}
            >
              <ClickableDiv
                action={() =>
                  props.clickableAction ? props.clickableAction() : null
                }
                className={`${baseClass}-action`}
                disabled={props.clickableDisabled}
              >
                {props.clickableOption}
              </ClickableDiv>
            </BsDropdown.Item>
          )}
          {props.disabledOptions &&
            props.disabledOptions.map((dOption, idx) => (
              <BsDropdown.Item
                className={`${baseClass}-item d-flex align-items-center py-2 font-size-sm ${!!props.wrapItems && 'text-wrap'}`}
                disabled
                key={`disabled-option-${idx}`}
              >
                {dOption}
              </BsDropdown.Item>
            ))}
        </BsDropdown.Menu>
      </BsDropdown>
    </div>
  );
}

/**
 * Constructing PopperConfigs is a real pain, so this utility function makes them for you.
 * This function is for use in a survey where we have a sticky footer. We need the popper to
 * 'flip' (show above the selector rather than below it) 75px earlier than normal because the
 * sticky footer overlaps the bottom 75px of the popper.
 * @param buffer
 */
export const surveyDropdownBottomPadding = (
  buffer: number = 75
): UsedPopperConfig => {
  return {
    modifiers: [
      {
        name: 'flip',
        options: {
          padding: {
            bottom: buffer,
          },
        },
      },
    ],
  };
};
