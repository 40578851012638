import { Brand } from 'core';

export enum RequestPasswordResetContext {
  ADMIN_FORGOT_PASSWORD = 'ADMIN_FORGOT_PASSWORD',
  ADMIN_CLAIM_ACCOUNT = 'ADMIN_CLAIM_ACCOUNT',
  RESPONDENT_FORGOT_PASSWORD = 'RESPONDENT_FORGOT_PASSWORD',
}

export type ValidIpAddress = Brand<string, 'ip_address'>;

export function getValidIpAddress(value: string): ValidIpAddress | null {
  const regex =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
  const matches = value.match(regex);
  if (matches) return matches[0] as ValidIpAddress;
  return null;
}

export enum UnsubscribeResult {
  SUCCESS = 'unsubscribe-success',
  ALREADY_UNSUBSCRIBED = 'unsubscribed-already',
  INVALID_TOKEN = 'unsubscribe-invalid-token',
}

export const ANONYMOUS = 'Anonymous';
