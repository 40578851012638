import { cyclicalColorInterpolators } from '@nivo/colors';
const initialColors = [
  '#1FA3A1',
  '#4E61BA',
  '#0EAD00',
  '#DE92BD',
  '#97BB57',
  '#235048',
  '#F7D54F',
  '#69A8B7',
  '#7D58BB',
  '#E26F03',
];

export const useGetNivoColors = () => {
  function getColors(amount: number) {
    if (amount <= 10) {
      return initialColors.slice(0, amount + 1);
    }
    const colorsForData = [];
    for (let limit = 0; limit < amount; limit++) {
      const newColor = cyclicalColorInterpolators.rainbow(limit / amount);
      colorsForData.push(newColor);
    }
    return colorsForData;
  }

  return { getColors };
};
